import { yupResolver } from '@hookform/resolvers/yup';
import { Variant } from 'components/Forms/components/Input';
import Layout from 'components/Forms/Layout';
import useAuth from 'hooks/useAuth';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import Button from 'components/Button';
import { COUNTRY, INDUSTRY } from 'data/options';
import { find } from 'lodash';
import Tabs from 'components/Tabs';
import { toast } from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { readAxiosErr } from 'utils/apiService';
import { updateOrganization, updateProfile } from 'queries/auth';
import { uploadImage } from 'queries/uploadFile';
import AppShell from 'components/AppShell';
import { useLocation } from 'react-router-dom';

interface IForm {
  orgName: string;
  industry: string;
  country?: string | null;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string | null;
  postalCode: number;
}
const schema = yup.object({
  industry: yup.string(),
  orgName: yup.string().min(2).max(75).required(),
  country: yup.string().required(),
  addressLine1: yup.string().max(30).required(),
  addressLine2: yup.string().max(30).required(),
  city: yup.string().max(28).required(),
  state: yup.string().required(),
  postalCode: yup.number().required().typeError('Must be a number')
});

const Organization = () => {
  const { user } = useAuth();
  const location = useLocation();
  let pathname = location.pathname;
  const [accountPhoto, setAccountPhoto] = useState(
    user?.organization.orgLogoURL
  );
  const STATE = find(COUNTRY, { value: 'usa' })?.states || [];

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<IForm>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    setAccountPhoto(user?.organization.orgLogoURL);
    reset({
      industry: user?.organization.industry || '',
      orgName: user?.organization.orgName || '',
      country: user?.organization.country || '',
      addressLine1: user?.organization.addressLine1 || '',
      addressLine2: user?.organization.addressLine2 || '',
      city: user?.organization.city || '',
      state: user?.organization.state || '',
      postalCode: user?.organization.postalCode || 0
    });
  }, [user]);

  const uploadAccountLogo = useMutation(
    (formData: any) => uploadImage(formData),
    {
      onSuccess: (res: any) => {
        setAccountPhoto(res.secure_url);
        let formData = new FormData();
        formData = Object.assign({ orgLogoURL: res.secure_url }, formData);
        updateOrganizationMutation.mutate(formData);
        toast.success('Account logo added successfully');
        if (!res) {
          toast.error('Something went wrong');
        }
      }
    }
  );
  const handleImage = (e: any) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('upload_preset', 'unboxedsass');
    uploadAccountLogo.mutate(formData);
  };

  const updateOrganizationMutation = useMutation(
    (formData: Record<string, any>) => updateOrganization(formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        toast.success('Updated Successful');
      }
    }
  );

  const organizationInfo = [
    {
      label: 'Company name',
      className: 'font-normal text-sm',
      type: FieldType.Input,
      variant: Variant.Text,
      defaultValue: user?.organization?.orgName,
      placeholder: 'Enter company name',
      error: errors.orgName?.message,
      dataTestId: 'company-name-input',
      ...register('orgName')
    },
    {
      name: 'industry',
      label: 'Industry',
      placeholder: 'Select',
      control,
      labelStyle: 'font-semibold text-sm',
      options: INDUSTRY,
      type: FieldType.Select,
      error: errors.industry?.message,
      dataTestId: 'company-industry-input',
      isSearchable: true
    }
  ];
  const accountLogo = [
    {
      name: 'accountLogo',
      label: 'Account logo (optional)',
      className: '-mt-5',
      showLabel: false,
      type: FieldType.UploadImage,
      imageRendered: accountPhoto,
      onChange: handleImage,
      dataTestId: 'account-logo-input'
    }
  ];
  const organizationAddress1 = [
    {
      name: 'country',
      label: 'Country',
      placeholder: 'Select country',
      control,
      labelStyle: 'font-semibold text-sm',
      options: COUNTRY,
      type: FieldType.Select,
      error: errors.country?.message,
      dataTestId: 'company-country-input',
      isSearchable: true
    },
    {
      label: 'Company Address line 1',
      className: 'font-normal mt-10',
      type: FieldType.Input,
      variant: Variant.Text,
      placeholder: 'Enter address',
      error: errors.addressLine1?.message,
      dataTestId: 'company-address1-input',
      ...register('addressLine1')
    },
    {
      label: 'Company Address line 2',
      className: 'font-normal',
      type: FieldType.Input,
      variant: Variant.Text,
      placeholder: 'Enter address',
      error: errors.addressLine2?.message,
      dataTestId: 'company-address2-input',
      ...register('addressLine2')
    }
  ];
  const organizationAddress2 = [
    {
      label: 'City',
      className: 'font-normal w-1/3',
      type: FieldType.Input,
      variant: Variant.Text,
      placeholder: 'Enter city',
      error: errors.city?.message,
      dataTestId: 'company-city-input',
      ...register('city')
    },
    {
      name: 'state',
      label: 'State',
      placeholder: 'Select',
      className: 'font-normal text-sm w-1/3 mt-2 mx-4',
      labelStyle: 'font-semibold',
      control,
      options: STATE,
      type: FieldType.Select,
      error: errors.state?.message,
      dataTestId: 'company-state-input',
      isSearchable: true
    },
    {
      label: 'Zip/Postal code',
      className: 'font-normal  w-1/3',
      type: FieldType.Input,
      variant: Variant.Text,
      placeholder: 'Enter zip/postal code',
      error: errors.postalCode?.message,
      dataTestId: 'company-zip-input',
      ...register('postalCode')
    }
  ];

  const links = [
    {
      link: '/profile',
      label: 'My profile',
      className: pathname === '/profile' ? 'bg-gray-700' : 'bg-background-theme'
    },
    {
      link: '/organization',
      label: 'Organization',
      className:
        pathname === '/organization' ? 'bg-gray-700' : 'bg-background-theme'
    },
    {
      link: '/team-members',
      label: 'Team members',
      className:
        pathname === '/team-members' ? 'bg-gray-700' : 'bg-background-theme'
    }
  ];

  const onSubmit: SubmitHandler<IForm> = async (formData) => {
    updateOrganizationMutation.mutate(formData);
  };
  return (
    <AppShell>
      <div className="bg-background-theme h-theme grid">
        <div className="mt-8 mb-8 ml-16 w-4/6 text-white text-4xl">
          <div className="font-semibold text-4xl" data-testid="profile-label">
            Profile
          </div>
          <Tabs links={links} />
        </div>
        <form data-testid="onboarding-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col w-3/5 mt-10 m-auto">
            <div className="flex bg-white rounded-l border shadow-lg w-full mb-8">
              <div className="mt-8 ml-10">
                {' '}
                <Layout fields={accountLogo} />
              </div>
              <div className="flex flex-col mt-8 ml-6 mb-10 h-28">
                <div className="text-2xl font-bold">
                  {user?.organization.orgName}
                </div>
                <div className="text-2xl font-normal text-gray-500 mt-1">
                  {user?.organization.industry}
                </div>
                <div className="mt-4 bg-indigo-100 rounded-md w-16"></div>
              </div>
            </div>
            <div className="bg-white rounded-l border shadow-lg w-full mb-8">
              <div className="font-bold text-xl px-8 mt-4">
                Organization information
              </div>
              <Layout fields={organizationInfo} className="p-8" />
              <div className="flex justify-end">
                <Button
                  label="Update Organization"
                  className="bg-primary w-48 rounded-lg mb-2 mr-8"
                  data-testid="save-profile-button"
                />
              </div>
            </div>
            <div className="bg-white rounded-l border shadow-lg w-full mb-8">
              <div className="font-bold text-xl px-8 mt-4 mb-8">
                Organization address
              </div>
              <Layout fields={organizationAddress1} className="px-8" />
              <Layout fields={organizationAddress2} className="flex px-8" />
              <div className="flex justify-end">
                <Button
                  label="Update address"
                  className="bg-primary w-40 rounded-lg mb-2 mr-8"
                  data-testid="save-profile-button"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </AppShell>
  );
};

export default Organization;
