import React from 'react';
import Button, { Size, Variants } from 'components/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { readAxiosErr } from 'utils/apiService';
import Icon from 'components/Icon';
import { toast } from 'react-hot-toast';
import { revokeInvitee } from 'queries/invites';

type App = {
  id: string;
  closeModal: () => void;
  associationId?: string;
};

const RevokeInvite: React.FC<App> = ({
  id,
  closeModal,
  associationId = ''
}) => {
  const queryClient = useQueryClient();

  const revokeMutation = useMutation(
    (id: string) => revokeInvitee(id, { associationId }),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['allInvitees']);
        queryClient.invalidateQueries(['alladvertiser']);
        queryClient.invalidateQueries(['allmediaregister']);
        toast.success('Invitee revoked successfully');
        closeModal();
      }
    }
  );

  return (
    <div className="px-24 py-12" data-testid="delete-ad-box">
      <div className="mb-6 m-auto w-8" data-testid="delete-icon">
        <Icon name="alert" />
      </div>
      <p className="text-lg text-center" data-testid="delete-ad">
        Are you sure you want to revoke this invitee?
      </p>
      <p className="text-lg mb-9 text-center" data-testid="delete-ad">
        This action cannot be undone.
      </p>
      <div className="flex justify-end">
        <Button
          label="No, cancel"
          className="w-32 mr-4 border-0"
          size={Size.Small}
          variant={Variants.Negative}
          dataTestId="cancel"
          onClick={closeModal}
        />
        <Button
          label="Yes, i'm sure"
          className="w-32"
          variant={Variants.Alert}
          onClick={() => revokeMutation.mutate(id)}
          dataTestId="delete"
        />
      </div>
    </div>
  );
};

export default RevokeInvite;
