import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Variant } from 'components/Forms/components/Input';
import Layout from 'components/Forms/Layout';
import useAuth from 'hooks/useAuth';
import * as yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import Button from 'components/Button';
import Tabs from 'components/Tabs';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { readAxiosErr } from 'utils/apiService';
import { updateProfile } from 'queries/auth';
import { uploadImage } from 'queries/uploadFile';
import ChangePassword from './components/ChangePassword';
import { Role } from 'utils/map';
import AppShell from 'components/AppShell';
import { useLocation } from 'react-router-dom';
import useRole from 'hooks/useRole';

interface IForm {
  fullName: string;
  email: string;
  jobTitle?: string;
  password?: string;
  confirmPassword?: string;
}

const schema = yup.object({
  fullName: yup
    .string()
    .min(2)
    .max(300)
    .matches(/^[A-Za-z\s,'-]+$/, 'Please enter valid full name'),
  email: yup
    .string()
    .matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      'Please enter valid email address'
    ),
  jobTitle: yup.string().max(128).optional()
});

const Profile = () => {
  const { user } = useAuth();
  const {
    isAdvertiserAdmin,
    isSuperAdmin,
    isMediaChannelUser,
    isAdvertiserUser
  } = useRole();
  const location = useLocation();
  let pathname = location.pathname;
  const [profilePhoto, setProfilePhoto] = useState(user?.profileLogoURL);

  useEffect(() => {
    setProfilePhoto(user?.profileLogoURL);
  }, [user]);

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<IForm>({ resolver: yupResolver(schema) });

  const handleImage = (e: any) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('upload_preset', 'unboxedsass');
    uploadProfileLogo.mutate(formData);
  };

  const uploadProfileLogo = useMutation(
    (formData: any) => uploadImage(formData),
    {
      onSuccess: (res: any) => {
        setProfilePhoto(res.secure_url);
        let formData = new FormData();
        formData = Object.assign({ profileLogoURL: res.secure_url }, formData);
        updateProfileMutation.mutate(formData);
        toast.success('Profile logo added successfully');
        if (!res) {
          toast.error('Something went wrong');
        }
      }
    }
  );
  const updateProfileMutation = useMutation(
    (formData: Record<string, any>) => updateProfile(formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        toast.success('Updated Successful');
      }
    }
  );

  const personalInfo = [
    {
      label: 'Full name',
      className: 'font-normal',
      type: FieldType.Input,
      variant: Variant.Text,
      defaultValue: user
        ? user?.firstName + ' ' + (user?.lastName ? user?.lastName : '')
        : '',
      placeholder: 'Enter full name',
      error: errors.fullName?.message,
      dataTestId: 'full-name-input',
      ...register('fullName')
    },
    {
      label: 'Email address',
      className: 'font-normal',
      type: FieldType.Input,
      variant: Variant.Email,
      placeholder: 'Email address',
      defaultValue: user?.email,
      disabled: true,
      error: errors.email?.message,
      dataTestId: 'email-input'
    },
    {
      label: 'Job title (optional)',
      className: 'font-normal',
      type: FieldType.Input,
      variant: Variant.Text,
      defaultValue: user?.jobTitle,
      placeholder: 'Enter job title',
      error: errors.jobTitle?.message,
      dataTestId: 'job-title-input',
      ...register('jobTitle')
    }
  ];
  const profileImage = [
    {
      name: 'profileLogo',
      label: 'Profile Image (optional)',
      className: user?.profileLogoURL ? '-mt-5' : '-mt-10',
      showLabel: false,
      type: FieldType.UploadImage,
      imageRendered: profilePhoto,
      onChange: handleImage,
      dataTestId: 'profile-image-input'
    }
  ];

  const links = [
    {
      link: '/profile',
      label: 'My profile',
      className: pathname === '/profile' ? 'bg-gray-700' : 'bg-background-theme'
    },
    {
      link: '/organization',
      label: 'Organization',
      className:
        pathname === '/organization' ? 'bg-gray-700' : 'bg-background-theme'
    },
    {
      link: '/team-members',
      label: 'Team members',
      className:
        pathname === '/team-members' ? 'bg-gray-700' : 'bg-background-theme'
    }
  ];
  if (isSuperAdmin || isMediaChannelUser || isAdvertiserUser) {
    delete links[1];
  }
  const onSubmit: SubmitHandler<IForm> = async (formData) => {
    updateProfileMutation.mutate(formData);
  };

  return (
    <AppShell>
      <div className="bg-background-theme h-theme grid">
        <div className="mt-8 mb-8 ml-16 w-4/6 text-white text-4xl">
          <div className="font-semibold text-4xl" data-testid="profile-label">
            Profile
          </div>
          <Tabs links={links} />
        </div>
        <div className="flex flex-col w-3/5 mt-10 m-auto">
          <div className="flex bg-white rounded-l border shadow-lg w-full mb-8">
            <div className="mt-8 ml-10">
              {' '}
              <Layout fields={profileImage} />
            </div>
            <div className="flex flex-col mt-8 ml-6 mb-10">
              <div className="text-2xl font-bold">
                {user?.firstName} {user?.lastName}
              </div>
              <div className="text-2xl font-normal text-gray-500 mt-1">
                {user?.email}
              </div>
              <div className="mt-4 bg-indigo-100 rounded-md w-max">
                <div className="py-0.5 px-3">{Role[user?.role]}</div>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-l border shadow-lg w-full mb-8">
            <div className="font-bold text-xl px-8 mt-4">
              General information
            </div>
            <form
              data-testid="onboarding-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Layout fields={personalInfo} className="p-8" />
              <div className="flex justify-end">
                <Button
                  label="Update profile"
                  className="bg-primary w-36 text-sm rounded-lg mb-2 mr-8"
                  data-testid="save-profile-button"
                />
              </div>
            </form>
          </div>
          <ChangePassword />
        </div>
      </div>
    </AppShell>
  );
};

export default Profile;
