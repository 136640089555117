import React from 'react';


const Loader: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="spinner h-20 w-20 border-4 border-t-4 border-indigo-500 rounded-full">
        <div className="spinner-inner h-16 w-16 bg-teal-500 rounded-full"></div>
      </div>
    </div>
  );
};

const styles = {
  animation: 'spin 1s linear infinite',
}

const spinnerInner = {
  height: '16px',
  width: '16px',
  backgroundColor: 'teal',
  borderRadius: '50%'
}

const spinner = {
  height: '20px',
  width: '20px',
  border: '4px solid indigo',
  borderTop: '4px solid gray',
  borderRadius: '50%'
}

export default Loader;