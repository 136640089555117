import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};
const SvgEmail: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 16,
  color = '#666',
  ...rest
}) => (
  <svg
    width={size}
    height={size - 2}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="m1.25 4 5.918 3.945a1.5 1.5 0 0 0 1.664 0L14.75 4m-12 8.25h10.5a1.5 1.5 0 0 0 1.5-1.5v-7.5a1.5 1.5 0 0 0-1.5-1.5H2.75a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5Z"
      stroke="#6B7280"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgEmail;
