import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const SvgMore: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 24,
  color = '#666',
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="m12.776 6.422.354-.354-.354.354a1.1 1.1 0 0 1-1.555 1.555l-.354.354.354-.354a1.1 1.1 0 1 1 1.555-1.555Zm0 9.6.354-.354-.354.354a1.1 1.1 0 0 1 0 1.555l.354.354-.354-.354a1.1 1.1 0 1 1 0-1.555Zm-.778-2.922a1.1 1.1 0 1 1 0-2.2 1.1 1.1 0 0 1 0 2.2Z"
      fill="#fff"
      stroke="#fff"
    />
  </svg>
);

export default SvgMore;
