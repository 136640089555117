import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import FallBack from 'components/FallBack';
import Loader from 'components/Loader';
import toast, { ToastBar, Toaster, useToasterStore } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Icon from 'components/Icon';
import Onboarding from 'pages/Onboarding';
import AuthProvider from 'context/AuthContext';
import CreateAd from 'pages/CreateAd';
import Dashboard from 'pages/Dashboard';
import Profile from 'pages/Profile';
import Organization from 'pages/Organization';
import useAuth from 'hooks/useAuth';
import Adverts from 'pages/Adverts';
import TeamMember from 'pages/TeamMember';
import RequireAuth from 'components/RequireAuth';
import Account from 'pages/Accounts';
import Overview from 'pages/Overview/AdvertiserAdmin.overview.tsx';
import Request from 'pages/Request';
import AdvertiserAdminOverview from 'pages/Overview/AdvertiserAdmin.overview.tsx';
import MediaAdminOverview from 'pages/Overview/Adverts.overview';
import AdvertsOverview from 'pages/Overview/Adverts.overview';
import SuperAdminOverview from 'pages/Overview/SuperAdmin.overview';
import SuperAdminAccount from 'pages/Accounts/components/SuperAdmin.accounts';
import Adlayout from 'pages/Adlayout';
import AdlayoutConfiguration from 'pages/Adlayout/components/AdConfiguration';
import CreateAdlayout from 'pages/CreateAdlayout';
import ForbiddenPage from 'pages/Forbidden';
import { Role } from 'utils/enum';

const Login = lazy(() => import('./pages/Login'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const AcceptInvite = lazy(() => import('./pages/AcceptInvite'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5 * 60 * 1000
    }
  }
});

const App = () => {
  const { toasts } = useToasterStore();

  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((_, i) => i >= 3)
      .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <ErrorBoundary FallbackComponent={FallBack}>
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route path="/" element={<Login />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/onboarding" element={<RequireAuth />}>
                    <Route index element={<Onboarding />} />
                  </Route>
                  <Route path="/accept-invite" element={<AcceptInvite />} />
                  <Route
                    path="/create-ad"
                    element={
                      <RequireAuth
                        roles={[Role.AdvertiserAdmin, Role.AdvertiserUser]}
                      />
                    }
                  >
                    <Route
                      index
                      element={<CreateAd disabled={false} page={'Create'} />}
                    />
                  </Route>
                  <Route
                    path="/view-ad/:id"
                    element={
                      <RequireAuth
                        roles={[
                          Role.mediaChannelAdmin,
                          Role.mediaChannelUser,
                          Role.AdvertiserAdmin,
                          Role.AdvertiserUser
                        ]}
                      />
                    }
                  >
                    <Route
                      index
                      element={<CreateAd disabled={true} page={'View'} />}
                    />
                  </Route>
                  <Route
                    path="/edit-ad/:id"
                    element={
                      <RequireAuth
                        roles={[
                          Role.AdvertiserAdmin,
                          Role.mediaChannelAdmin,
                          Role.mediaChannelUser
                        ]}
                      />
                    }
                  >
                    <Route
                      index
                      element={<CreateAd disabled={false} page={'Edit'} />}
                    />
                  </Route>
                  <Route path="/dashboard" element={<RequireAuth />}>
                    <Route index element={<Dashboard />} />
                  </Route>
                  <Route
                    path="/adverts"
                    element={
                      <RequireAuth
                        roles={[
                          Role.mediaChannelAdmin,
                          Role.mediaChannelUser,
                          Role.AdvertiserAdmin,
                          Role.AdvertiserUser
                        ]}
                      />
                    }
                  >
                    <Route index element={<Adverts />} />
                    <Route path="/adverts/all" element={<Adverts />} />
                    <Route path="/adverts/all/:id" element={<Adverts />} />
                    <Route path="/adverts/pending" element={<Adverts />} />
                  </Route>
                  <Route path="/profile" element={<RequireAuth />}>
                    <Route index element={<Profile />} />
                  </Route>
                  <Route path="/team-members" element={<RequireAuth />}>
                    <Route index element={<TeamMember />} />
                  </Route>
                  <Route
                    path="/organization"
                    element={
                      <RequireAuth
                        roles={[
                          Role.AdvertiserAdmin,
                          Role.AdvertiserUser,
                          Role.mediaChannelAdmin,
                          Role.mediaChannelUser
                        ]}
                      />
                    }
                  >
                    <Route index element={<Organization />} />
                  </Route>
                  <Route
                    path="/account"
                    element={
                      <RequireAuth
                        roles={[
                          Role.mediaChannelAdmin,
                          Role.mediaChannelUser,
                          Role.SuperAdmin
                        ]}
                      />
                    }
                  >
                    <Route index element={<Account />} />
                  </Route>
                  <Route
                    path="/advertiser/overview"
                    element={
                      <RequireAuth
                        roles={[
                          Role.mediaChannelAdmin,
                          Role.mediaChannelUser,
                          Role.SuperAdmin
                        ]}
                      />
                    }
                  >
                    <Route index element={<AdvertiserAdminOverview />} />
                  </Route>
                  <Route
                    path="/accounts/superadmin/advertiser"
                    element={<RequireAuth roles={[Role.SuperAdmin]} />}
                  >
                    <Route
                      index
                      element={<SuperAdminAccount type="advertiser" />}
                    />
                  </Route>
                  <Route
                    path="/accounts/superadmin/mediacompanies"
                    element={<RequireAuth roles={[Role.SuperAdmin]} />}
                  >
                    <Route
                      index
                      element={<SuperAdminAccount type="mediacompanies" />}
                    />
                  </Route>
                  <Route
                    path="/adverts/overview"
                    element={
                      <RequireAuth
                        roles={[
                          Role.mediaChannelAdmin,
                          Role.AdvertiserAdmin,
                          Role.mediaChannelUser
                        ]}
                      />
                    }
                  >
                    <Route index element={<AdvertsOverview />} />
                  </Route>
                  <Route
                    path="/superadmin/overview"
                    element={<RequireAuth roles={[Role.SuperAdmin]} />}
                  >
                    <Route index element={<SuperAdminOverview />} />
                  </Route>
                  <Route
                    path="/adverts/requests"
                    element={
                      <RequireAuth
                        roles={[Role.mediaChannelAdmin, Role.mediaChannelUser]}
                      />
                    }
                  >
                    <Route index element={<Request />} />
                  </Route>
                  <Route
                    path="/adlayout"
                    element={<RequireAuth roles={[Role.mediaChannelAdmin]} />}
                  >
                    <Route index element={<Adlayout />} />
                  </Route>
                  <Route
                    path="/create-adlayout"
                    element={<RequireAuth roles={[Role.mediaChannelAdmin]} />}
                  >
                    <Route
                      index
                      element={
                        <CreateAdlayout disabled={false} page={'Create'} />
                      }
                    />
                  </Route>
                  <Route
                    path="/ad-layout-configuration"
                    element={<RequireAuth roles={[Role.mediaChannelAdmin]} />}
                  >
                    <Route index element={<AdlayoutConfiguration />} />
                  </Route>
                  <Route
                    path="/edit-adlayout/:id"
                    element={<RequireAuth roles={[Role.mediaChannelAdmin]} />}
                  >
                    <Route
                      index
                      element={
                        <CreateAdlayout disabled={false} page={'Edit'} />
                      }
                    />
                  </Route>
                  <Route path="/forbidden" element={<RequireAuth />}>
                    <Route index element={<ForbiddenPage />} />
                  </Route>
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: '#51b06c'
            }
          },
          error: {
            style: {
              background: '#dd6d6d'
            }
          }
        }}
      >
        {(t) => (
          <ToastBar
            toast={t}
            style={{
              borderRadius: '4px',
              color: '#fff',
              padding: '16px',
              display: 'flex',
              rowGap: '12px'
            }}
          >
            {({ message }) => (
              <div className="flex space-x-2 items-center justify-evenly">
                {t.type === 'error' && (
                  <div>
                    <Icon name="email" size={20} />
                  </div>
                )}
                {t.type === 'success' && (
                  <div>
                    <Icon name="password" color="#fff" size={20} />
                  </div>
                )}
                {message}
              </div>
            )}
          </ToastBar>
        )}
      </Toaster>
    </>
  );
};

export default App;
