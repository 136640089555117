import React from 'react';
import { FieldType } from 'utils/enum';
import Checkbox from './components/Checkbox';
import Input from './components/Input';
import SingleSelect from './components/SingleSelect';
import Radio from './components/Radio';
import RadioButtonGroup from './components/RadioButtonGroup';
import UploadFile from './components/UploadFile';
import DateTime from './components/DateTime';
import UploadImage from './components/UploadImage';
import ChipSingleSelector from './components/ChipSingleSelector';

const fieldMap: Record<string, any> = {
  [FieldType.Input]: Input,
  [FieldType.Select]: SingleSelect,
  [FieldType.Radio]: RadioButtonGroup,
  [FieldType.Checkbox]: Checkbox,
  [FieldType.UploadFile]: UploadFile,
  [FieldType.DateTime]: DateTime,
  [FieldType.Checkbox]: Checkbox,
  [FieldType.UploadImage]: UploadImage,
  [FieldType.ChipSingleSelect]: ChipSingleSelector
};

type AppProps = {
  fields: Array<Record<string, any>>;
  className?: string;
  loading?: boolean;
};

const Layout: React.FC<AppProps> = ({ fields, loading, className = '' }) => {
  return (
    <div className={className}>
      {fields.map((field, index) => {
        const Component = fieldMap[field.type];
        return <Component key={index} loading={loading} {...field} />;
      })}
    </div>
  );
};

export default Layout;
