import Icon from 'components/Icon';
import React, { useEffect, useMemo, useState } from 'react';
import clx from 'classnames';
import { daysSinceCreation, TimestampToDateAndTime } from 'utils/time';
import Menu from 'components/Menu';
import { useNavigate } from 'react-router-dom';
import Modal from 'components/Modal';
import WhiteLoader from 'components/WhiteLoader';
import { useModal } from 'hooks/useModal';
import { toast } from 'react-hot-toast';
import { readAxiosErr } from 'utils/apiService';
import {
  useMutation,
  useQueryClient,
  QueryClient
} from '@tanstack/react-query';
import { approveAd, getDuplicate } from 'queries/ad';
import DeleteAd from 'components/DeleteAd';
import { Status } from 'utils/enum';
import _ from 'lodash';
import { AD_CHANNEL } from 'utils/map';
import useRole from 'hooks/useRole';

type AppProps = {
  id: string;
  mediaChannel: any;
  adName: string;
  status: Status;
  mediaLink: string;
  startDate: number;
  endDate: number;
  organization: any;
  createdAt?: number;
  dataTestId?: string;
};
const AdCard: React.FC<AppProps> = ({
  id,
  mediaChannel,
  adName,
  status,
  mediaLink,
  startDate,
  endDate,
  createdAt,
  organization,
  dataTestId
}) => {
  const queryClient = useQueryClient();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [isDeleteOpen, showDelete, closeDelete] = useModal();
  const { isMediaChannelAdmin, isAdvertiserAdmin, isAdvertiserUser } =
    useRole();

  const duplicateMutation = useMutation((id: string) => getDuplicate(id), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: (res: any) => {
      if (res) {
        navigate(`/edit-ad/${res._id}`);
        toast.success('Ad cloned successfully');
        queryClient.removeQueries(['adverts']);
      } else {
        toast.error('Something went wrong');
      }
    }
  });

  const changeStatusAdMutation = useMutation(
    (payload: any) => approveAd(payload),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        queryClient.invalidateQueries(['adverts']);
      }
    }
  );

  const options = useMemo(() => {
    let options: any[] = [
      ...(isAdvertiserAdmin || isAdvertiserUser
        ? [
            {
              label: 'Edit',
              dataTestId: 'edit-button',
              icon: 'edit',
              disabled: !(status === Status.Draft || status === Status.Pending),
              onClick: () => navigate(`/edit-ad/${id}`)
            },
            {
              label: 'Duplicate',
              dataTestId: 'duplicate-button',
              icon: 'duplicate',
              onClick: () => duplicateMutation.mutate(id)
            },
            {
              label: 'Delete',
              dataTestId: 'delete-button',
              icon: 'delete',
              disabled:
                status === Status.Approved || status === Status.Published,
              onClick: () => showDelete()
            }
          ]
        : [
            {
              label: 'Preview ad',
              dataTestId: 'approve-button',
              icon: 'delete',
              onClick: () => navigate(`/view-ad/${id}`)
            },
            {
              label: 'Approve ad',
              dataTestId: 'approve-button',
              icon: 'delete',
              disabled: !(status === Status.Pending),
              onClick: () => {
                setLoader(true);
                changeStatusAdMutation.mutate({
                  status: Status.Approved,
                  adId: id
                });
              }
            },
            {
              label: 'Reject ad',
              dataTestId: 'reject-button',
              icon: 'delete',
              disabled: !(status === Status.Pending),
              onClick: () => {
                setLoader(true);
                changeStatusAdMutation.mutate({
                  status: Status.Rejected,
                  adId: id
                });
              }
            },
            {
              label: 'Unpublish ad',
              dataTestId: 'unpublish-button',
              icon: 'delete',
              disabled: !(status === Status.Published),
              onClick: () => {
                setLoader(true);
                changeStatusAdMutation.mutate({
                  status: Status.Unpublished,
                  adId: id
                });
              }
            }
          ])
    ];
    return options;
  }, []);

  const showStatus = (status: Status) => {
    if (status === Status.Pending) {
      return (
        <div
          className="flex w-20 text-xs mt-36 p-2 drop-shadow-sm rounded-lg bg-yellow-100 text-yellow-800"
          data-testid={`ad-card-status-pending-${id}`}
        >
          <span className="mt-1 w-2 h-2 mr-1 rounded-full bg-yellow-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }

    if (status === Status.Draft) {
      return (
        <div
          className="flex w-16 text-xs mt-36 p-2 drop-shadow-sm rounded-lg bg-gray-100 text-gray-900"
          data-testid={`ad-card-status-draft-${id}`}
        >
          <span className="mt-1 w-2 h-2 mr-2 rounded-full bg-gray-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }

    if (status === Status.Unpublished) {
      return (
        <div
          className="flex w-28 text-xs mt-36 p-2 drop-shadow-sm rounded-lg bg-purple-100 text-purple-900"
          data-testid={`ad-card-status-unpublished-${id}`}
        >
          <span className="mt-1 w-2 h-2 mr-2 rounded-full bg-purple-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }

    if (status === Status.Published) {
      return (
        <div
          className="flex w-24 text-xs mt-36 p-2 drop-shadow-sm rounded-lg bg-blue-100 text-blue-900"
          data-testid={`ad-card-status-published-${id}`}
        >
          <span className="mt-1 w-2 h-2 mr-2 rounded-full bg-blue-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }

    if (status === Status.Approved) {
      return (
        <div
          className="flex w-24 text-xs mt-36 p-2 drop-shadow-sm rounded-lg bg-green-100 text-green-800"
          data-testid={`ad-card-status-approved-${id}`}
        >
          <span className="mt-1 w-2 h-2 mr-2 rounded-full bg-green-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }

    if (status === Status.Rejected) {
      return (
        <div
          className="flex w-24 text-xs mt-36 p-2 drop-shadow-sm rounded-lg bg-red-100 text-red-800"
          data-testid={`ad-card-status-rejected-${id}`}
        >
          <span className="mt-1 w-2 h-2 mr-2 rounded-full bg-red-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }
  };

  const days = daysSinceCreation(Number(createdAt));

  return (
    <div
      key={id}
      className={clx(
        'text-white flex flex-col bg-cover bg-center justify-between relative shadow-sm rounded-lg mt-2 bg-white pb-4 px-5 pt-8 w-96 h-full',
        {
          'ad-card-empty-state': !mediaLink
        }
      )}
      style={{
        background: `linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 0.8)
      ), url(${mediaLink})`
      }}
      data-testid={`ad-card-${id}`}
    >
      <div>{showStatus(status)}</div>
      <div
        onClick={() => {
          navigate(`/view-ad/${id}`);
        }}
        className="cursor-pointer"
        data-testid={`ad-card-view-ad-${id}`}
      >
        {adName}
      </div>
      <div className="text-xs">
        {!isMediaChannelAdmin ? (
          <>
            Ad Channel{' '}
            <span
              className="text-gray-200"
              data-testid={`ad-card-channel-${id}`}
            >
              {mediaChannel?.orgName}
            </span>
          </>
        ) : (
          <>
            Advertiser{' '}
            <span
              className="text-gray-200"
              data-testid={`ad-card-channel-${id}`}
            >
              {organization?.orgName}
            </span>
          </>
        )}
      </div>
      <div className="flex text-xs justify-between border-t-2 py-1 border-b-2 border-gray-500">
        <div>
          Start{' '}
          <span
            className="text-gray-200"
            data-testid={`ad-card-start-date-${id}`}
          >
            {TimestampToDateAndTime(startDate)}
          </span>
        </div>
        <div>
          End{' '}
          <span
            className="text-gray-200"
            data-testid={`ad-card-end-date-${id}`}
          >
            {TimestampToDateAndTime(endDate)}
          </span>
        </div>
      </div>
      <div className="flex justify-between py-1">
        <div className="text-xs" data-testid={`ad-card-created-at-${id}`}>
          {days === 1 || days === 0 ? '1 day ago' : `${days} days ago`}
        </div>
        <div>
          <Menu options={options} />
        </div>
      </div>
      <Modal isOpen={isDeleteOpen} closeModal={closeDelete} width="40%">
        <DeleteAd id={id} closeModal={closeDelete} />
      </Modal>
      <WhiteLoader show={loader} />
    </div>
  );
};

export default AdCard;
