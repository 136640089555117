export const SESSION_KEY = 'sid';

export const PRIMARY_COLOR = '#7854F6';

export const TEXT_COLOR = '#333333';

export const BORDER_COLOR = '#D5D7D8';

export const codeImplement = (code: string) => {
  return `<html>
  <head>
  This is the head of your page
  <title>Example HTML page</title>
  </head>
  <body>
  This is the body of your page.
  <div align="center">
      ${code}
  </div>
  </body>
  </html>`;
};
