import React, { useRef } from 'react';
import { SubmitHandler } from 'react-hook-form';
import Icon from 'components/Icon';

type AppProps = {
  label?: string;
  name: string;
  className?: string;
  onChange: SubmitHandler<any>;
  error?: string;
  loading?: boolean;
  image?: boolean;
  disabled?: boolean;
  dataTestId?: string;
  supportText?: string;
};

const UploadFile = React.forwardRef<HTMLInputElement, AppProps>(
  (
    {
      name,
      label = '',
      onChange,
      image = false,
      supportText = '',
      className = '',
      disabled = false,
      error = null,
      loading = false,
      dataTestId = ''
    },
    ref
  ) => {
    const inputFile = useRef<HTMLInputElement | null>(null);
    return (
      <div className={className} data-testid={dataTestId}>
        {label && <div className="font-semibold text-sm mb-2">{label}</div>}
        <input
          type="file"
          ref={inputFile}
          disabled={disabled}
          onChange={onChange}
          className="border file:mr-4 file:py-2 file:px-4
          file:rounded-lg file:border-0
          file:text-sm file:font-semibold w-full
          file:bg-primary file:text-white"
          accept={image ? '.jpg,.jpeg,.png,.gif' : '.mp4'}
          alt="file-upload"
        />
        {supportText && (
          <div className="text-sm font-normal w-1/2 mt-3 text-gray-500">
            {supportText}
          </div>
        )}
        {error && <div className="text-error text-xs">*{error}</div>}
      </div>
    );
  }
);

UploadFile.displayName = 'UploadFile';

export default UploadFile;
