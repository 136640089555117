import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Status } from 'utils/enum';
import Modal from 'components/Modal';
import Button, { Variants } from 'components/Button';
import Tabs from 'components/Tabs';
import { useModal } from 'hooks/useModal';
import AddTeamMember from './components/addTeamMember';
import {
  removeUser,
  resendInvite,
  useAllInvitees,
  useAllActiveSuperAdmins
} from 'queries/invites';
import { readAxiosErr } from 'utils/apiService';
import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import NameCircle from '../../components/NameCircle/NameCircle';
import AppShell from 'components/AppShell';
import { Role } from 'utils/map';
import RevokeInvite from './components/revokeInvitee';
import { useLocation } from 'react-router-dom';
import useRole from 'hooks/useRole';
import { ROLE } from 'data/options';

const TeamMember = () => {
  const [status, setStatus] = useState(Status.Pending);
  const {
    isSuperAdmin,
    isAdvertiserAdmin,
    isMediaChannelAdmin,
    isMediaChannelUser,
    isAdvertiserUser
  } = useRole();
  const [isAddTeamOpen, showAddTeam, closeAddTeam] = useModal();
  const [memberId, setMemberId] = useState('');
  const [isRevokeOpen, showRevoke, closeRevoke] = useModal();
  const location = useLocation();
  let pathname = location.pathname;
  const queryClient = useQueryClient();

  const handleCloseRevoke = () => {
    setMemberId('');
    closeRevoke();
  };

  let useInvitees = isSuperAdmin ? useAllActiveSuperAdmins : useAllInvitees;

  let { data: pendingInvitees } = useInvitees({
    status: Status.Pending
  });

  let { data: activeInvitees } = useInvitees({
    status: Status.Active
  });

  let { data: pendingSuperAdmins } = useAllActiveSuperAdmins({
    status: Status.Pending
  });

  let { data: activeSuperAdmins } = useAllActiveSuperAdmins({
    status: Status.Active
  });

  pendingInvitees = isSuperAdmin ? pendingSuperAdmins : pendingInvitees;
  activeInvitees = isSuperAdmin ? activeSuperAdmins : activeSuperAdmins;

  const links = [
    {
      link: '/profile',
      label: 'My profile',
      className: pathname === '/profile' ? 'bg-gray-700' : 'bg-background-theme'
    },
    {
      link: '/organization',
      label: 'Organization',
      className:
        pathname === '/organization' ? 'bg-gray-700' : 'bg-background-theme'
    },
    {
      link: '/team-members',
      label: 'Team members',
      className:
        pathname === '/team-members' ? 'bg-gray-700' : 'bg-background-theme'
    }
  ];
  if (isSuperAdmin || isMediaChannelUser || isAdvertiserUser) {
    delete links[1];
  }
  const resendInviteMutation = useMutation((id: string) => resendInvite(id), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: () => {
      toast.success('Invite Resent');
    }
  });

  const removeUserMutation = useMutation((id: string) => removeUser(id), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['allInvitees']);
      toast.success('User removed');
    }
  });

  return (
    <AppShell>
      <div className="bg-background-theme h-theme grid">
        <div className="mt-8 mb-8 ml-16 text-white text-4xl">
          <div className="flex justify-between">
            <div className="font-semibold text-4xl" data-testid="profile-label">
              Profile
            </div>
            <Button
              label="Add team members"
              className="w-44 bg-primary mr-48"
              data-testid="create-ad-dashboard"
              onClick={showAddTeam}
            />
          </div>
          <Tabs links={links} />
        </div>
        <div className="flex flex-col w-3/5 mt-10 m-auto">
          <div className="bg-white rounded-l border shadow-lg w-full mb-8">
            <div className="font-bold text-xl px-8 my-4">Invites sent</div>
            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200 mb-8"></hr>
            {pendingInvitees?.map((d: any) => (
              <div className="flex m-8 border p-4" key={d.id}>
                <div>
                  <NameCircle firstName={d.email.toUpperCase()} lastName="" />{' '}
                </div>
                <div className="ml-2 w-60">
                  <div
                    className="font-semibold text-base truncate"
                    title={d.email}
                  >
                    {d.email}
                  </div>
                  <div
                    className="text-sm font-normal text-gray-500 truncate"
                    title={d.inviteeEmail}
                  >
                    Invited by {d.inviteeEmail}
                  </div>
                </div>
                <div className="flex w-10 ml-60">
                  <Button
                    variant={Variants.Negative}
                    label="Revoke"
                    onClick={() => {
                      setMemberId(d.id);
                      showRevoke();
                    }}
                  ></Button>
                  <Button
                    label="Resend"
                    className="ml-4"
                    onClick={() => {
                      resendInviteMutation.mutate(d.id);
                    }}
                  ></Button>
                </div>
              </div>
            ))}
          </div>
          <div className="bg-white rounded-l border shadow-lg w-full mb-8">
            <div className="font-bold text-xl px-8 mt-4">Active members</div>
            <p className="font-normal text-sm px-8 mb-4 text-gray-500">
              View all active members in your organization with respective role
              access
            </p>
            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200 mb-8"></hr>
            <table>
              <thead>
                <tr className="bg-gray-50 text-gray-500 font-semibold text-xs">
                  <th className="w-80 text-left py-4 pl-4">Name</th>
                  <th className="w-80 text-left py-4 pl-4">Email</th>
                  <th className="w-80 text-left py-4 pl-4">Role</th>
                  <th className="w-80 my-4"></th>
                </tr>
              </thead>
              <tbody>
                {activeInvitees?.map((d: any, i: number) => (
                  <tr
                    className={`border-t-2 text-gray-900 text-base ${
                      i % 2 === 0 ? '' : 'bg-gray-50'
                    }`}
                    key={d.id}
                  >
                    <td
                      className="w-60 py-4 pl-4 flex"
                      title={`${d?.firstName} ${d?.lastName}`}
                    >
                      <NameCircle
                        firstName={d.firstName}
                        lastName={d.lastName}
                      />{' '}
                      <div className="mt-3 truncate w-40">
                        {d?.firstName
                          ? d?.firstName
                          : '' + ' ' + (d?.lastName ? d?.lastName : '')}
                      </div>
                    </td>
                    <td className="py-4" title={d.email}>
                      <div className="w-44 truncate">{d.email}</div>
                    </td>
                    <td className="w-80 py-4 pl-4 truncate">{Role[d.role]}</td>
                    <td className="w-80 py-4 pl-4">
                      <Select
                        options={[
                          {
                            value: `${d?.id}`,
                            label: 'Remove User',
                            isdisabled: isAdvertiserAdmin
                              ? Role[d.role] === 'Admin'
                              : false ||
                                Role[d.role] === 'Admin' ||
                                isMediaChannelAdmin
                              ? Role[d.role] === 'Media Admin'
                              : false
                          }
                        ]}
                        isOptionDisabled={(option) => option.isdisabled}
                        onChange={(selectedOption) => {
                          if (selectedOption?.value) {
                            removeUserMutation.mutate(selectedOption?.value);
                          }
                        }}
                        isSearchable={false}
                        placeholder="Actions"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal isOpen={isAddTeamOpen} closeModal={closeAddTeam} width="40%">
          <AddTeamMember closeModal={closeAddTeam} />
        </Modal>
        {isRevokeOpen && (
          <Modal
            isOpen={isRevokeOpen}
            closeModal={handleCloseRevoke}
            width="40%"
          >
            <RevokeInvite id={memberId} closeModal={handleCloseRevoke} />
          </Modal>
        )}
      </div>
    </AppShell>
  );
};

export default TeamMember;
