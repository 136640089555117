import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const ImageUpload: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 36,
  color = '#666',
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size - 2}
    height={size}
    fill="none"
    viewBox="0 0 34 36"
  >
    <path
      stroke="#9CA3AF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8.667 24.666A6.666 6.666 0 017.2 11.495 8.335 8.335 0 0123.5 8h.167a8.334 8.334 0 011.666 16.5M22 19.666l-5-5m0 0l-5 5m5-5v20"
    ></path>
  </svg>
);

export default ImageUpload;
