import useAuth from 'hooks/useAuth';
import { Role } from 'utils/enum';

const useRole = () => {
  const { user } = useAuth();

  if (!user) {
    return {
      isSuperAdmin: false,
      isAtcSales: false,
      isAdChannelAdmin: false,
      isAdChannelUser: false,
      isAdvertiserAdmin: false,
      isAdvertiserUser: false
    };
  }

  return {
    isSuperAdmin: user.role === Role.SuperAdmin,
    isAtcSales: user.role === Role.AtcSales,
    isMediaChannelAdmin: user.role === Role.mediaChannelAdmin,
    isMediaChannelUser: user.role === Role.mediaChannelUser,
    isAdvertiserAdmin: user.role === Role.AdvertiserAdmin,
    isAdvertiserUser: user.role === Role.AdvertiserUser
  };
};

export default useRole;
