import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const SvgFilter: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 18,
  color = '#666',
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M2.47 2.47a.75.75 0 0 0-.22.53v1.94c0 .198.08.39.22.53l4.81 4.81c.14.14.22.332.22.53v4.94l3-3v-1.94c0-.198.08-.39.22-.53l4.81-4.81a.75.75 0 0 0 .22-.53V3a.75.75 0 0 0-.75-.75H3a.75.75 0 0 0-.53.22Z"
      stroke="#6B7280"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgFilter;
