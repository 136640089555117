import React from 'react';
import Button, { Size, Variants } from 'components/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { readAxiosErr } from 'utils/apiService';
import Icon from 'components/Icon';
import { toast } from 'react-hot-toast';
import { deleteAd } from 'queries/ad';

type App = {
  id: string;
  closeModal: () => void;
};

const DeleteAd: React.FC<App> = ({ id, closeModal }) => {
  const queryClient = useQueryClient();

  const deleteMutation = useMutation((id: string) => deleteAd(id), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: () => {
      queryClient.removeQueries(['adverts']);
      toast.success('Ad deleted successfully');
      closeModal();
    }
  });

  return (
    <div className="px-24 py-12" data-testid="delete-ad-box">
      <div className="mb-6 m-auto w-8" data-testid="delete-icon">
        <Icon name="alert" />
      </div>
      <p className="text-lg text-center" data-testid="delete-ad">
        Are you sure you want to delete this advert?
      </p>
      <p className="text-lg mb-9 text-center" data-testid="delete-ad">
        This action cannot be undone.
      </p>
      <div className="flex justify-end">
        <Button
          label="No, cancel"
          className="w-32 mr-4 border-0"
          size={Size.Small}
          variant={Variants.Negative}
          dataTestId="cancel"
          disabled={deleteMutation.isLoading}
          onClick={closeModal}
        />
        <Button
          label="Yes, i'm sure"
          className="w-32"
          variant={Variants.Alert}
          onClick={() => deleteMutation.mutate(id)}
          loading={deleteMutation.isLoading}
          dataTestId="delete"
        />
      </div>
    </div>
  );
};

export default DeleteAd;
