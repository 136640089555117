import useRole from 'hooks/useRole';
import { approveAd, useAdDetail, useApproveAd } from 'queries/ad';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import AdvertiserAdminAdConfiguration from './components/AdvertiserAdmin.adConfiguration';
import MediaAdminAdConfiguration from './components/MediaAdmin.AdConfirguration';
import { Status } from 'utils/enum';
import _ from 'lodash';
import Button, { Variants } from 'components/Button';
import { Query, useMutation, useQueryClient } from '@tanstack/react-query';
import { readAxiosErr } from 'utils/apiService';
import { toast } from 'react-hot-toast';
import WhiteLoader from 'components/WhiteLoader';

type AppProps = {
  disabled: boolean;
  page: string;
};

const CreateAd: React.FC<AppProps> = ({ disabled, page }) => {
  const { id = '' } = useParams();
  const [adImage, setAdImage] = useState('');
  const [imgMobile, setImgMobile] = useState('');
  const [imgDesktop, setImgDesktop] = useState('');
  const {
    isAdvertiserAdmin,
    isAdvertiserUser,
    isMediaChannelAdmin,
    isMediaChannelUser
  } = useRole();
  const { data, isLoading } = useAdDetail(id);
  const queryClient = useQueryClient();
  const [loader, setLoader] = useState(false);

  const changeStatusAdMutation = useMutation(
    (payload: any) => approveAd(payload),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        queryClient.invalidateQueries(['adDetails']);
        queryClient.invalidateQueries(['adverts']);
      }
    }
  );

  useEffect(() => {
    setImgMobile(
      adImage.substring(0, 50) + 'w_595,h_330,c_fill/' + adImage.substring(50)
    );
    setImgDesktop(
      adImage.substring(0, 50) + 'w_334,h_518,c_fill/' + adImage.substring(50)
    );
  }, [adImage]);

  const showStatus = (status: Status) => {
    if (status === Status.Pending) {
      return (
        <div
          className="flex w-20 text-xs p-2 mt-2 ml-4 drop-shadow-sm rounded-lg bg-yellow-100 text-yellow-800"
          data-testid={`ad-card-status-pending-${id}`}
        >
          <span className="mt-1 w-2 h-2 mr-1 rounded-full bg-yellow-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }

    if (status === Status.Draft) {
      return (
        <div
          className="flex w-16 text-xs p-2 mt-2 ml-4 drop-shadow-sm rounded-lg bg-gray-100 text-gray-900"
          data-testid={`ad-card-status-draft-${id}`}
        >
          <span className="mt-1 w-2 h-2 mr-2 rounded-full bg-gray-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }

    if (status === Status.Unpublished) {
      return (
        <div
          className="flex w-28 text-xs p-2 mt-2 ml-4 drop-shadow-sm rounded-lg bg-purple-100 text-purple-900"
          data-testid={`ad-card-status-unpublished-${id}`}
        >
          <span className="mt-1 w-2 h-2 mr-2 rounded-full bg-purple-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }

    if (status === Status.Published) {
      return (
        <div
          className="flex w-24 text-xs mt-2 ml-4 p-2 drop-shadow-sm rounded-lg bg-blue-100 text-blue-900"
          data-testid={`ad-card-status-published-${id}`}
        >
          <span className="mt-1 w-2 h-2 mr-2 rounded-full bg-blue-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }

    if (status === Status.Approved) {
      return (
        <div
          className="flex w-24 text-xs mt-2 ml-4 p-2 drop-shadow-sm rounded-lg bg-green-100 text-green-800"
          data-testid={`ad-card-status-approved-${id}`}
        >
          <span className="mt-1 w-2 h-2 mr-2 rounded-full bg-green-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }

    if (status === Status.Rejected) {
      return (
        <div
          className="flex w-24 text-xs mt-2 ml-4 p-2 drop-shadow-sm rounded-lg bg-red-100 text-red-800"
          data-testid={`ad-card-status-rejected-${id}`}
        >
          <span className="mt-1 w-2 h-2 mr-2 rounded-full bg-red-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }
  };

  const replaceImage = (err: any) => {
    if (err.target.alt === 'adPreviewDesktop') {
      err.target.src =
        'https://res.cloudinary.com/dg2mwhsdc/image/upload/v1672334899/Ad-preview-desktop_jysio3.png';
    } else {
      err.target.src =
        'https://res.cloudinary.com/dg2mwhsdc/image/upload/v1672335160/Frame_64_yovifk.png';
    }
  };

  return (
    <div>
      <div className="bg-background-theme h-theme grid">
        <div className="flex justify-between mt-8 mb-8 ml-16 text-white text-4xl">
          <div className="font-semibold text-4xl" data-testid="create-ad-label">
            {isAdvertiserAdmin || isAdvertiserUser ? (
              <div>{page} ad</div>
            ) : (
              <div className="flex">
                <div>Preview Ad {'> '} </div>
                <div className="font-light ml-2">{data?.adName}</div>
                <div>{showStatus(data?.status)}</div>
              </div>
            )}
          </div>
          {(isMediaChannelAdmin || isMediaChannelUser) &&
            data?.status === Status.Pending && (
              <div className="mr-28">
                <Button
                  className="mr-3"
                  label="Approve ad"
                  onClick={() => {
                    setLoader(true);
                    changeStatusAdMutation.mutate({
                      status: Status.Approved,
                      adId: id
                    });
                  }}
                />
                <Button
                  label="Reject ad"
                  onClick={() => {
                    setLoader(true);
                    changeStatusAdMutation.mutate({
                      status: Status.Rejected,
                      adId: id
                    });
                  }}
                />
              </div>
            )}
          {!isAdvertiserAdmin &&
            !isAdvertiserUser &&
            data?.status === Status.Published && (
              <div className="mr-28">
                <Button
                  className="mr-3"
                  label="Unpublish ad"
                  onClick={() => {
                    setLoader(true);
                    changeStatusAdMutation.mutate({
                      status: Status.Unpublished,
                      adId: id
                    });
                  }}
                />
              </div>
            )}
        </div>
        <div className="flex">
          <div className="w-2/5 mt-8 ml-8 mb-28">
            <div className="bg-white rounded-l border shadow-lg">
              <div
                className="font-bold text-xl ml-8 mt-4 mb-3"
                data-testid="ad-configuration-label"
              >
                Ad configuration
              </div>
              <hr className="h-px bg-horizontalRule border-0 dark:bg-horizontalRule"></hr>
              {isAdvertiserAdmin || isAdvertiserUser ? (
                <AdvertiserAdminAdConfiguration
                  adImage={adImage}
                  setAdImage={setAdImage}
                  disabled={disabled}
                />
              ) : (
                <MediaAdminAdConfiguration
                  adImage={adImage}
                  setAdImage={setAdImage}
                  disabled={disabled}
                />
              )}
            </div>
          </div>
          <div className="w-3/5 flex fixed right-0 justify-between mt-8">
            <div className="ml-16">
              <div
                className="font-bold text-xl mb-4"
                data-testid="ad-preview-desktop"
              >
                Ad preview (Desktop)
              </div>
              <img
                className="rounded-lg"
                src={imgMobile}
                alt="adPreviewDesktop"
                data-testid="ad-preview-img-desktop"
                onError={replaceImage}
              />
            </div>
            <div className="ml-8 mr-8">
              <div
                className="font-bold text-xl mb-4"
                data-testid="ad-preview-mobile"
              >
                Ad preview (Mobile)
              </div>
              <img
                className="rounded-lg"
                src={imgDesktop}
                data-testid="ad-preview-img-mobile"
                alt="adPreviewMobile"
                onError={replaceImage}
              />
            </div>
          </div>
        </div>
      </div>
      <WhiteLoader show={loader} />
    </div>
  );
};

export default CreateAd;
