export const INDUSTRY = [
  {
    label: 'Advertising ',
    value: 'advertising'
  },
  {
    label: 'Automotive',
    value: 'automotive'
  },
  {
    label: 'Banking',
    value: 'banking'
  },
  {
    label: 'Business Services/Agency',
    value: 'business-services-agency'
  },
  {
    label: 'Business Services/Agency-Advertising',
    value: 'business-services-agency-advertising'
  },
  {
    label: 'Computer software',
    value: 'computer-software'
  },
  {
    label: 'Consumer Packaged Goods',
    value: 'consumer-packaged-goods'
  },
  {
    label: 'Consumer Products',
    value: 'consumer-products'
  },
  {
    label: 'Consumer Services',
    value: 'consumer-services'
  },
  {
    label: 'Data Brokerage',
    value: 'data-brokerage'
  },
  {
    label: 'Department Stores',
    value: 'department-stores'
  },
  {
    label: 'Directory Publishers',
    value: 'directory-publishers'
  },
  {
    label: 'Distribution',
    value: 'distribution'
  },
  {
    label: 'E-commerce',
    value: 'ecommerce'
  },
  {
    label: 'Education',
    value: 'education'
  },
  {
    label: 'Energy and Utilities',
    value: 'energy-and-utilities'
  },
  {
    label: 'Entertainment',
    value: 'entertainment'
  },
  {
    label: 'Financial Services',
    value: 'financial-services'
  },
  {
    label: 'Financial Services-Brokerage/Investment',
    value: 'financial-services-brokerage-investment'
  },
  {
    label: 'Financial Services-Retail Banking',
    value: 'financial-services-retail-banking'
  },
  {
    label: 'Government',
    value: 'government'
  },
  {
    label: 'Healthcare',
    value: 'healthcare'
  },
  {
    label: 'Insurance',
    value: 'insurance'
  },
  {
    label: 'IT Services',
    value: 'it-Services'
  },
  {
    label: 'Manufacturing',
    value: 'manufacturing'
  },
  {
    label: 'Manufacturing - Durables',
    value: 'manufacturing-durables'
  },
  {
    label: 'Media and Publishers',
    value: 'media-and-publishers'
  },
  {
    label: 'Non-Profit',
    value: 'non-profit'
  },
  {
    label: 'Other',
    value: 'other'
  },
  {
    label: 'Print & Digital Media',
    value: 'print-digital-media'
  },
  {
    label: 'Professional Sports',
    value: 'professional-sports'
  },
  {
    label: 'Retail',
    value: 'retail'
  },
  {
    label: 'Technology',
    value: 'technology'
  },
  {
    label: 'Travel and Hospitality',
    value: 'travel-and-hospitality'
  }
];

export const COUNTRY = [
  {
    label: 'United States of America',
    value: 'usa',
    states: [
      { label: 'Alaska', value: 'Alaska' },
      { label: 'Alabama', value: 'Alabama' },
      { label: 'Arkansas', value: 'Arkansas' },
      { label: 'Arizona', value: 'Arizona' },
      { label: 'California', value: 'California' },
      { label: 'Colorado', value: 'Colorado' },
      { label: 'Connecticut', value: 'Connecticut' },
      { label: 'District of Columbia', value: 'District of Columbia' },
      { label: 'Delaware', value: 'Delaware' },
      { label: 'Florida', value: 'Florida' },
      { label: 'Georgia', value: 'Georgia' },
      { label: 'Hawaii', value: 'Hawaii' },
      { label: 'Iowa', value: 'Iowa' },
      { label: 'Idaho', value: 'Idaho' },
      { label: 'IL', value: 'Illinois' },
      { label: 'Illinois', value: 'Indiana' },
      { label: 'Kansas', value: 'Kansas' },
      { label: 'Kentucky', value: 'Kentucky' },
      { label: 'Louisiana', value: 'Louisiana' },
      { label: 'Massachusetts', value: 'Massachusetts' },
      { label: 'Maryland', value: 'Maryland' },
      { label: 'Maine', value: 'Maine' },
      { label: 'Michigan', value: 'Michigan' },
      { label: 'Minnesota', value: 'Minnesota' },
      { label: 'Missouri', value: 'Missouri' },
      { label: 'Mississippi', value: 'Mississippi' },
      { label: 'Montana', value: 'Montana' },
      { label: 'North Carolina', value: 'North Carolina' },
      { label: 'North Dakota', value: 'North Dakota' },
      { label: 'Nebraska', value: 'Nebraska' },
      { label: 'New Hampshire', value: 'New Hampshire' },
      { label: 'New Jersey', value: 'New Jersey' },
      { label: 'New Mexico', value: 'New Mexico' },
      { label: 'Nevada', value: 'Nevada' },
      { label: 'New York', value: 'NewYork' },
      { label: 'Ohio', value: 'Ohio' },
      { label: 'Oklahoma', value: 'Oklahoma' },
      { label: 'Oregon', value: 'Oregon' },
      { label: 'Pennsylvania', value: 'Pennsylvania' },
      { label: 'Rhode Island', value: 'Rhode Island' },
      { label: 'South Carolina', value: 'South Carolina' },
      { label: 'South Dakota', value: 'South Dakota' },
      { label: 'Tennessee', value: 'Tennessee' },
      { label: 'Texas', value: 'Texas' },
      { label: 'Utah', value: 'Utah' },
      { label: 'Virginia', value: 'Virginia' },
      { label: 'Vermont', value: 'Vermont' },
      { label: 'Washington', value: 'Washington' },
      { label: 'Wisconsin', value: 'Wisconsin' },
      { label: 'West Virginia', value: 'West Virginia' },
      { label: 'Wyoming', value: 'Wyoming' }
    ]
  }
];

export const CHANNEL = [
  {
    label: 'Unboxed',
    value: 'UNBOXED'
  },
  {
    label: 'Auzmor Office',
    value: 'AUZMOR_OFFICE'
  }
];

export const FORMAT = [
  {
    name: 'Image',
    value: 'image',
    label: 'Image',
    size: [
      { label: '300x300', value: 'image(300x300)' },
      { label: '600x314', value: 'image(600x314)' }
    ]
  },
  {
    name: 'Video',
    value: 'video',
    label: 'Video',
    size: [
      { label: '300x60', value: 'video(300x300)' },
      { label: '720x720', value: 'video(720x720)' }
    ]
  }
];

export const SIZE = [
  {
    label: 'Image (300x300 , 600x314)',
    value: 'image-size'
  },
  {
    label: 'Video (300x60 , 720x720)',
    value: 'video-size'
  }
];

export const ROLE = [
  {
    label: 'Super admin',
    value: 'SUPER_ADMIN'
  },
  {
    label: 'ATC sales',
    value: 'ATC_SALES'
  },
  {
    label: 'Media channel admin',
    value: 'MEDIA_CHANNEL_ADMIN'
  },
  {
    label: 'Media channel user',
    value: 'MEDIA_CHANNEL_USER'
  },
  {
    label: 'Advertiser admin',
    value: 'ADVERTISER_ADMIN'
  },
  {
    label: 'Advertiser user',
    value: 'ADVERTISER_USER'
  }
];

export const POSITION = [
  {
    label: 'Top Left',
    value: 'top-left'
  },
  {
    label: 'Top',
    value: 'top'
  },
  {
    label: 'Top Right',
    value: 'top-right'
  },
  {
    label: 'Center Left',
    value: 'center-left'
  },
  {
    label: 'Center',
    value: 'center'
  },
  {
    label: 'Center Right',
    value: 'center-right'
  },
  {
    label: 'Bottom Left',
    value: 'bottom-left'
  },
  {
    label: 'Bottom',
    value: 'bottom'
  },
  {
    label: 'Bottom Right',
    value: 'bottom-right'
  }
];

export const DELIVERY = [
  { name: 'Desktop', value: 'desktop', label: 'Desktop' },
  { name: 'Mobile', value: 'mobile', label: 'Mobile' },
  {
    name: 'Desktop and Mobile',
    value: 'DESKTOP_AND_MOBILE',
    label: 'Desktop and Mobile'
  }
];

export const FREQUENCY = [
  {
    label: 1,
    value: 1
  },
  {
    label: 2,
    value: 2
  },
  {
    label: 3,
    value: 3
  }
];

export const SUPPORTTEXT = [
  {
    value: 'image',
    label:
      'Max. file size: 250kb, resolution: 72dpi, file formats: JPG, PNG, GIF'
  },
  {
    value: 'video',
    label: 'Max. file size: 1MB, resolution: 72dpi, file formats: MP4'
  }
];

export const DAYS = [
  {
    label: '7 days',
    value: '7'
  },
  {
    label: '30 days',
    value: '30'
  },
  {
    label: '3 months',
    value: '3'
  }
];

export const Sort_Ad = [
  {
    label: 'Latest',
    value: 'latest'
  },
  {
    label: 'Oldest',
    value: 'oldest'
  }
];

export const ADVERTISER_ADMIN_LINK = [
  {
    link: '/profile',
    label: 'My profile',
    className: 'bg-white'
  },
  {
    link: '/organization',
    label: 'Organization',
    className: 'bg-white'
  },
  {
    link: '/team-members',
    label: 'Team members',
    className: 'bg-white'
  }
];

export const ADSIZE_DESKTOP = [
  { value: 'medium-rectangle', label: 'Medium rectangle (300 x 250 px)' },
  { value: 'large-rectangle', label: 'Large rectangle (336 x 280 px)' },
  { value: 'half-page', label: 'Half page (300 x 600 px)' },
  { value: 'leaderboard', label: 'Leaderboard (728 x 90 px)' },
  { value: 'billboard', label: 'Billboards (970 x 250 px)' },
  { value: 'large-billboard', label: 'Large billboards (970 x 90 px)' },
  { value: 'banner', label: 'Banner (468 x 60 px)' },
  { value: 'portrait', label: 'Portrait (200 x 1050 px)' },
  { value: 'wide-billboard', label: 'Wide Billboard (1200 x 280)' },
  { value: 'sidebar', label: 'Sidebar (384 x 280)' },
  { value: 'extra-wide-billboard', label: 'Extra Wide Billboard (1200 x 300)' },
  { value: 'wide-skyscraper', label: 'Wide Skyscraper (384 x 800)' },
  { value: 'leaderboard', label: 'Leaderboard (683 x 280)' },
  { value: 'half-page', label: 'Half Page (340 x 800)' },
  {
    value: 'in-article-leaderboard',
    label: 'In-Article Leaderboard (683 x 200)'
  },
  { value: 'skyscraper', label: 'Skyscraper (200 x 800)' }
];

export const ADSIZE_MOBILE = [
  { value: 'large-mobile-banner', label: 'Large mobile banner (320 x 100 px)' },
  { value: 'mobile-leaderboard', label: 'Mobile leaderboards (320 x 50 px)' },
  { value: 'small-rectangle', label: 'Small rectangle (180 x 150 px)' },
  { value: 'half-banner', label: 'Half banner (234 x 60 px)' },
  { value: 'vertical-banner', label: 'Vertical banner (120 x 240 px)' },
  { value: 'wide-skyscraper', label: 'Wide skyscraper (160 x 600 px)' },
  { value: 'skyscraper', label: 'Skyscraper (120 x 600 px)' },
  { value: 'portrait', label: 'Portrait (300 x 1050 px)' },
  { value: 'square', label: 'Square (250 x 250 px)' },
  { value: 'small-square', label: 'Small square (200 x 200 px)' },
  { value: 'button', label: 'Button (125 x 125 px)' }
];
