import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import AppShell from 'components/AppShell';
import CodeSnippet from './CodeSnippet';
import { codeImplement } from 'utils/constants';

const AdlayoutConfiguration = () => {
  const [layoutId, setLayoutId] = useState('');
  const [mediaChannelOrgId, setMediaChannelOrgId] = useState('');
  const URL = process.env.REACT_APP_SCRIPT_URL;
  const codeSnippetTemplate = `
    <script async src="${URL}?layoutId=
    __LAYOUT_ID__&mediaChannelOrgId=__MEDIA_CHANNEL_ORG_ID__" crossorigin="anonymous"></script>
    <ins
    class="adsbymedia"
    mediaChannelOrgId=${mediaChannelOrgId}
    layoutId=${layoutId}
    style="width: 728px; height: 90px">
    </ins>
  `;

  const updateCodeSnippet = () => {
    const updatedCodeSnippet = codeSnippetTemplate
      .replace('__LAYOUT_ID__', layoutId)
      .replace('__MEDIA_CHANNEL_ORG_ID__', mediaChannelOrgId);
    return updatedCodeSnippet;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setLayoutId(searchParams.get('layoutId') || '');
    setMediaChannelOrgId(searchParams.get('mediaChannelOrgId') || '');
  }, []);

  return (
    <AppShell>
      <div className="bg-background-theme h-theme grid">
        <div className="mt-8 mb-8 ml-36 text-white text-4xl">
          <div className="flex justify-between">
            <div className="font-semibold text-4xl" data-testid="profile-label">
              Create ad layout
            </div>
            <Button
              label="Create ad layout"
              className="w-44 bg-primary mr-48"
              data-testid="create-ad-dashboard"
            />
          </div>
        </div>
        <div className="flex flex-col w-3/5 mt-10 m-auto">
          <div
            className="flex justify-between mt-6 "
            data-testid="search-adverts"
          ></div>
          <div className="bg-white rounded-l border shadow-lg w-full mb-8">
            <div className="font-bold text-xl px-8 mt-4 mb-3">
              Ad layout configuration
            </div>
            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200 mb-8"></hr>
            <div className="font-medium text-sm px-8 mt-4">Ad script</div>
            <div className="font-medium text-sm px-8 mt-4">
              Please copy this script that has to be placed in the webpage
            </div>
            <CodeSnippet code={updateCodeSnippet()} showCopy={true} />
            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200 mb-8"></hr>
            <div className="font-medium text-sm px-8 mt-4">
              This is the example
            </div>
            <CodeSnippet
              code={codeImplement(updateCodeSnippet())}
              showCopy={false}
            />
          </div>
        </div>
      </div>
    </AppShell>
  );
};

export default AdlayoutConfiguration;
