import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet, Navigate } from 'react-router-dom';
import WhiteLoader from 'components/WhiteLoader';
import { Role } from 'utils/enum';

type AppProps = {
  roles?: Role[];
};

const RequireAuth: React.FC<AppProps> = ({ roles = [] }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    }
  }, []);

  if (user) {
    if (roles.length && !roles.includes(user?.role)) {
      return <Navigate to="/forbidden" />;
    }
    return <Outlet />;
  }
  return <WhiteLoader show={true} />;
};

export default RequireAuth;
