import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const SignOut: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 16,
  color = '#000',
  ...rest
}) => (
  <svg
    width={size}
    height={size - 3}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.7.2a.9.9 0 0 0-.9.9v10.8a.9.9 0 0 0 1.8 0V1.1a.9.9 0 0 0-.9-.9Zm9.264 8.364a.9.9 0 0 0 1.273 1.272l2.7-2.7a.9.9 0 0 0 0-1.272l-2.7-2.7a.9.9 0 1 0-1.273 1.272L12.128 5.6H5.301a.9.9 0 1 0 0 1.8h6.827l-1.164 1.164Z"
      fill="#9CA3AF"
    />
  </svg>
);
export default SignOut;
