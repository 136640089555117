import React, { useEffect, useState } from 'react';
import { FieldType, Status } from 'utils/enum';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Layout from 'components/Forms/Layout';
import { Variant } from 'components/Forms/components/Input';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { readAxiosErr } from 'utils/apiService';
import { toast } from 'react-hot-toast';
import WhiteLoader from 'components/WhiteLoader';
import {
  createAd,
  getMediaChannel,
  updateAdDetail,
  useAdDetail
} from 'queries/ad';
import Button, { Variants } from 'components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { getCurrentUnixTime, getTimefromUnix, getUnix } from 'utils/time';
import {
  CHANNEL,
  DELIVERY,
  FORMAT,
  FREQUENCY,
  POSITION,
  SUPPORTTEXT
} from 'data/options';
import { find } from 'lodash';
import { uploadImage, uploadVideo } from 'queries/uploadFile';
import useAuth from 'hooks/useAuth';
import { extractFileName } from 'utils/helper';
import { layouts } from 'queries/adLayout';

interface IForm {
  adName: string;
  mediaChannel: string | null;
  adDelivery: string;
  adFormat: string;
  pageNumber?: number;
  destinationURL: string;
  startDate?: number;
  endDate?: number;
  adFrequency: number | null;
  adLayout: string;
}
const schema = yup.object({
  adName: yup.string().required('Ad name is required').min(2).max(30),
  mediaChannel: yup.string().required('Ad channel is required'),
  adDelivery: yup.string().required('Ad delivery is required'),
  adFormat: yup.string().required('Ad format is required'),
  pageNumber: yup
    .number()
    .notRequired()
    .transform(function (value, originalValue) {
      if (!originalValue) {
        return null;
      }
      return parseInt(originalValue);
    })
    .positive('Page number must be greater than 0')
    .min(1, 'Page number must be greater than or equal to 1')
    .nullable()
    .typeError('Page number must be a number'),
  adLayout: yup.string().required(),
  destinationURL: yup
    .string()
    .url('Please enter a valid destination URL')
    .required('Destination URL is required'),
  startDate: yup.number(),
  endDate: yup.number()
});

type AppProps = {
  adImage: string;
  setAdImage: (...args: any) => void;
  disabled: boolean;
};
interface MediaChannel {
  id: string;
  label: string;
  value: string;
}

const AdvertiserAdminAdConfiguration: React.FC<AppProps> = ({
  adImage,
  setAdImage,
  disabled
}) => {
  const { id = '' } = useParams();
  const queryClient = useQueryClient();
  const { data, isLoading } = useAdDetail(id);
  const [loader, setLoader] = useState(false);
  const editable = id && !disabled;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const navigate = useNavigate();
  const { user } = useAuth();
  const [fileName, setFileName] = useState('');

  const createAdMutation = useMutation(
    (formData: Record<string, any>) => createAd(formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        if (!res) {
          toast.success('Cannot process at the moment. Please try again later');
          navigate('/dashboard');
        } else {
          if (res.status === 'DRAFT') {
            toast.success('Ad drafted successfully');
          }
          toast.success('Ad created successfully!');
          queryClient.invalidateQueries(['adverts']);
          navigate('/adverts');
        }
      }
    }
  );
  const updateAdMutation = useMutation(
    (formData: Record<string, any>) => updateAdDetail(formData, id),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        if (!res) {
          toast.success('Cannot process at the moment. Please try again later');
          navigate('/dashboard');
        } else if (res.status == 'DRAFT') {
          toast.success('Ad drafted successfully');
          queryClient.invalidateQueries(['adverts']);
          navigate('/adverts');
        } else {
          queryClient.invalidateQueries(['adverts']);
          toast.success('Ad updated successfully'); // after the update
          navigate('/adverts');
        }
      }
    }
  );
  const [Channel, setChannel] = useState<MediaChannel[]>([]);
  const [selectedAdDelivery, setSelectadDelivery] = useState<
    string | undefined
  >('');
  const [selectedMediaChannelID, setSelectedMediaChannelID] = useState<
    string | undefined
  >('');
  const [Adlayout, setAdlayout] = useState({});
  const [allAdlayoutfromAPI, setAllAdlayoutfromAPI] = useState<any[]>([]);
  const [pageNumberSet, setPageNumberSet] = useState();

  useEffect(() => {
    allMediaChannel.mutate();
  }, []);
  const allMediaChannel = useMutation(() => getMediaChannel(), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: (res: {
      [key: number]: { label: string; mediaOrgId: string };
    }) => {
      setChannel(
        Object.entries(res).map(([value, { label, mediaOrgId }]) => ({
          label,
          id: mediaOrgId,
          value: label
        }))
      );
    }
  });
  const allAdlayout = useMutation(
    () =>
      layouts({
        mediaChannelOrgId: selectedMediaChannelID,
        adDelivery: selectedAdDelivery?.toUpperCase()
      }),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        setAllAdlayoutfromAPI(res);
        const mappedAdLayouts = res.map((adLayout: any) => ({
          label: adLayout.adLayoutName,
          value: adLayout.id
        }));
        setAdlayout(mappedAdLayouts);
      }
    }
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors }
  } = useForm<IForm>({
    resolver: yupResolver(schema)
  });

  const _adFormat = watch('adFormat');
  const _adDelivery = watch('adDelivery');
  const _mediaChannel = watch('mediaChannel');

  const getSelectedMediaChannelID = (mediaChannel: string | null) => {
    setLoader(true);
    const selectedChannelObj = Channel.find((c) => c.label === mediaChannel);
    const selectedChannelId = selectedChannelObj?.id;
    setSelectedMediaChannelID(selectedChannelId);
  };
  useEffect(() => {
    getSelectedMediaChannelID(_mediaChannel);
    setSelectadDelivery(_adDelivery);
  }, [_mediaChannel, _adDelivery]);

  useEffect(() => {
    if (selectedAdDelivery && selectedMediaChannelID) {
      setLoader(true);
      allAdlayout.mutate();
    }
  }, [selectedAdDelivery, selectedMediaChannelID]);
  useEffect(() => {
    setFileName(extractFileName(data ? data?.mediaLink : ''));
    reset({
      adName: data?.adName || '',
      mediaChannel: data?.mediaChannel?.orgName || '',
      adDelivery: data?.adDelivery || null,
      adFormat: data?.adFormat || 'image',
      adFrequency: data?.adFrequency || 1,
      destinationURL: data?.destinationURL || '',
      pageNumber: data?.pageNumber || NaN,
      adLayout: data?.adLayout?.id || ''
    });
    setAdImage(data?.mediaLink || '');
    if (data?.startDate) {
      setStartDate(getTimefromUnix(data?.startDate));
    }
    if (data?.endDate) {
      setEndDate(getTimefromUnix(data?.endDate));
    }
  }, [data]);

  const uploadImageMutation = useMutation(
    (formData: any) => uploadImage(formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        setAdImage(res.secure_url);
        setLoader(false);
        toast.success('Image uploaded successfully');
        if (!res) {
          toast.error('Something went wrong');
        }
      }
    }
  );
  const uploadVideoMutation = useMutation(
    (formData: any) => uploadVideo(formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        setAdImage(res.secure_url);
        setLoader(false);
        toast.success('Video uploaded successfully');
        if (!res) {
          toast.error('Something went wrong');
        }
      }
    }
  );

  const adFile = (e: any) => {
    if (_adFormat === 'video') {
      if (
        e.target.files[0].type == 'image/jpeg' ||
        e.target.files[0].type == 'image/png' ||
        e.target.files[0].type == 'image/gif'
      ) {
        toast.error('Ad format and file format does not match!');
        return;
      }
    }
    if (_adFormat === 'image') {
      if (e.target.files[0].type == 'video/mp4') {
        toast.error('Ad format and file format does not match!');
        return;
      }
    }
    if (e.target.files[0].type == 'video/mp4') {
      if (e.target.files[0].size > 1025000) {
        toast.error('File size must be lesser than 1MB');
        return;
      }
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('upload_preset', 'unboxedsass');
      setLoader(true);
      uploadVideoMutation.mutate(formData);
    } else {
      if (e.target.files[0].size > 250001) {
        toast.error('File size must be lesser than 250KB');
        return;
      }
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('upload_preset', 'unboxedsass');
      setLoader(true);
      uploadImageMutation.mutate(formData);
    }
  };

  const fields = [
    {
      label: 'Ad name',
      className: 'mb-4 font-normal',
      type: FieldType.Input,
      variant: Variant.Text,
      disabled,
      placeholder: 'Enter ad name',
      error: errors.adName?.message,
      dataTestId: 'full-name-input',
      ...register('adName'),
      default: 'anshul'
    },
    {
      label: 'Ad channel',
      control,
      disabled,
      options: Channel,
      labelStyle: 'text-sm mb-2 font-semibold',
      className: 'mb-8',
      type: FieldType.Select,
      placeholder: 'Select',
      error: errors.mediaChannel?.message,
      dataTestId: 'Ad-channel-input',
      ...register('mediaChannel')
    },
    {
      label: 'Ad delivery',
      buttons: DELIVERY,
      control,
      className: 'mb-8',
      disabled,
      type: FieldType.Radio,
      error: errors.adDelivery?.message,
      dataTestId: 'Ad-channel-input',
      ...register('adDelivery')
    },
    {
      label: 'Ad layout',
      type: FieldType.Select,
      control,
      disabled,
      options: Adlayout,
      labelStyle: 'text-sm mb-2 font-semibold',
      className: 'mb-8',
      placeholder: 'Select',
      error: errors.adLayout?.message,
      dataTestId: 'Ad-size-input',
      ...register('adLayout')
    },
    {
      label: 'Ad format',
      buttons: FORMAT,
      className: 'mb-8',
      control,
      disabled,
      type: FieldType.Radio,
      error: errors.adFormat?.message,
      dataTestId: 'Ad-channel-input',
      ...register('adFormat')
    },
    {
      label: 'Upload ad file',
      className: disabled ? 'hidden' : 'font-medium mb-4',
      onChange: adFile,
      image: _adFormat === 'image',
      disabled,
      type: FieldType.UploadFile,
      supportText: find(SUPPORTTEXT, { value: `${_adFormat}` })?.label || ''
    },
    {
      label: 'File name',
      className: disabled ? 'mb-6 font-normal' : 'hidden',
      type: FieldType.Input,
      variant: Variant.Text,
      disabled,
      defaultValue: fileName,
      dataTestId: 'full-name-input'
    },
    {
      label: 'Page number (optional)',
      className: 'font-normal',
      type: FieldType.Input,
      disabled: true,
      variant: Variant.Number,
      placeholder: 'Enter page number',
      error: errors.pageNumber?.message,
      dataTestId: 'page-number-input',
      ...register('pageNumber')
    },
    {
      label: 'Destination URL',
      className: 'font-normal',
      type: FieldType.Input,
      disabled,
      variant: Variant.Text,
      placeholder: 'Enter destination url',
      error: errors.destinationURL?.message,
      dataTestId: 'destination-url-input',
      ...register('destinationURL')
    },
    {
      name: 'startDate',
      label: 'Target delivery start date and time',
      className: 'font-normal space-y-1 mb-6',
      type: FieldType.DateTime,
      disabled,
      onChange: setStartDate,
      value: startDate,
      dataTestId: 'start-date-input'
    },
    {
      name: 'endDate',
      label: 'Target delivery end date and time',
      className: 'font-normal space-y-1 mb-6',
      disabled,
      type: FieldType.DateTime,
      onChange: setEndDate,
      value: endDate,
      dataTestId: 'end-date-input'
    },
    {
      label: 'Ad frequency',
      type: FieldType.Select,
      control,
      disabled,
      options: FREQUENCY,
      className: 'mb-8 hidden',
      labelStyle: 'font-semibold text-sm mb-2',
      placeholder: 'Select',
      error: errors.adFrequency?.message,
      dataTestId: 'Ad-size-input',
      ...register('adFrequency')
    }
  ];

  const handleSubmitDraft = () => {
    handleSubmit(async (formData) => {
      if (adImage) {
        formData = Object.assign({ mediaLink: adImage }, formData);
      } else {
        toast.error('Media file is missing');
        return;
      }
      if (
        getCurrentUnixTime() > getUnix(startDate) ||
        getCurrentUnixTime() > getUnix(endDate) ||
        getUnix(startDate) >= getUnix(endDate)
      ) {
        toast.error('Please select valid start and end date');
        return;
      } else {
        formData = Object.assign({ startDate: getUnix(startDate) }, formData);
        formData = Object.assign({ endDate: getUnix(endDate) }, formData);
      }
      if (editable) {
        formData = Object.assign({ status: 'DRAFT' }, formData);
        updateAdMutation.mutate(formData);
      } else {
        formData = Object.assign({ status: 'DRAFT' }, formData);
        createAdMutation.mutate(formData);
      }
    })();
  };
  const handleSubmitPublish = () => {
    setLoader(true);
    handleSubmit(async (formData) => {
      if (adImage) {
        formData = Object.assign({ mediaLink: adImage }, formData);
      } else {
        setLoader(false);
        toast.error('Media file is missing');
        return;
      }
      if (
        getCurrentUnixTime() > getUnix(startDate) ||
        getCurrentUnixTime() > getUnix(endDate) ||
        getUnix(startDate) >= getUnix(endDate)
      ) {
        setLoader(false);
        toast.error('Please select valid start and end date');
        return;
      } else {
        formData = Object.assign({ startDate: getUnix(startDate) }, formData);
        formData = Object.assign({ endDate: getUnix(endDate) }, formData);
      }
      if (editable) {
        formData = Object.assign({ status: 'PENDING' }, formData);
        updateAdMutation.mutate(formData);
      } else {
        createAdMutation.mutate(formData);
      }
    })();
  };

  const _adlayout = watch('adLayout');

  useEffect(() => {
    if (allAdlayoutfromAPI && allAdlayoutfromAPI.length > 0) {
      const searchIndex = allAdlayoutfromAPI.findIndex(
        (adlayout: any) => adlayout.id == _adlayout
      );
      const pageNumber = allAdlayoutfromAPI[searchIndex]?.pageNumber;
      const allValue = getValues();

      reset({
        ...allValue,
        pageNumber: pageNumber
      });
    }
  }, [_adlayout]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        data-testid="create-ad-form"
      >
        <Layout fields={fields} className="p-8" />
        <div className="bg-white border fixed left-0 right-0 bottom-0 shadow-lg w-full px-20 py-4 flex justify-between items-center">
          <Button
            label="Cancel"
            variant={Variants.Negative}
            className="w-28 border-0"
            onClick={() => {
              navigate('/adverts');
            }}
            dataTestId="back-dashboard"
          />
          {!disabled && (
            <div className="flex">
              {(data == null || data?.status == 'DRAFT') && (
                <Button
                  variant={Variants.Negative}
                  label="Save draft"
                  onClick={handleSubmitDraft}
                  className="w-28 mr-4"
                  dataTestId="save-draft-button"
                />
              )}
              <Button
                label="Publish ad"
                className="w-28"
                onClick={handleSubmitPublish}
                dataTestId="publish-ad-button"
              />
            </div>
          )}
        </div>
        <WhiteLoader show={loader || isLoading || id !== ''} />
      </form>
    </div>
  );
};

export default AdvertiserAdminAdConfiguration;
