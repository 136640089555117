import React from 'react';

const Code = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <path
        fill="#9CA3AF"
        fillRule="evenodd"
        d="M11.085 3.246a.9.9 0 01.57 1.139l-3.6 10.8a.9.9 0 11-1.709-.569l3.6-10.8a.9.9 0 011.139-.57zM5.136 6.164a.9.9 0 010 1.273L3.073 9.5l2.063 2.064a.9.9 0 11-1.272 1.273l-2.7-2.7a.9.9 0 010-1.273l2.7-2.7a.9.9 0 011.272 0zm7.728 0a.9.9 0 011.272 0l2.7 2.7a.9.9 0 010 1.273l-2.7 2.7a.9.9 0 11-1.272-1.273L14.928 9.5l-2.064-2.063a.9.9 0 010-1.273z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Code;
