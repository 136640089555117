import React from 'react';
import PasswordShow from './icons/PasswordShow';
import PasswordHide from './icons/PasswordHide';
import Password from './icons/Password';
import Email from './icons/Email';
import ImageUpload from './icons/ImageUpload';
import More from './icons/More';
import Delete from './icons/Delete';
import Edit from './icons/Edit';
import Duplicate from './icons/Duplicate';
import Search from './icons/Search';
import Latest from './icons/Latest';
import Oldest from './icons/Oldest';
import Filter from './icons/Filter';
import Alert from './icons/Alert';
import ArrowDown from './icons/ArrowDown';
import X from './icons/X';
import Info from './icons/Info';
import Adverts from './icons/Adverts';
import Billing from './icons/Billing';
import Dashboard from './icons/Dashboard';
import Profile from './icons/Profile';
import SignOut from './icons/SignOut';
import Tick from './icons/Tick';
import Sort from './icons/Sort';
import CircularProgress from './icons/CircularProgress';
import Switch from './icons/Switch';
import MinusCircle from './icons/MinusCircle';
import Link from './icons/Link';
import Code from './icons/Code';

type AppProps = {
  name: string;
  size?: number;
  color?: string;
  className?: string;
  onClick?: () => any;
};

const iconMap: Record<string, any> = {
  'password-show': PasswordShow,
  'password-hide': PasswordHide,
  password: Password,
  email: Email,
  imageUpload: ImageUpload,
  more: More,
  edit: Edit,
  duplicate: Duplicate,
  delete: Delete,
  info: Info,
  alert: Alert,
  search: Search,
  latest: Latest,
  oldest: Oldest,
  filter: Filter,
  x: X,
  'arrow-down': ArrowDown,
  adverts: Adverts,
  billing: Billing,
  dashboard: Dashboard,
  profile: Profile,
  code: Code,
  switch: Switch,
  minusCircle: MinusCircle,
  link: Link,
  tick: Tick,
  circularProgress: CircularProgress,
  'sign-out': SignOut,
  sort: Sort
};

const Icon: React.FC<AppProps> = ({ name, size, color, ...rest }) => {
  const Component = iconMap[name] || null;
  if (!Component) {
    return null;
  }
  return <Component name={name} size={size} color={color} {...rest} />;
};

export default Icon;
