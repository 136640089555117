import React from 'react';
import { Link } from 'react-router-dom';
import clx from 'classnames';

interface IOption {
  link: string;
  label: string;
  className?: string;
  count?: any;
}
type AppProps = {
  links: IOption[];
};
const Tabs: React.FC<AppProps> = ({ links }) => {
  return (
    <div>
      <div className="flex mt-3">
        {links.map((link: any) => (
          <Link
            className={`flex text-sm leading-5 font-medium mr-10 text-gray-400 w-max p-1 ${link.className}`}
            to={link.link}
            key={link.link}
          >
            {link.label}
            {(link.count || link?.count >= 0) && (
              <div className="ml-1 rounded-lg w-6 bg-blue-300 text-center">
                {link.count}
              </div>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
