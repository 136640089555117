import React from 'react';
import useAuth from 'hooks/useAuth';
import { Popover } from '@headlessui/react';
import Icon from 'components/Icon';
import Button from 'components/Button';
import { Role } from 'utils/map';
import { useNavigate } from 'react-router-dom';
import useRole from 'hooks/useRole';
const Account = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const {
    isAdvertiserAdmin,
    isSuperAdmin,
    isMediaChannelAdmin,
    isMediaChannelUser,
    isAdvertiserUser
  } = useRole();

  if (!user) {
    return <div>&nbsp;</div>;
  }

  const logout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="flex items-center">
      <div
        className="w-8 h-8 rounded-full"
        style={{
          background: `url(${user?.profileLogoURL})`
        }}
      >
        {' '}
      </div>
      <Popover className="relative">
        <Popover.Button
          onClick={() => {
            // setIsOpen(true);
          }}
        >
          <div className="flex py-2 px-4 w-max h-10 text-gray-400">
            <div data-testid="navbar-firstName-label">{user.firstName}</div>
            <div className="mt-1 ml-2">
              <Icon name="arrow-down" data-testid="arrow-down-navbar-icon" />
            </div>
          </div>
        </Popover.Button>

        <Popover.Panel className="absolute right-0 z-10 w-max drop-shadow-2xl border bg-white rounded-md">
          {({ close }) => (
            <div className="my-3">
              <div className="text-gray-900 font-bold text-sm mx-4 w-44 truncate">
                {user.firstName} {user.lastName}
              </div>
              <div className="mb-2 font-semibold text-gray-500 text-xs mx-4 truncate w-44">
                {user.email}
              </div>
              <div className="w-max px-2.5 py-0.5 text-gray-900 text-xs  bg-gray-100 mb-3 mx-4">
                {Role[user.role]}
              </div>
              {isAdvertiserAdmin || isMediaChannelAdmin ? (
                <div>
                  {isMediaChannelAdmin ? (
                    <div
                      className="text-gray-900 w-56 cursor-pointer hover:bg-gray-100 text-sm flex my-2 border-t-2 py-2"
                      onClick={() => navigate('/advertiser/overview')}
                    >
                      <Icon name="dashboard" className="ml-4 mr-3 mt-1" />
                      <div> Advertisers </div>
                    </div>
                  ) : (
                    <div
                      className="text-gray-900 w-56 cursor-pointer hover:bg-gray-100 text-sm flex my-2 border-t-2 py-2"
                      onClick={() => navigate('/dashboard')}
                    >
                      <Icon name="dashboard" className="ml-4 mr-3 mt-1" />
                      <div> Dashboard </div>
                    </div>
                  )}
                  <div
                    className="text-gray-900 cursor-pointer hover:bg-gray-100 text-sm flex py-2"
                    onClick={() => navigate('/adverts')}
                  >
                    <Icon name="adverts" className="ml-4 mr-3 mt-1" />
                    <div> Adverts </div>
                  </div>
                  {isMediaChannelAdmin ? (
                    <div
                      className="text-gray-900 cursor-pointer hover:bg-gray-100 text-sm flex py-2"
                      onClick={() => navigate('/adlayout')}
                    >
                      <Icon name="adverts" className="ml-4 mr-3 mt-1" />
                      <div> Ad Layout </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div>
                  {!isMediaChannelUser && !isAdvertiserUser ? (
                    <div
                      className="text-gray-900 w-56 cursor-pointer hover:bg-gray-100 text-sm flex my-2 border-t-2 py-2"
                      onClick={() => navigate('/superadmin/overview')}
                    >
                      <Icon name="dashboard" className="ml-4 mr-3 mt-1" />
                      <div> Media Admin </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {!isAdvertiserUser ? (
                    <div
                      className="text-gray-900 cursor-pointer hover:bg-gray-100 text-sm flex py-2"
                      onClick={() => navigate('/advertiser/overview')}
                    >
                      <Icon name="adverts" className="ml-4 mr-3 mt-1" />
                      <div> Advertiser </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              )}
              <div
                className="text-gray-900 cursor-pointer hover:bg-gray-100 text-sm flex py-2"
                onClick={() => navigate('/profile')}
              >
                <Icon name="profile" className="ml-4 mr-3 mt-1" />
                <div> Profile </div>
              </div>
              <div
                className="text-gray-900 cursor-pointer hover:bg-gray-100 text-sm flex pb-3 pt-2 border-b-2"
                onClick={() => navigate('/billing')}
              >
                <Icon name="billing" className="ml-4 mr-3 mt-1" />
                <div> Billing</div>
              </div>
              <div
                className="text-gray-900 cursor-pointer hover:bg-gray-100 text-sm flex py-2 mt-3"
                onClick={logout}
              >
                <Icon name="sign-out" className="ml-4 mr-3 mt-1" />
                <div>Sign out</div>
              </div>
            </div>
          )}
        </Popover.Panel>
      </Popover>
    </div>
  );
};

export default Account;
