import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const SvgX: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 20,
  color = '#000',
  ...rest
}) => (
  <svg
    width={size}
    height={size + 1}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.293 4.793a1 1 0 0 1 1.414 0L10 9.086l4.293-4.293a1 1 0 1 1 1.414 1.414L11.414 10.5l4.293 4.293a1 1 0 0 1-1.414 1.414L10 11.914l-4.293 4.293a1 1 0 0 1-1.414-1.414L8.586 10.5 4.293 6.207a1 1 0 0 1 0-1.414Z"
      fill="#111928"
    />
  </svg>
);
export default SvgX;
