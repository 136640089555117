import Navbar from 'components/Navbar';
import React from 'react';

type AppProps = {
  children: React.ReactNode;
  className?: string;
};

const AppShell: React.FC<AppProps> = ({ children, className = '' }) => {
  return (
    <div className="h-full bg-background-light">
      <Navbar />
      <div className={`mt-12 bg-background-light ${className}`}>{children}</div>
    </div>
  );
};

export default AppShell;
