import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { INDUSTRY, COUNTRY, ROLE } from 'data/options';
import Button from 'components/Button';
import Layout from 'components/Forms/Layout';
import { find } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { readAxiosErr } from 'utils/apiService';
import { toast } from 'react-hot-toast';
import { saveProfile } from 'queries/auth';
import useAuth from 'hooks/useAuth';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldType } from 'utils/enum';
import { Variant } from 'components/Forms/components/Input';
import { uploadImage } from 'queries/uploadFile';
import { Role } from 'utils/map';

interface IForm {
  fullName: string;
  email: string;
  jobTitle?: string;
  orgName?: string;
  industry: string;
  country?: string | null;
  profileLogo?: object;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string | null;
  postalCode: number;
}

const schema = yup.object({
  jobTitle: yup.string().optional().notRequired(),
  fullName: yup
    .string()
    .min(2)
    .max(300)
    .matches(/^[A-Za-z\s,'-]+$/, 'Please enter valid full name')
    .required('Full name must be at least 2 characters'),
  orgName: yup
    .string()
    .min(2)
    .max(75)
    .matches(/^[a-zA-Z0-9](.*[a-zA-Z0-9])?$/, 'Please enter valid company name')
    .test('test-companyName', 'Please enter valid company name', (value) => {
      let strNumber = Number(value);
      return !Number.isInteger(strNumber);
    }),
  country: yup.string().required(),
  industry: yup.string().required('Please fill Industry field'),
  addressLine1: yup.string().max(30).required('Address Line 1 is required'),
  addressLine2: yup.string().max(30).required('Address Line 2 is required'),
  city: yup.string().max(28).required(),
  state: yup.string().required(),
  postalCode: yup
    .number()
    .test('postalCode', 'Please enter valid postal code', (value) => {
      return value ? /^\d{5}?$/.test(value.toString()) : false;
    })
    .typeError('Postal code is a required field')
    .required('Postal code is a required field')
});

const STATE = find(COUNTRY, { value: 'usa' })?.states || [];

const Onboarding = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IForm>({ resolver: yupResolver(schema) });

  const { user } = useAuth();
  const navigate = useNavigate();

  const [profilePhoto, setProfilePhoto] = useState('');
  const [accountPhoto, setAccountPhoto] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const uploadProfileLogo = useMutation(
    (formData: any) => uploadImage(formData),
    {
      onSuccess: (res: any) => {
        setProfilePhoto(res.secure_url);
        toast.success('Profile logo added successfully');
        if (!res) {
          toast.error('Something went wrong');
        }
      }
    }
  );
  const uploadAccountLogo = useMutation(
    (formData: any) => uploadImage(formData),
    {
      onSuccess: (res: any) => {
        setAccountPhoto(res.secure_url);
        toast.success('Account logo added successfully');
        if (!res) {
          toast.error('Something went wrong');
        }
      }
    }
  );
  const handleImage = (e: any) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('upload_preset', 'unboxedsass');
    if (e.target.id == 'profileLogo') {
      uploadProfileLogo.mutate(formData);
    } else {
      uploadAccountLogo.mutate(formData);
    }
  };

  const saveProfileMutation = useMutation(
    (formData: Record<string, any>) => saveProfile(formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        if (!res) {
          toast.error('Something went wrong');
        } else if (res.role == 'MEDIA_CHANNEL_ADMIN') {
          return navigate('/adverts/overview');
        } else if (res.organization.isOnboardingCompleted) {
          navigate('/dashboard');
        } else {
          navigate('/onboarding');
        }
        navigate('/dashboard');
        toast.success('Successfully Completed');
      }
    }
  );
  const onSubmit: SubmitHandler<IForm> = async (formData) => {
    if (profilePhoto) {
      formData = Object.assign({ profileLogoURL: profilePhoto }, formData);
    }
    if (accountPhoto) {
      formData = Object.assign({ orgLogoURL: accountPhoto }, formData);
    }

    if (formData.jobTitle === '') {
      formData.jobTitle = formData.jobTitle.trim();
      if (formData.jobTitle === '') {
        delete formData.jobTitle;
      }
    }
    saveProfileMutation.mutate(formData);
  };

  const personalInfo = [
    {
      label: 'Full name',
      className: 'font-normal',
      type: FieldType.Input,
      variant: Variant.Text,
      defaultValue: user
        ? (user?.firstName ?? '') + ' ' + (user?.lastName ?? ' ')
        : '',
      placeholder: 'Enter full name',
      error: errors.fullName?.message,
      dataTestId: 'full-name-input',
      ...register('fullName')
    },
    {
      label: 'Email address',
      className: 'font-normal',
      type: FieldType.Input,
      variant: Variant.Email,
      placeholder: 'Email address',
      defaultValue: user ? user?.email : '',
      disabled: true,
      error: errors.email?.message,
      dataTestId: 'email-input'
    },
    {
      label: 'Job title (optional)',
      className: 'font-normal',
      type: FieldType.Input,
      variant: Variant.Text,
      placeholder: 'Enter job title',
      error: errors.jobTitle?.message,
      dataTestId: 'job-title-input',
      ...register('jobTitle')
    }
  ];
  const profileImage = [
    {
      name: 'profileLogo',
      label: 'Profile image (optional)',
      imageRendered: profilePhoto,
      type: FieldType.UploadImage,
      onChange: handleImage,
      dataTestId: 'profile-image-input'
    }
  ];
  const organizationInfo = [
    {
      label: 'Company name',
      className: 'font-normal text-sm',
      type: FieldType.Input,
      variant: Variant.Text,
      defaultValue: user ? user.organization.orgName : '',
      placeholder: 'Enter company name',
      error: errors.orgName?.message,
      dataTestId: 'company-name-input',
      ...register('orgName')
    },
    {
      name: 'industry',
      label: 'Industry',
      placeholder: 'Select',
      control,
      labelStyle: 'font-semibold text-sm mb-2',
      options: INDUSTRY,
      type: FieldType.Select,
      error: errors.industry?.message,
      dataTestId: 'company-industry-input',
      isSearchable: true
    }
  ];
  const accountLogo = [
    {
      name: 'accountLogo',
      label: 'Account logo (optional)',
      imageRendered: accountPhoto,
      type: FieldType.UploadImage,
      onChange: handleImage,
      dataTestId: 'account-logo-input'
    }
  ];
  const organizationAddress1 = [
    {
      name: 'country',
      label: 'Country',
      placeholder: 'Select country',
      control,
      labelStyle: 'font-semibold text-sm mb-2',
      options: COUNTRY,
      type: FieldType.Select,
      error: errors.country?.message,
      dataTestId: 'company-country-input',
      isSearchable: true
    },
    {
      label: 'Company address line 1',
      className: 'font-normal mt-10',
      type: FieldType.Input,
      variant: Variant.Text,
      placeholder: 'Enter address',
      error: errors.addressLine1?.message,
      dataTestId: 'company-address1-input',
      ...register('addressLine1')
    },
    {
      label: 'Company address line 2',
      className: 'font-normal',
      type: FieldType.Input,
      variant: Variant.Text,
      placeholder: 'Enter address',
      error: errors.addressLine2?.message,
      dataTestId: 'company-address2-input',
      ...register('addressLine2')
    }
  ];
  const organizationAddress2 = [
    {
      label: 'City',
      className: 'font-normal w-1/3',
      type: FieldType.Input,
      variant: Variant.Text,
      placeholder: 'Enter city',
      error: errors.city?.message,
      dataTestId: 'company-city-input',
      ...register('city')
    },
    {
      name: 'state',
      label: 'State',
      placeholder: 'Select',
      className: 'font-normal text-sm w-1/3 mx-4',
      labelStyle: 'font-semibold text-sm mb-2',
      control,
      options: STATE,
      type: FieldType.Select,
      error: errors.state?.message,
      dataTestId: 'company-state-input',
      isSearchable: true
    },
    {
      label: 'Zip/postal code',
      className: 'font-normal  w-1/3',
      type: FieldType.Input,
      variant: Variant.Number,
      placeholder: 'Enter zip/postal code',
      error: errors.postalCode?.message,
      dataTestId: 'company-zip-input',
      ...register('postalCode')
    }
  ];

  return (
    <div className="bg-background-theme h-44 grid">
      <div className="mt-8 mb-8 m-auto w-4/6 text-white text-4xl">
        <div className="font-bold">Welcome to advertiser</div>
        <div className="mt-2">
          Let's get started by telling us about yourself
        </div>
      </div>
      <div className="flex w-1/2 mt-10 m-auto">
        <div className="bg-white rounded-l border shadow-lg w-full mb-8">
          <form data-testid="onboarding-form" onSubmit={handleSubmit(onSubmit)}>
            <div
              className="font-semibold text-lg ml-8 mt-4 mb-4"
              data-testid="personal-info-label"
            >
              Personal Information
            </div>
            <div className="mt-6 ml-8 flex">
              <div className="w-3/4" data-testid="personal-info-fields">
                <Layout fields={personalInfo} />{' '}
              </div>
              <div className="w-1/2">
                <Layout fields={profileImage} className="ml-16" />
              </div>
            </div>
            <div
              className="font-semibold text-lg ml-8 mt-4 mb-4"
              data-testid="organization-info-label"
            >
              Organization Information
            </div>
            <div className="mt-6 ml-8 flex">
              <div className="w-3/4" data-testid="organization-info-fields">
                <Layout fields={organizationInfo} />
              </div>
              <div className="w-1/2">
                <Layout fields={accountLogo} className="ml-16" />
              </div>
            </div>
            <div
              className="font-semibold text-lg ml-8 mt-4 mb-4"
              data-testid="organization-address-label"
            >
              Organization Address
            </div>
            <div
              className="mt-6 ml-8 w-11/12"
              data-testid="organization-address-form"
            >
              <Layout fields={organizationAddress1} />
              <Layout fields={organizationAddress2} className="flex" />
            </div>
            <hr className="mt-8 mb-4 h-px bg-horizontalRule border-0 dark:bg-horizontalRule"></hr>
            <div className="flex justify-end">
              <Button
                label="Save profile"
                className="bg-primary w-32 rounded-lg mb-2 mr-8"
                data-testid="save-profile-button"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
