import { useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

export const createAdLayout = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('/v1/ad-layouts', payload);
  return data;
};

export const updateAdLayout = async (
  payload: Record<string, any>,
  id: string
) => {
  const { data } = await apiService.put(`/v1/ad-layouts/${id}`, payload);
  return data;
};

export const layouts = async (q: Record<string, any>) => {
  const { data } = await apiService.get('/v1/ad-layouts', q);
  return data;
};

const getAdlayoutDetail = async (id: string) => {
  if (!id) return null;
  const { data } = await apiService.get(`/v1/ad-layouts/layoutId/${id}`);
  return data;
};

export const useAdlayoutDetail = (id: string) => {
  return useQuery(['adlayoutDetails', id], () => getAdlayoutDetail(id), {
    staleTime: 60 * 60 * 1000,
    refetchInterval: 60 * 1000
  });
};

export const useAllLayout = (q: Record<string, any>, enabled = true) => {
  return useQuery(['layout', q], () => layouts(q), {
    staleTime: 60 * 60 * 1000,
    enabled: enabled
  });
};

export const deleteAdlayout = async (id: string) => {
  const { data } = await apiService.delete(`/v1/ad-layouts/${id}`);
  return data;
};

export const getDuplicateAdLayout = async (id: string) => {
  const { data } = await apiService.post(`/v1/ad-layouts/${id}/duplicate`);
  return data;
};
