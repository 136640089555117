import React, { useEffect, useState } from 'react';
import AppShell from 'components/AppShell';
import Tabs from 'components/Tabs';
import { useLocation } from 'react-router';
import {
  useAllAdvertiserAccount,
  useAllRegisterAccount
} from 'queries/invites';
import {
  useAdvertiserAccountStats,
  useAdvertiserAccountStatsforMediaAdmin
} from 'queries/stats';
import { useQueryClient } from '@tanstack/react-query';
import { getDateBeforeDays, getUnix } from 'utils/time';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button, { Variants } from 'components/Button';
import useRole from 'hooks/useRole';

const AdvertiserAdminOverview = () => {
  const [searchQuery, setSearchQuery] = useState<String>('');
  const queryClient = useQueryClient();
  const { isSuperAdmin, isMediaChannelAdmin, isMediaChannelUser } = useRole();
  const [selectDateRange, setSelectDateRange] = useState(7);
  let { data: registeredOrg } = useAllRegisterAccount({});
  const location = useLocation();
  let pathname = location.pathname;
  const [dateRange, setDateRange] = useState<Date[]>([
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
    new Date()
  ]);
  const [startDate, endDate] = dateRange;
  const AccountHook =
    isMediaChannelAdmin || isMediaChannelUser
      ? useAdvertiserAccountStatsforMediaAdmin
      : useAdvertiserAccountStats;

  let { data } = AccountHook({
    startDate: getUnix(startDate),
    endDate: getUnix(endDate)
  });
  let { data: superAdminAdvertiserCount } = useAllAdvertiserAccount({});
  useEffect(() => {
    queryClient.invalidateQueries(['alladvertiser']);
  }, []);

  const handleDate = (selectedDuration: number) => {
    setSelectDateRange(selectedDuration);
    setDateRange([getDateBeforeDays(selectedDuration), new Date()]);
  };
  const links = [
    {
      link: '/advertiser/overview',
      label: 'Overview',
      className:
        pathname === '/advertiser/overview'
          ? 'text-blue-500 border-blue-500  border-b-2'
          : 'bg-background-theme'
    },
    {
      link: isSuperAdmin ? '/accounts/superadmin/advertiser' : '/account',
      label: 'Accounts',
      count: isSuperAdmin
        ? superAdminAdvertiserCount?.totalAdvOrgs
        : registeredOrg?.totalAdvAccounts,
      className:
        pathname === '/account'
          ? 'text-blue-500 border-blue-500  border-b-2'
          : 'bg-background-theme'
    }
  ];
  return (
    <AppShell>
      <div className="pb-24">
        <div className="mb-4">
          <div className="bg-background-theme h-theme grid">
            <div className="my-8 mx-36 text-white text-4xl">
              <div
                className="font-semibold text-4xl"
                data-testid="adverts-label"
              >
                Advertisers
              </div>
              <Tabs links={links} />
            </div>
          </div>
        </div>
        <div className="bg-[#F5F5F5]">
          <div className="flex mb-20">
            <DatePicker
              className="ml-36 mb-4"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              placeholderText="Custom range"
              onChange={(update: any) => {
                setDateRange(update);
              }}
              maxDate={new Date()}
              isClearable={true}
            />
            <Button
              label="7 days"
              variant={
                selectDateRange === 7 ? Variants.SemiPrimary : Variants.Disabled
              }
              className="mr-4"
              onClick={() => handleDate(7)}
            />
            <Button
              label="30 days"
              variant={
                selectDateRange === 30
                  ? Variants.SemiPrimary
                  : Variants.Disabled
              }
              className="mr-4"
              onClick={() => handleDate(30)}
            />
            <Button
              label="3 months"
              variant={
                selectDateRange === 90
                  ? Variants.SemiPrimary
                  : Variants.Disabled
              }
              onClick={() => handleDate(90)}
            />
          </div>
          <div className="flex ml-36">
            <div className="drop-shadow-sm rounded-lg p-6 bg-white flex flex-col mr-8 w-96">
              <div className="font-bold">Total advertiser accounts</div>
              <div className="font-bold">
                {isMediaChannelAdmin || isMediaChannelUser
                  ? data?.inActive
                    ? data?.inActive
                    : '' + data?.active
                    ? data?.active
                    : ''
                  : data?.totalOrgs?.total}
              </div>
              <div className="font-bold">-%</div>
              <div>vs</div>
            </div>
            <div className="drop-shadow-sm rounded-lg p-6 bg-white flex flex-col mr-8 w-96">
              <div className="font-bold">Active advertiser accounts</div>
              <div className="font-bold">
                {isMediaChannelAdmin || isMediaChannelUser
                  ? data?.active
                  : data?.activeAdvOrgs?.Active_orgs}
              </div>
              <div className="font-bold">-%</div>
              <div>vs</div>
            </div>
            <div className="drop-shadow-sm rounded-lg p-6 bg-white flex flex-col w-96">
              <div className="font-bold">Inactive advertiser accounts</div>
              <div className="font-bold">
                {isMediaChannelAdmin || isMediaChannelUser
                  ? data?.inActive
                  : data?.inactiveAdvOrgs?.Inactive_orgs}
              </div>
              <div className="font-bold">-%</div>
              <div>vs</div>
            </div>
          </div>
        </div>
      </div>
    </AppShell>
  );
};

export default AdvertiserAdminOverview;
