import React from 'react';

type AppProps = {
  dataTestId?: string;
  items: Array<Record<string, any>>;
};

const List: React.FC<AppProps> = ({ items }) => {
  return (
    <>
      <ul>
        {items.map((item) => (
          <li key={item.label} className={`${item.className}  list-disc`}>
            {item.label}
          </li>
        ))}
      </ul>
    </>
  );
};

export default List;
