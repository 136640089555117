import React from 'react';
import Button, { Size, Variants } from 'components/Button';
import { readAxiosErr } from 'utils/apiService';
import { toast } from 'react-hot-toast';
import * as yup from 'yup';
import Layout from 'components/Forms/Layout';
import { FieldType } from 'utils/enum';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Variant } from 'components/Forms/components/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { inviteAdmin } from 'queries/invites';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type App = {
  closeModal: () => void;
};

interface IForm {
  email: string;
  orgName: string;
}

const schema = yup.object({
  orgName: yup.string().required()
});

const InviteMember: React.FC<App> = ({ closeModal }) => {
  const queryClient = useQueryClient();

  const InviteMutation = useMutation((payload: any) => inviteAdmin(payload), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: (res: any) => {
      queryClient.invalidateQueries(['allregister']);
      toast.success('Invite sent successfully');
      closeModal();
    }
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IForm>({
    resolver: yupResolver(schema)
  });

  const field = [
    {
      label: 'Enter email address to invite',
      type: FieldType.Input,
      variant: Variant.Text,
      placeholder: 'Enter email address',
      dataTestId: 'add-email',
      className: 'text-sm w-52 mx-6',
      ...register('email')
    },
    {
      label: 'Company Name',
      type: FieldType.Input,
      variant: Variant.Text,
      placeholder: 'Enter company name',
      dataTestId: 'add-company',
      className: 'text-sm w-52 mx-6',
      ...register('orgName')
    }
  ];

  const onSubmit: SubmitHandler<IForm> = async (formData) => {
    let emails = formData.email;
    let emailList = emails.split(',');
    let payload = [];
    if (emailList.length == 2) {
      return toast.error(`You can only enter 1 email`);
    }
    let emailSchema = yup.string().email();
    for (let index = 0; index < emailList.length; index++) {
      const element = emailList[index];
      try {
        await emailSchema.validate(element.trim());
        payload.push({ email: element, orgName: formData.orgName });
      } catch (err) {
        return toast.error(`${element.trim()} is not a valid email address`);
      }
    }
    let xyz = {
      invitees: payload
    };
    if (xyz.invitees[0].email.length >= 70) {
      return toast.error('Please enter a valid email');
    }
    if (xyz.invitees[0].email === '') {
      return toast.error('Please enter email address');
    } else {
      InviteMutation.mutate(xyz);
    }
  };

  return (
    <div data-testid="add-team-box">
      <div className="mb-6 mt-4 text-xl font-semibold ml-8">
        Invite advertiser
      </div>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="create-ad-form">
        <div>
          <Layout fields={field} className="flex justify-center" />
          <div className="flex justify-end">
            <Button
              label="Cancel"
              className="w-32 border-0"
              size={Size.Small}
              variant={Variants.Negative}
              dataTestId="cancel"
              onClick={(e: any) => {
                e?.preventDefault();
                closeModal();
              }}
            />
            <Button
              label="Send invite"
              className="w-32 mr-8"
              variant={Variants.Primary}
              dataTestId="delete"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default InviteMember;
