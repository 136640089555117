import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Billing: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 16,
  color = '#666',
  ...rest
}) => (
  <svg
    width={size}
    height={size - 1}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.7A7.2 7.2 0 1 0 8.002.3a7.2 7.2 0 0 0 0 14.4ZM8.9 3a.9.9 0 0 0-1.8 0v.083a4.082 4.082 0 0 0-1.508.596c-.65.432-1.191 1.13-1.191 2.021s.542 1.589 1.191 2.021c.432.288.949.49 1.509.596v1.747c-.352-.114-.612-.285-.759-.453a.9.9 0 1 0-1.359 1.178c.506.585 1.272.969 2.118 1.128V12a.9.9 0 1 0 1.8 0v-.083a4.08 4.08 0 0 0 1.508-.596c.65-.432 1.192-1.13 1.192-2.02 0-.892-.542-1.59-1.192-2.022-.458-.298-.97-.5-1.508-.596V4.936c.352.114.612.285.758.454a.9.9 0 1 0 1.36-1.18c-.506-.584-1.271-.968-2.118-1.127V3Z"
      fill="#9CA3AF"
    />
  </svg>
);
export default Billing;
