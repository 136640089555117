import React from 'react';
import Button, { Size, Variants } from 'components/Button';
import { readAxiosErr } from 'utils/apiService';
import { toast } from 'react-hot-toast';
import * as yup from 'yup';
import Layout from 'components/Forms/Layout';
import { FieldType } from 'utils/enum';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Variant } from 'components/Forms/components/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { ROLE } from 'data/options';
import { inviteUser } from 'queries/invites';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useRole from 'hooks/useRole';

type App = {
  closeModal: () => void;
};

interface IForm {
  email: string;
  role: string | null;
}

const schema = yup.object({
  role: yup.string().required()
});

const AddTeamMember: React.FC<App> = ({ closeModal }) => {
  const queryClient = useQueryClient();
  const { isSuperAdmin, isAdvertiserAdmin } = useRole();

  const InviteMutation = useMutation((payload: any) => inviteUser(payload), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: (res: any) => {
      closeModal();
      queryClient.invalidateQueries(['allInvitees']);
      toast.success('Invite sent successfully');
    }
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<IForm>({
    resolver: yupResolver(schema)
  });

  const field = [
    {
      label: 'Enter email address to invite',
      type: FieldType.Input,
      variant: Variant.Text,
      placeholder: 'Enter email address',
      dataTestId: 'add-email',
      className: 'text-sm w-72',
      ...register('email')
    },
    {
      label: 'Role',
      type: FieldType.Select,
      control,
      options: ROLE.filter((role) => {
        if (isSuperAdmin) {
          return role.value === 'SUPER_ADMIN';
        }
        if (isAdvertiserAdmin) {
          return role.value === 'ADVERTISER_USER';
        }
        return role.value === 'MEDIA_CHANNEL_USER';
      }),
      labelStyle: 'text-sm mb-2 font-semibold',
      className: 'w-48',
      placeholder: 'Select',
      error: errors.role?.message,
      dataTestId: 'Ad-position-input',
      ...register('role')
    }
  ];

  const onSubmit: SubmitHandler<IForm> = async (formData) => {
    let emails = formData.email;
    let emailList = emails.split(',');
    let payload = [];
    let emailSchema = yup.string().email();
    for (let index = 0; index < emailList.length; index++) {
      const element = emailList[index];
      try {
        await emailSchema.validate(element.trim());
        payload.push({ email: element, role: formData.role });
      } catch (err) {
        return toast.error(`${element.trim()} is not a valid email address`);
      }
    }
    let xyz = {
      invitees: payload
    };
    InviteMutation.mutate(xyz);
  };

  return (
    <div data-testid="add-team-box">
      <div className="mb-6 mt-4 text-xl font-semibold ml-8">
        Add team members
      </div>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="create-ad-form">
        <div>
          <Layout fields={field} className="flex justify-around" />
          <p className="font-normal text-sm px-8 mb-8 text-gray-500">
            Use commas to separate email addresses.
          </p>
          <div className="flex justify-end">
            <Button
              label="Cancel"
              className="w-32 border-0"
              size={Size.Small}
              variant={Variants.Negative}
              dataTestId="cancel"
              onClick={(e: any) => {
                e?.preventDefault();
                closeModal();
              }}
            />
            <Button
              label="Send invite"
              className="w-32 mr-8"
              variant={Variants.Primary}
              dataTestId="delete"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddTeamMember;
