import apiService from '../utils/apiService';
import { useQuery } from '@tanstack/react-query';

export const createAd = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('v1/ad-request', payload);
  return data;
};

export const updateAdDetail = async (
  payload: Record<string, any>,
  id: string
) => {
  const { data } = await apiService.put(`/v1/ad-request/${id}`, payload);
  return data;
};

const getAdDetail = async (id: string) => {
  if (!id) return null;
  const { data } = await apiService.get(`/v1/ad-request/adid/${id}`);
  return data;
};

export const useAdDetail = (id: string) => {
  return useQuery(['adDetails', id], () => getAdDetail(id), {
    staleTime: 60 * 60 * 1000,
    refetchInterval: 60 * 1000
  });
};

export const getDuplicate = async (id: string) => {
  const { data } = await apiService.get(`v1/ad-request/${id}/clone`);
  return data;
};

const getAdverts = async (q: Record<string, any>) => {
  const { data } = await apiService.get('v1/ad-request', q);
  return data;
};

export const useAdverts = (q: Record<string, any>, enabled = true) => {
  return useQuery(['adverts', q], () => getAdverts(q), {
    staleTime: 60 * 60 * 1000,
    enabled: enabled
  });
};

export const deleteAd = async (id: string) => {
  const { data } = await apiService.delete(`v1/ad-request/${id}`);
  return data;
};

export const approveAd = async (payload: Record<string, any>) => {
  const { data } = await apiService.patch(
    '/v1/ad-request/change-status',
    payload
  );
  return data;
};
export const useApproveAd = (payload: Record<string, any>, enabled = true) => {
  return useQuery(['adverts', payload], () => approveAd(payload), {
    staleTime: 60 * 60 * 1000,
    enabled: enabled
  });
};

export const getMediaChannel = async () => {
  const { data } = await apiService.get(`/v1/association`);
  return data;
};

export const getMediaOrg = async () => {
  const { data } = await apiService.get(`/v1/organization/all-mediaorgs`);
  return data;
};
