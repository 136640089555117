import React from 'react';
import { useController } from 'react-hook-form';
import Chip from 'components/Chip';

interface IOptions {
  value: string;
  label: string;
}

type AppProps = {
  label: string;
  name: string;
  options: IOptions[];
  control: any;
  className?: string;
  labelStyle?: string;
  required?: boolean;
  isSelectable?: boolean;
  loading?: boolean;
  error?: string;
  dataTestId?: string;
};

const ChipSingleSelector: React.FC<AppProps> = ({
  name,
  label,
  control,
  options,
  className = '',
  labelStyle = '',
  isSelectable = true,
  required = true,
  loading = false,
  dataTestId = '',
  error = ''
}) => {
  const { field } = useController({ name, control });

  return (
    <div data-testid={dataTestId} className={className}>
      <div className={`text-text ${labelStyle}`}>{label}</div>
      <div className="flex">
        <div className="grid grid-cols-4 gap-2">
          {options.map((option) => (
            <Chip
              key={option.value}
              name={option.label}
              isSelected={field.value === option.value}
              onSelect={() => field.onChange(option.value)}
            />
          ))}
        </div>
      </div>
      {error && <div className="text-error text-xs mt-2">*{error}</div>}
    </div>
  );
};

export default ChipSingleSelector;
