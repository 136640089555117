import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { FieldType, Status, SortAd } from 'utils/enum';
import Modal from 'components/Modal';
import Button, { Variants } from 'components/Button';
import Tabs from 'components/Tabs';
import { useModal } from 'hooks/useModal';
import {
  resendInvite,
  statusChangeOrganization,
  useAllAdvertiserAccount,
  useAllRegisterMediaAccount
} from 'queries/invites';
import Layout from 'components/Forms/Layout';
import _, { debounce } from 'lodash';
import { readAxiosErr } from 'utils/apiService';
import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import AppShell from 'components/AppShell';
import { Link, useLocation } from 'react-router-dom';
import RevokeInvite from 'pages/TeamMember/components/revokeInvitee';
import { Variant } from 'components/Forms/components/Input';
import Sort from 'pages/Adverts/components/Sort';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { INDUSTRY, Sort_Ad } from 'data/options';
import { useForm } from 'react-hook-form';
import Icon from 'components/Icon';
import FilterPopover from './FilterPopover';
import NameCircle from 'components/NameCircle/NameCircle';
import InviteMediaAdmin from './inviteMediaAdmin';
import InviteAdvertiser from './inviteAdvertiser';
import EditMediaChannel from './EditMediaChannel';
import { PRIMARY_COLOR } from 'utils/constants';
import { modifyMediaChannelShow } from 'utils/helper';
const { Option } = components;

interface IForm {
  sort: string;
}

interface IFilter {
  statuses: Status[];
  mediaChannelOrgName?: string;
  industry?: string | null;
}
interface AccountProps {
  type: 'advertiser' | 'mediacompanies';
}

const SuperAdminAccount = ({ type }: AccountProps) => {
  const [isInviteTeamOpen, showInviteTeam, closeInviteTeam] = useModal();
  const [isInviteAdvertiserOpen, showInviteAdvertiser, closeInviteAdvertiser] =
    useModal();
  const [isEditMediaChannelOpen, showEditMediaChannel, closeEditMediaChannel] =
    useModal();
  const [isRevokeOpen, showRevoke, closeRevoke] = useModal();
  const [memberId, setMemberId] = useState('');
  const [emailID, setEmailId] = useState('');
  const [orgName, setOrgName] = useState('');
  const [allCheckBox, setAllCheckBox] = useState(false);
  const location = useLocation();
  const [sortField, setSortField] = useState('name');
  const [revokeassociationId, setassociationId] = useState(' ');
  let associationId = '';
  let pathname = location.pathname;
  const queryClient = useQueryClient();
  const [sort, setSort] = useState<string>(SortAd.Descending);
  const [sortIcon, setSortIcon] = useState({
    name: false,
    advertiser: false,
    industry: false,
    adCount: false,
    status: false,
    mediaChannel: false
  });
  const [searchQuery, setSearchQuery] = useState<String>('');
  const updateQuery = (e: any) => {
    setSearchQuery(e?.target?.value);
  };
  const selectStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      fontSize: '14px',
      padding: '2px'
    })
  };

  const schema = yup.object({
    sort: yup.string()
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {} = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      sort: Sort_Ad[0].value
    }
  });
  const debouncedOnChange = debounce(updateQuery, 500);
  const handleSearchQueryChange = (e: any) => {
    if (e.target.value.length === 0 || e.target.value.length > 2) {
      debouncedOnChange(e);
    }
  };

  const [filters, setFilters] = useState<IFilter>({
    statuses: [],
    industry: '',
    mediaChannelOrgName: ''
  });
  const [mediaChannel, setMediaChannelOrgName] = useState();
  const handleFilterChange = (filterData: IFilter) => {
    let { mediaChannelOrgName, industry, ...statuses } = filterData;
    let transformFilterData: IFilter;

    if (allCheckBox) {
      transformFilterData = {
        statuses: Object.values({
          Approved: Status.Approved,
          Draft: Status.Draft,
          Pending: Status.Pending,
          Published: Status.Published,
          Rejected: Status.Rejected,
          Unpublished: Status.Unpublished
        })
      };
    } else {
      transformFilterData = {
        statuses: Object.values(statuses)
          .filter((v) => v !== undefined && v !== null)
          .flat()
      };
    }

    if (industry) {
      transformFilterData.industry = industry;
    }
    if (mediaChannelOrgName) {
      transformFilterData.mediaChannelOrgName = mediaChannelOrgName;
    }
    setFilters(transformFilterData);
  };

  function handleFilterRemove(type: string, value: any) {
    switch (type) {
      case 'mediaChannel':
        handleFilterChange({
          ...filters,
          mediaChannelOrgName: ''
        });
        break;
      case 'industry':
        handleFilterChange({
          ...filters,
          industry: ''
        });
        break;

      case 'status':
        setAllCheckBox(false);
        handleFilterChange({
          ...filters,
          statuses: filters.statuses.filter((status) => status !== value)
        });
        break;
      default:
        break;
    }
  }
  const colourStyles = {
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        fontSize: '14px',
        padding: '2px',
        width: '160px',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        backgroundColor: isFocused ? PRIMARY_COLOR : null,
        color: isFocused ? 'white' : 'black'
      };
    }
  };
  const showFilters = () => {
    return (
      <div className="flex flex-wrap" data-testid="show-all-filters">
        {filters?.mediaChannelOrgName && (
          <span className="flex bg-blue-50 py-0.5 px-2.5 w-fit mr-4 mb-4 text-sm">
            <span
              className="text-blue-800 font-semibold"
              data-testid="show-filters-date"
            >
              Media
            </span>
            : {filters?.mediaChannelOrgName}
            <div
              onClick={(e) =>
                handleFilterRemove('mediaChannel', filters.mediaChannelOrgName)
              }
              className="cursor-pointer"
              data-testid="date-filter-x-icon"
            >
              <Icon name="x" />{' '}
            </div>{' '}
          </span>
        )}

        {filters?.industry && (
          <span className="flex bg-blue-50 py-0.5 px-2.5 w-fit mr-4 mb-4 text-sm">
            <span
              className="text-blue-800 font-semibold"
              data-testid="show-filters-media-channel"
            >
              Industry
            </span>
            : {INDUSTRY.filter((l) => l.value === filters.industry)[0].label}
            <div
              onClick={(e) => handleFilterRemove('industry', filters.industry)}
              className="cursor-pointer"
              data-testid="channel-filters-x-icon"
            >
              <Icon name="x" />{' '}
            </div>{' '}
          </span>
        )}

        {filters?.statuses.map((status: string, id: number) => {
          return (
            <span
              className="flex bg-blue-50 py-0.5 px-2.5 w-fit mr-4 mb-4 text-sm"
              key={id}
            >
              <span
                className="text-blue-800 font-semibold"
                data-testid={`show-filters-statuses-${id}`}
              >
                Status
              </span>
              : {_.capitalize(status)}
              <div
                onClick={(e) => handleFilterRemove('status', status)}
                className="cursor-pointer"
                data-testid={`statuses-filters-x-icon-${id}`}
              >
                <Icon name="x" />{' '}
              </div>
            </span>
          );
        })}
      </div>
    );
  };

  const isFiltersEmpty = () => {
    return _.isEqual(filters, {
      industry: '',
      date: undefined,
      statuses: []
    });
  };

  const useAccountHook =
    type === 'advertiser'
      ? useAllAdvertiserAccount
      : useAllRegisterMediaAccount;
  let { data: registeredOrg } = useAccountHook({
    orgName: searchQuery,
    order: sort,
    status: filters.statuses.join(','),
    industry: filters?.industry,
    mediaChannelOrgName: filters?.mediaChannelOrgName,
    sortField: sortField
  });

  useEffect(() => {
    if (type === 'advertiser') {
      setMediaChannelOrgName(registeredOrg?.mediaChannelArr);
    }
  }, [registeredOrg]);

  const field = [
    {
      name: 'search',
      type: FieldType.Input,
      variant: Variant.Text,
      placeholder: 'Search company',
      onChange: handleSearchQueryChange,
      className: 'text-sm',
      dataTestId: 'search-company-name'
    }
  ];

  let { data: allInvitees } = useAccountHook({
    status: Status.Pending
  });

  const handleCloseRevoke = () => {
    setMemberId('');
    closeRevoke();
  };
  let a: any = type;
  const links = [
    {
      link:
        type === 'advertiser' ? '/advertiser/overview' : '/superadmin/overview',
      label: 'Overview',

      className:
        pathname === '/advertiser/overview'
          ? 'text-blue-500 border-blue-500  border-b-2'
          : 'bg-background-theme'
    },
    {
      link:
        type === 'advertiser'
          ? '/accounts/superadmin/advertiser'
          : '/accounts/superadmin/mediacompanies',
      label: 'Accounts',
      count: registeredOrg?.res?.length ?? registeredOrg?.totalMediaAdmins,
      className:
        pathname === '/accounts/superadmin/mediacompanies' ||
        '/accounts/superadmin/advertiser'
          ? 'text-blue-500 border-blue-500  border-b-2'
          : 'bg-background-theme'
    }
  ];

  const CustomSelectValue = (props: any) => (
    <div className="flex">
      <Icon name={props.data.icon} data-testid={`${props.data.label}-icon`} />
      {props.data.label}
    </div>
  );

  const CustomSelectOption = (props: any) => (
    <Option {...props}>
      <div className="flex">
        <Icon name={props.data.icon} data-testid={`${props.data.label}-icon`} />
        {props.data.label}
      </div>
    </Option>
  );

  const showStatus = (status: string) => {
    if (status === Status.Active) {
      return (
        <div
          className="flex w-24 text-xs p-2 drop-shadow-sm rounded-lg bg-green-100 text-green-800"
          data-testid={`ad-card-status-approved`}
        >
          <span className="mt-1 w-2 h-2 mr-2 rounded-full bg-green-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }

    if (status === 'INACTIVE') {
      return (
        <div
          className="flex w-24 text-xs p-2 drop-shadow-sm rounded-lg bg-red-100 text-red-800"
          data-testid={`ad-card-status-rejected`}
        >
          <span className="mt-1 w-2 h-2 mr-2 rounded-full bg-red-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }
  };

  const resendInviteMutation = useMutation(
    (id: string) => resendInvite(id, { associationId }),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: () => {
        toast.success('Invite Resent');
      }
    }
  );
  const statusModifyMutation = useMutation(
    (formData: any) => statusChangeOrganization(formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        // eslint-disable-next-line no-lone-blocks
        {
          type === 'advertiser'
            ? queryClient.invalidateQueries(['alladvertiser'])
            : queryClient.invalidateQueries(['allmediaregister']);
        }
        let state = '';
        if (res?.status === 'ACTIVE') {
          state = 'active';
        } else {
          state = 'inactive';
        }
        toast.success(`Advertiser is ${state}`);
      }
    }
  );

  return (
    <AppShell>
      <div className="bg-background-theme h-theme grid">
        <div className="mt-8 mb-8 ml-36 text-white text-4xl">
          <div className="flex justify-between">
            <div className="font-semibold text-4xl" data-testid="profile-label">
              {type === 'mediacompanies' ? 'Media companies' : 'Advertisers'}
            </div>
            {type === 'mediacompanies' ? (
              <Button
                label="Invite"
                className="w-44 bg-primary mr-48"
                data-testid="create-ad-dashboard"
                onClick={showInviteTeam}
              />
            ) : (
              <Button
                label="Invite"
                className="w-44 bg-primary mr-48"
                data-testid="create-ad-dashboard"
                onClick={showInviteAdvertiser}
              />
            )}
          </div>
          <Tabs links={links} />
        </div>
        <div className="flex flex-col w-3/5 mt-10 m-auto">
          <div
            className="flex justify-between mt-6 "
            data-testid="search-adverts"
          >
            <Layout fields={field} className="w-80 text-sm" />
            <div>
              <FilterPopover
                type={type}
                filters={filters}
                handleFilterChange={handleFilterChange}
                mediaChannel={mediaChannel}
                data-testid="filter-popover-layout"
              />
            </div>
          </div>
          <div className="mb-16 mx-1">{showFilters()}</div>
          <div className="mx-1 py-9 border-t-2 flex justify-between">
            <div data-testid="showing-adverts-results">
              {allInvitees?.length !== 0
                ? `Showing 1 to ${allInvitees?.length ?? ''} of ${
                    allInvitees?.length ?? ''
                  } results`
                : 'Showing 0 results'}
            </div>
            <div className="relative">
              <Sort sort={sort} setSort={setSort} />
            </div>
          </div>
          <div className="bg-white rounded-l border shadow-lg w-full mb-8">
            <div className="font-bold text-xl px-8 my-4">Invites sent</div>
            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200 mb-8"></hr>
            {allInvitees?.map((d: any) => (
              <div
                className="flex m-8 border p-4"
                key={type === 'advertiser' ? d.admin?.id : d?.id}
                data-testid={d?.admin?.firstName}
              >
                <div>
                  <NameCircle
                    firstName={
                      type === 'advertiser'
                        ? d?.admin?.email.toUpperCase()
                        : d?.email.toUpperCase()
                    }
                    lastName=""
                  />{' '}
                </div>
                <div className="ml-2">
                  <div
                    className="font-semibold text-base w-64 truncate"
                    data-testid={
                      type === 'advertiser' ? d?.admin?.email : d?.email
                    }
                    title={type === 'advertiser' ? d?.admin?.email : d?.email}
                  >
                    {type === 'advertiser' ? d?.admin?.email : d?.email}
                  </div>
                  <div
                    className="text-sm font-normal text-gray-500 w-60 truncate"
                    data-testid={
                      type === 'advertiser'
                        ? d?.admin?.inviteeEmail
                        : d?.inviteeEmail
                    }
                  >
                    Invited by{' '}
                    {type === 'advertiser'
                      ? d?.admin?.inviteeEmail
                        ? d?.admin?.inviteeEmail
                        : 'Super Admin'
                      : d?.inviteeEmail
                      ? d?.inviteeEmail
                      : 'Super Admin'}
                  </div>
                </div>
                <div className="flex w-10 ml-60">
                  <Button
                    variant={Variants.Negative}
                    label="Revoke"
                    data-testid="revoke-button"
                    onClick={() => {
                      // eslint-disable-next-line no-lone-blocks
                      {
                        if (type === 'advertiser') {
                          setMemberId(d?.admin?.id);
                          setassociationId(d?.associationId);
                        } else {
                          setMemberId(d?.id);
                        }
                      }
                      showRevoke();
                    }}
                  ></Button>
                  <Button
                    label="Resend"
                    data-testid="resend-button"
                    className="ml-4"
                    onClick={() => {
                      associationId = d?.associationId;
                      resendInviteMutation.mutate(
                        type === 'advertiser' ? d?.admin?.id : d?.id
                      );
                    }}
                  ></Button>
                </div>
              </div>
            ))}
          </div>
          <div className="bg-white rounded-l border shadow-lg w-full mb-8">
            <div className="font-bold text-xl px-8 mt-4">
              Registered accounts
            </div>
            <p className="font-normal text-sm px-8 mb-4 text-gray-500">
              View all registered companies and their information
            </p>
            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200 mb-8"></hr>
            <table>
              <thead>
                <tr className="bg-gray-50 text-gray-500 font-semibold text-xs">
                  <th className="w-80 text-left py-4 pl-4">
                    {' '}
                    <div
                      className="flex justify-between"
                      onClick={(e: any) => {
                        setSortField('name');
                        setSortIcon({
                          name: !sortIcon.name,
                          advertiser: false,
                          industry: false,
                          adCount: false,
                          status: false,
                          mediaChannel: false
                        });
                        sort === SortAd.Ascending
                          ? setSort(SortAd.Descending)
                          : setSort(SortAd.Ascending);
                      }}
                    >
                      <div>Name/email</div>{' '}
                      <Icon name={sortIcon.name ? 'oldest' : 'latest'} />
                    </div>
                  </th>
                  <th className="w-80 text-left py-4 pl-4">
                    <div
                      className="flex justify-between"
                      onClick={(e: any) => {
                        setSortField('orgName');
                        setSortIcon({
                          name: false,
                          advertiser: !sortIcon.advertiser,
                          industry: false,
                          adCount: false,
                          status: false,
                          mediaChannel: false
                        });
                        sort === SortAd.Ascending
                          ? setSort(SortAd.Descending)
                          : setSort(SortAd.Ascending);
                      }}
                    >
                      <div>Company Name</div>{' '}
                      <Icon name={sortIcon.advertiser ? 'oldest' : 'latest'} />
                    </div>
                  </th>
                  <th className="w-80 text-left py-4 pl-4">
                    <div
                      className="flex justify-between"
                      onClick={(e: any) => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                          type === 'advertiser'
                            ? setSortField('mediaChannels')
                            : setSortField('industry');
                        }

                        setSortIcon({
                          name: false,
                          advertiser: false,
                          industry: !sortIcon.industry,
                          adCount: false,
                          status: false,
                          mediaChannel: false
                        });
                        sort === SortAd.Ascending
                          ? setSort(SortAd.Descending)
                          : setSort(SortAd.Ascending);
                      }}
                    >
                      {type === 'advertiser' ? 'Media channel' : 'Industry'}

                      <Icon name={sortIcon.industry ? 'oldest' : 'latest'} />
                    </div>
                  </th>
                  <th className="w-80 text-left py-4 pl-4">
                    <div
                      className="flex justify-between"
                      onClick={(e: any) => {
                        setSortField('status');
                        setSortIcon({
                          name: false,
                          advertiser: false,
                          industry: false,
                          adCount: false,
                          status: !sortIcon.status,
                          mediaChannel: false
                        });
                        sort === SortAd.Ascending
                          ? setSort(SortAd.Descending)
                          : setSort(SortAd.Ascending);
                      }}
                    >
                      <div> Status</div>{' '}
                      <Icon name={sortIcon.status ? 'oldest' : 'latest'} />
                    </div>
                  </th>
                  <th className="w-80 my-4"></th>
                </tr>
              </thead>
              <tbody>
                {(registeredOrg?.res ?? registeredOrg?.mediaAdminsArr)?.map(
                  (d: any, i: number) => (
                    <tr
                      className={`border-t-2 text-gray-900 text-base ${
                        i % 2 === 0 ? '' : 'bg-gray-50'
                      }`}
                      key={d.id}
                    >
                      <td className="w-80 py-4 pl-4 flex">
                        <NameCircle
                          firstName={
                            type === 'advertiser'
                              ? d?.admin?.firstName
                                ? d?.admin?.firstName
                                : ''
                              : d?.firsName
                          }
                          lastName={
                            type === 'advertiser'
                              ? d?.admin?.lastName
                                ? d?.admin?.lastName
                                : ''
                              : d?.lastName
                          }
                        />
                        <div className="mt-3 w-48" data-testid={d?.firstName}>
                          {type === 'advertiser'
                            ? d?.admin?.firstName
                              ? d?.admin?.firstName
                              : '' + ' ' + d?.admin?.lastName
                              ? d?.admin?.lastName
                              : ''
                            : d?.lastName
                            ? d?.firstName
                            : '' + ' ' + (d?.lastName ? d?.lastName : '')}
                          <div
                            className="truncate"
                            data-testid={d?.email}
                            title={
                              type === 'advertiser' ? d?.admin?.email : d?.email
                            }
                          >
                            {type === 'advertiser' ? d?.admin?.email : d?.email}
                          </div>
                        </div>
                      </td>
                      <td
                        className="w-80 py-4 pl-4 truncate"
                        title={
                          type === 'advertiser'
                            ? d?.admin?.organization?.orgName
                            : d?.organization?.orgName
                        }
                      >
                        {type === 'advertiser'
                          ? d?.admin?.organization?.orgName
                          : d?.organization?.orgName}
                      </td>
                      <td
                        className="w-80 py-4 pl-4 truncate overflow-anywhere whitespace-normal"
                        title={
                          type === 'advertiser'
                            ? d?.mediaChannels
                            : d?.organization?.industry
                        }
                        data-testid={d?.organization?.industry}
                      >
                        {type === 'advertiser'
                          ? modifyMediaChannelShow(d?.mediaChannels)
                          : d?.organization?.industry
                              ?.split('-')
                              ?.map(
                                (word: any) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join('-')}
                      </td>
                      <td
                        className="w-80 py-4 pl-4"
                        data-testid={
                          type === 'advertiser'
                            ? d?.admin?.organization?.status
                            : d?.organization?.status
                        }
                      >
                        {type === 'advertiser'
                          ? showStatus(d?.admin?.organization?.status)
                          : showStatus(d?.organization?.status)}
                      </td>
                      <td className="w-max py-4 pl-4">
                        <div className="w-40">
                          <Select
                            options={[
                              {
                                value: `${d?.id}`,
                                label: 'Activate a/c',
                                icon: 'switch',
                                isdisabled:
                                  d?.organization?.status === 'ACTIVE' ||
                                  d?.admin?.organization?.status === 'ACTIVE'
                              },
                              {
                                value: `${d?.id}`,
                                label: 'Deactivate a/c',
                                icon: 'minusCircle',
                                isdisabled:
                                  d?.organization?.status === 'INACTIVE' ||
                                  d?.admin?.organization?.status === 'INACTIVE'
                              },
                              {
                                ...(type === 'advertiser' && {
                                  value: `${d?.admin?.id}`,
                                  label: 'Edit media channel',
                                  icon: 'link',
                                  isdisabled:
                                    d?.organization?.status === 'INACTIVE' ||
                                    d?.admin?.organization?.status ===
                                      'INACTIVE'
                                })
                              }
                            ]}
                            styles={colourStyles}
                            components={{
                              Option: CustomSelectOption,
                              SingleValue: CustomSelectValue
                            }}
                            isOptionDisabled={(option) => {
                              if (option.isdisabled) {
                                return true;
                              } else {
                                return false;
                              }
                            }}
                            onChange={(selectedOption) => {
                              if (selectedOption?.value) {
                                if (selectedOption.label === 'Activate a/c') {
                                  let formData = new FormData();
                                  // eslint-disable-next-line no-lone-blocks
                                  {
                                    type === 'advertiser'
                                      ? (formData = Object.assign(
                                          {
                                            OrgId: d?.admin?.organization?.id
                                          },
                                          formData
                                        ))
                                      : (formData = Object.assign(
                                          {
                                            OrgId: d?.organization?.id
                                          },
                                          formData
                                        ));
                                  }
                                  formData = Object.assign(
                                    { status: 'ACTIVE' },
                                    formData
                                  );
                                  statusModifyMutation.mutate(formData);
                                } else if (
                                  selectedOption.label === 'Edit media channel'
                                ) {
                                  setEmailId(d?.admin?.email);
                                  setOrgName(d?.admin?.organization?.orgName);
                                  showEditMediaChannel();
                                } else {
                                  let formData = new FormData();
                                  // eslint-disable-next-line no-lone-blocks
                                  {
                                    type === 'advertiser'
                                      ? (formData = Object.assign(
                                          {
                                            OrgId: d?.admin?.organization?.id
                                          },
                                          formData
                                        ))
                                      : (formData = Object.assign(
                                          {
                                            OrgId: d?.organization?.id
                                          },
                                          formData
                                        ));
                                  }
                                  formData = Object.assign(
                                    { status: 'INACTIVE' },
                                    formData
                                  );
                                  statusModifyMutation.mutate(formData);
                                }
                              }
                            }}
                            isSearchable={false}
                            placeholder="Actions"
                          />
                        </div>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          isOpen={isInviteTeamOpen}
          closeModal={closeInviteTeam}
          width="40%"
        >
          <InviteMediaAdmin closeModal={closeInviteTeam} />
        </Modal>
        <Modal
          isOpen={isInviteAdvertiserOpen}
          closeModal={closeInviteAdvertiser}
          width="40%"
        >
          <InviteAdvertiser closeModal={closeInviteAdvertiser} />
        </Modal>
        <Modal
          isOpen={isEditMediaChannelOpen}
          closeModal={closeEditMediaChannel}
          width="40%"
        >
          <EditMediaChannel
            email={emailID}
            orgName={orgName}
            closeModal={closeEditMediaChannel}
          />
        </Modal>
        {isRevokeOpen && (
          <Modal
            isOpen={isRevokeOpen}
            closeModal={handleCloseRevoke}
            width="40%"
          >
            <RevokeInvite
              id={memberId}
              associationId={revokeassociationId}
              closeModal={handleCloseRevoke}
            />
          </Modal>
        )}
      </div>
    </AppShell>
  );
};

export default SuperAdminAccount;
