import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};
const Edit: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 16,
  color = '#666'
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 18 19"
  >
    <path
      fill="#6B7280"
      d="M15.673 2.828a1.8 1.8 0 00-2.545 0L6.301 9.655V12.2h2.545l6.827-6.827a1.8 1.8 0 000-2.545z"
    ></path>
    <path
      fill="#6B7280"
      fillRule="evenodd"
      d="M1.8 5.9a1.8 1.8 0 011.8-1.8h3.6a.9.9 0 010 1.8H3.6v9h9v-3.6a.9.9 0 111.8 0v3.6a1.8 1.8 0 01-1.8 1.8h-9a1.8 1.8 0 01-1.8-1.8v-9z"
      clipRule="evenodd"
    ></path>
  </svg>
);
export default Edit;
