import React from 'react';

const MinusCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <path
        stroke="#9CA3AF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11.25 9.5h-4.5m9 0a6.75 6.75 0 11-13.5 0 6.75 6.75 0 0113.5 0z"
      ></path>
    </svg>
  );
};

export default MinusCircle;
