import React from 'react';

type AppProps = {
  label1?: string;
  label2?: string;
};
const EmptyState: React.FC<AppProps> = ({ label1 = '', label2 = '' }) => {
  return (
    <div
      className="mx-36 flex flex-col content-center bg-white pt-44 pb-36 flex-wrap"
      data-testid="empty-state-banner"
    >
      <div className="ml-24" data-testid="empty-state-ads-image">
        <img
          src={require('images/empty-state-ads.png')}
          alt="ads"
          className="object-cover"
          data-testid="empty-state-ads-image"
        />
      </div>
      <div
        className="text-lg font-bold mt-4 mb-2 px-10 text-center"
        data-testid="empty-state-label-one"
      >
        {label1 ? label1 : 'There are no ads in your account yet'}
      </div>
      <div
        className="text-base font-semibold text-center"
        data-testid="empty-state-label-two"
      >
        {label2 ? label2 : 'Click "Create ad" to get started'}
      </div>
    </div>
  );
};

export default EmptyState;
