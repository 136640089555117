export const AD_CHANNEL: Record<string, any> = {
  auzmor_office: 'Auzmor Office',
  unboxed: 'Unboxed',
  UNBOXED: 'Unboxed'
};

export const Role: Record<string, any> = {
  ADVERTISER_ADMIN: 'Admin',
  SUPER_ADMIN: 'Super Admin',
  MEDIA_CHANNEL_ADMIN: 'Media Admin',
  MEDIA_CHANNEL_USER: 'Media Channel User',
  advertiser: 'Advertiser'
};

export const AdDelivery: Record<string, any> = {
  MOBILE: 'Mobile',
  DESKTOP: 'Desktop',
  DESKTOP_AND_MOBILE: 'Desktop and Mobile'
};
