import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};
const Delete: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 16,
  color = '#666'
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 18 19"
  >
    <path
      fill="#F98080"
      fillRule="evenodd"
      d="M8.1 2.3a.9.9 0 00-.805.498L6.643 4.1H3.599a.9.9 0 000 1.8v9a1.8 1.8 0 001.8 1.8h7.2a1.8 1.8 0 001.8-1.8v-9a.9.9 0 000-1.8h-3.044l-.651-1.302a.9.9 0 00-.805-.498H8.1zM6.3 7.7a.9.9 0 011.8 0v5.4a.9.9 0 01-1.8 0V7.7zm4.5-.9a.9.9 0 00-.9.9v5.4a.9.9 0 001.8 0V7.7a.9.9 0 00-.9-.9z"
      clipRule="evenodd"
    ></path>
  </svg>
);
export default Delete;
