import React, { useState } from 'react';
interface AppProps {
  code: string;
  showCopy: boolean;
}
const CodeSnippet = ({ code, showCopy }: AppProps) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(code)
      .then(() => setCopySuccess('Copied!'))
      .catch(() => setCopySuccess('Error'));
  };

  return (
    <div className="bg-gray-100 p-4 m-4 rounded-md relative border-2 overflow-auto">
      <pre>
        <code>{code}</code>
      </pre>
      {showCopy && (
        <button
          className="btn absolute top-0 right-0 mt-2 mr-2"
          onClick={copyToClipboard}
        >
          {copySuccess ? copySuccess : 'Copy'}
        </button>
      )}
    </div>
  );
};

export default CodeSnippet;
