import React from 'react';
import Account from './components/Account';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import useRole from 'hooks/useRole';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAdvertiserAdmin, isSuperAdmin, isAdvertiserUser } = useRole();
  const { user } = useAuth();

  return (
    <div className="fixed top-0 left-0 right-0 h-14 bg-background-theme px-16 z-50 border-b-2 border-gray-700">
      <div className="h-full flex justify-between items-center">
        <div className="object-fit bg-cover w-32 h-16 my-4 bg-onboarding-header"></div>
        <div className="flex text-white w-max">
          {isSuperAdmin ? (
            <div className="flex">
              <div
                className={`mr-4 py-1.5 px-3 cursor-pointer ${
                  location.pathname === '/superadmin/overview' ||
                  location.pathname === '/accounts/superadmin/mediacompanies'
                    ? 'bg-gray-700'
                    : ''
                }`}
                onClick={() => navigate('/superadmin/overview')}
              >
                Media companies
              </div>
              <div
                className={`mr-4 py-1.5 px-3 cursor-pointer ${
                  location.pathname === '/advertiser/overview' ||
                  location.pathname === '/accounts/superadmin/advertiser'
                    ? 'bg-gray-700'
                    : ''
                }`}
                onClick={() => navigate('/accounts/superadmin/advertiser')}
              >
                Advertisers
              </div>
            </div>
          ) : isAdvertiserUser || isAdvertiserAdmin ? (
            <div className="flex">
              <div
                className={`mr-4 py-1.5 px-3 cursor-pointer ${
                  location.pathname === '/dashboard' ? 'bg-gray-700' : ''
                }`}
                onClick={() => navigate('/dashboard')}
              >
                Dashboard
              </div>
              <div
                className={`py-1.5 px-3 cursor-pointer ${
                  location.pathname === '/adverts' ? 'bg-gray-700' : ''
                }`}
                onClick={() => navigate('/adverts')}
              >
                Adverts
              </div>
            </div>
          ) : (
            <div className="flex">
              <div
                className={`py-1.5 px-3 cursor-pointer ${
                  location.pathname === '/adverts/overview' ? 'bg-gray-700' : ''
                }${location.pathname === '/adverts/all' ? 'bg-gray-700' : ''}${
                  location.pathname === '/adverts/requests' ? 'bg-gray-700' : ''
                }`}
                onClick={() => navigate('/adverts/overview')}
              >
                Adverts
              </div>
              <div
                className={`mr-4 py-1.5 px-3 cursor-pointer ${
                  location.pathname === '/account' ? 'bg-gray-700' : ''
                }`}
                onClick={() => navigate('/account')}
              >
                Advertisers
              </div>
            </div>
          )}
        </div>
        {user ? <Account /> : <div className="w-40"></div>}
      </div>
    </div>
  );
};

export default Navbar;
