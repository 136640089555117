import Icon from 'components/Icon';
import { divide } from 'lodash';
import React, { useRef } from 'react';
import { SubmitHandler } from 'react-hook-form';

type AppProps = {
  label?: string;
  name: string;
  className?: string;
  onChange: SubmitHandler<any>;
  error?: string;
  loading?: boolean;
  showLabel?: boolean;
  dataTestId?: string;
  supportText?: string;
  imageRendered?: string;
};

const UploadImage = React.forwardRef<HTMLInputElement, AppProps>(
  (
    {
      name,
      label = '',
      onChange,
      supportText = '',
      className = '',
      error = null,
      dataTestId = '',
      imageRendered = '',
      showLabel = true
    },
    ref
  ) => {
    return (
      <div className={className} data-testid={dataTestId}>
        <label
          className={`font-semibold text-sm mb-2 ${
            showLabel ? '' : 'invisible'
          }`}
        >
          {label}
        </label>
        <div className="absolute">
          {!imageRendered ? (
            <div className="w-32 h-32 border-2 border-gray-200 border-dashed rounded-full cursor-pointer">
              <input
                type="file"
                id={name}
                onChange={onChange}
                className="cursor-pointer relative z-10 opacity-0 w-full h-full"
                accept=".jpg,.jpeg,.png,.gif"
                alt="file-upload"
              />
              <div className="absolute bottom-16 left-12">
                <Icon name="imageUpload" size={32} />
              </div>
              <div className="absolute font-medium text-background text-xs top-20 left-6 whitespace-nowrap">
                Upload Image
              </div>
            </div>
          ) : (
            <div className="w-32 h-32 border-2 border-gray-200 border-dashed rounded-full cursor-pointer">
              <input
                type="file"
                id={name}
                onChange={onChange}
                className="cursor-pointer relative z-10 opacity-0 w-full h-full"
                accept=".jpg,.jpeg,.png,.gif"
                alt="file-upload"
              />
              <img
                className="absolute w-32 h-32 rounded-full top-0"
                src={imageRendered}
                alt="adPreviewDesktop"
                data-testid="image-preview-profile"
              />
            </div>
          )}
        </div>
        {supportText && (
          <div className="text-sm font-normal w-2/3 mt-3 text-gray-500">
            {supportText}
          </div>
        )}
        {error && <div className="text-error text-xs">*{error}</div>}
      </div>
    );
  }
);

UploadImage.displayName = 'UploadFile';

export default UploadImage;
