import React, { useEffect, useState } from 'react';
import { Listbox, Popover } from '@headlessui/react';
import Icon from 'components/Icon';
import { FieldType } from 'utils/enum';
import Layout from 'components/Forms/Layout';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button, { Variants } from 'components/Button';
import { Status } from 'utils/enum';
import DateTime from 'components/Forms/components/DateTime';
import { getUnix } from 'utils/time';
import { Menu } from '@headlessui/react';
import { AD_CHANNEL } from 'utils/map';
import useRole from 'hooks/useRole';
import { useMutation } from '@tanstack/react-query';
import {
  createAd,
  getMediaChannel,
  updateAdDetail,
  useAdDetail
} from 'queries/ad';
import { readAxiosErr } from 'utils/apiService';
import toast from 'react-hot-toast';
import { channel } from 'diagnostics_channel';

interface IForm {
  statuses: Status[];
  date: number | undefined;
  advertiserOrgId?: string | null;
}

type AppProps = {
  filters: object;
  handleFilterChange: (filterData: IForm) => void;
};

const FilterPopover: React.FC<AppProps> = ({ filters, handleFilterChange }) => {
  const schema = yup.object({
    filter: yup.string()
  });

  const { control, handleSubmit, reset, setValue } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      statuses: []
    }
  });

  const { isAdvertiserAdmin } = useRole();

  const [date, setDate] = useState(undefined);
  const [adChannelOrgId, setAdChannelOrgId] = useState<string>('');
  const [adChannelLabel, setAdChannelLabel] = useState('');
  const [allCheckBox, setAllCheckBox] = useState(false);

  const [Channel, setChannel] = useState([]);
  useEffect(() => {
    allMediaChannel.mutate();
  }, []);

  const allMediaChannel = useMutation(() => getMediaChannel(), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: (res) => {
      setChannel(res);
    }
  });

  const advertiserfield = [
    {
      label: 'Ad channel',
      control,
      options: Channel,
      labelStyle: 'text-sm mb-2 font-semibold',
      className: 'mb-8',
      type: FieldType.Select,
      placeholder: 'Select',
      dataTestId: 'Ad-channel-input'
    }
  ];

  const statusFields = [
    {
      name: 'All',
      label: 'All',
      value: 'ALL',
      control,
      defaultValue: allCheckBox,
      setAllCheckBox,
      type: FieldType.Checkbox,
      onChange: handleFilterChange,
      dataTestId: 'filter-all'
    },
    {
      name: 'Draft',
      label: 'Draft',
      value: 'DRAFT',
      control,
      defaultValue: allCheckBox,
      setAllCheckBox,
      type: FieldType.Checkbox,
      onChange: handleFilterChange,
      dataTestId: 'filter-draft'
    },
    {
      name: 'Pending',
      label: 'Pending',
      value: 'PENDING',
      control,
      defaultValue: allCheckBox,
      setAllCheckBox,
      type: FieldType.Checkbox,
      onChange: handleFilterChange,
      dataTestId: 'filter-pending'
    },
    {
      name: 'Published',
      label: 'Published',
      value: 'PUBLISHED',
      control,
      defaultValue: allCheckBox,
      setAllCheckBox,
      type: FieldType.Checkbox,
      onChange: handleFilterChange,
      dataTestId: 'filter-published'
    },
    {
      name: 'Approved',
      label: 'Approved',
      value: 'APPROVED',
      control,
      defaultValue: allCheckBox,
      setAllCheckBox,
      type: FieldType.Checkbox,
      onChange: handleFilterChange,
      dataTestId: 'filter-approved'
    },
    {
      name: 'Unpublished',
      label: 'Unpublished',
      value: 'UNPUBLISHED',
      control,
      defaultValue: allCheckBox,
      setAllCheckBox,
      type: FieldType.Checkbox,
      onChange: handleFilterChange,
      dataTestId: 'filter-all'
    },
    {
      name: 'Rejected',
      label: 'Rejected',
      value: 'REJECTED',
      control,
      defaultValue: allCheckBox,
      setAllCheckBox,
      type: FieldType.Checkbox,
      onChange: handleFilterChange,
      dataTestId: 'filter-rejected'
    }
  ];

  const onSubmit: SubmitHandler<IForm> = async (formData) => {
    formData = Object.assign({ date: getUnix(date) }, formData);
    formData = Object.assign({ advertiserOrgId: adChannelOrgId }, formData);

    handleFilterChange(formData);
  };

  const handleReset = () => {
    reset({
      statuses: []
    });
    setDate(undefined);
    setAdChannelOrgId('');
    setAllCheckBox(false);
  };

  return (
    <Popover className="relative">
      <Popover.Button
        onClick={() => {
          // setIsOpen(true);
        }}
      >
        <div className="flex py-2 px-4 w-28 h-10 border-solid border border-gray-700">
          <div className="pt-1 mr-2">
            <Icon name="filter" data-testid="filter-icon" />
          </div>
          <div data-testid="filter-label">Filter</div>
          <div className="mt-1 ml-2">
            <Icon name="arrow-down" data-testid="arrow-down-filter-icon" />
          </div>
        </div>
      </Popover.Button>

      <Popover.Panel className="absolute right-0 z-10 w-[472px] drop-shadow-2xl px-8 border bg-white">
        {({ close }) => (
          <form
            className="mt-6"
            onSubmit={handleSubmit(onSubmit)}
            data-testid="popover-panel-filters"
          >
            <div className="flex justify-between pb-3.5">
              <div className="text-xl" data-testid="filter-popover-label">
                Filters
              </div>
              <div>
                {' '}
                <Icon
                  name="x"
                  onClick={() => close()}
                  className="cursor-pointer"
                  data-testid="filter-popover-x-icon"
                />
              </div>
            </div>
            <div className="mb-8">
              <div
                className="text-lg font-semibold mt-8 mb-4"
                data-testid="filter-ad-channel"
              >
                Filter by: Advertiser
              </div>
              <Menu>
                <Menu.Button>
                  <div
                    className="w-[406px] py-3 px-4 border-2 flex justify-between relative rounded-lg"
                    data-testid="filter-ad-channel-button"
                  >
                    {adChannelOrgId ? `${adChannelLabel}` : 'Select'}
                    <Icon
                      name="arrow-down"
                      className="mt-1"
                      data-testid="filter-ad-channel-icon-arrow-down"
                    />
                  </div>
                </Menu.Button>
                <Menu.Items className="absolute w-[406px]">
                  {Channel.map((e: any) => {
                    return (
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={`bg-white ${
                              active && ' bg-gray-200'
                            } py-3 pl-4 cursor-pointer rounded-lg border-x-2`}
                            onClick={() => {
                              setAdChannelOrgId(e.advOrgId);
                              setAdChannelLabel(e.label);
                            }}
                            data-testid="advertiser-channel"
                          >
                            {e.label}
                          </div>
                        )}
                      </Menu.Item>
                    );
                  })}
                </Menu.Items>
              </Menu>
            </div>
            <div className="flex justify-between my-4">
              <div>
                <Button
                  label="Cancel"
                  variant={Variants.PrimaryNoOutlined}
                  className="w-14 mr-8"
                  onClick={(e: any) => {
                    e?.preventDefault();
                    close();
                  }}
                  dataTestId="button-filter-cancel"
                />
              </div>
              <div className="">
                <Button
                  label="Reset all filters"
                  variant={Variants.PrimaryOutlined}
                  className="w-36 mr-4"
                  onClick={(e: any) => {
                    e?.preventDefault();
                    handleReset();
                  }}
                  dataTestId="button-filter-reset"
                />
                <Button
                  label="Apply"
                  className="w-20"
                  dataTestId="button-filter-apply"
                />
              </div>
            </div>
          </form>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default FilterPopover;
