import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};
const Adverts: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 14,
  color,
  ...rest
}) => (
  <svg
    width={size}
    height={size - 1}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M.7 1.1a.9.9 0 0 1 .9-.9h10.8a.9.9 0 0 1 .9.9v1.8a.9.9 0 0 1-.9.9H1.6a.9.9 0 0 1-.9-.9V1.1Zm0 5.4a.9.9 0 0 1 .9-.9H7a.9.9 0 0 1 .9.9v5.4a.9.9 0 0 1-.9.9H1.6a.9.9 0 0 1-.9-.9V6.5Zm9.9-.9a.9.9 0 0 0-.9.9v5.4a.9.9 0 0 0 .9.9h1.8a.9.9 0 0 0 .9-.9V6.5a.9.9 0 0 0-.9-.9h-1.8Z"
      fill="#9CA3AF"
    />
  </svg>
);
export default Adverts;
