import React from 'react';
import useRole from 'hooks/useRole';
import MediaAdminAccount from './components/MediaAdmin.accounts';
import SuperAdminAccount from './components/SuperAdmin.accounts';

const Adverts = () => {
  const { isSuperAdmin, isMediaChannelAdmin, isMediaChannelUser } = useRole();

  if (isMediaChannelAdmin || isMediaChannelUser) {
    return <MediaAdminAccount />;
  } else if (isSuperAdmin) {
    return <SuperAdminAccount type="advertiser" />;
  } else {
    return <div>Not accessible</div>;
  }
};

export default Adverts;
