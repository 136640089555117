import React from 'react';
import { useController } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form';

type AppProps = {
  label?: string;
  name: string;
  value?: string;
  className?: string;
  onChange: SubmitHandler<any>;
  error?: string;
  loading?: boolean;
  dataTestId?: string;
  control?: any;
  defaultValue?: boolean;
  setAllCheckBox?: (checked: boolean) => void;
};

const Checkbox = React.forwardRef<HTMLInputElement, AppProps>(
  (
    {
      name,
      value,
      label = '',
      onChange,
      className = '',
      error = null,
      loading = false,
      defaultValue = false,
      control,
      setAllCheckBox,
      dataTestId = ''
    },
    ref
  ) => {
    const { field } = useController({ name, control });

    return (
      <div className={className} data-testid={dataTestId}>
        <label className="flex items-end space-x-2">
          <input
            type="checkbox"
            className="h-4 w-4 rounded-sm cursor-pointer"
            name={name}
            ref={ref}
            disabled={loading}
            checked={defaultValue ? defaultValue : field?.value === value}
            onChange={(e) => {
              if (e.target.name === 'All') {
                if (setAllCheckBox) {
                  if (e.target.checked) {
                    setAllCheckBox(true);
                  } else {
                    field?.onChange(null);
                    setAllCheckBox(false);
                  }
                }
              } else {
                field?.onChange(e.target.checked ? value : null);
              }
            }}
          />
          {label && <div className="text-text-medium text-sm">{label}</div>}
        </label>
        {error && <div className="text-error text-xs">*{error}</div>}
      </div>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
