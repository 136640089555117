import React from 'react';

type Approps = {
  firstName: string;
  lastName: string;
};
const NameCircle: React.FC<Approps> = ({ firstName, lastName }) => {
  const firstInitial = firstName ? firstName[0].toUpperCase() : '';
  const lastInitial = lastName ? lastName[0].toUpperCase() : '';
  return (
    <div>
      <div className="flex justify-center items-center">
        <div className="text-gray-900 mr-2 text-base border-2 bg-gray-50 border-white rounded-full w-12 h-12 flex justify-center items-center">
          {firstInitial} {lastInitial}
        </div>
      </div>
    </div>
  );
};

export default NameCircle;
