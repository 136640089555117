import axios from 'axios';

export const uploadImage = async (payload: Record<string, any>) => {
  const uninterceptedAxiosInstance = axios.create();
  const { data } = await uninterceptedAxiosInstance.post(
    'https://api.cloudinary.com/v1_1/dg2mwhsdc/image/upload',
    payload
  );
  return data;
};

export const uploadVideo = async (payload: Record<string, any>) => {
  const uninterceptedAxiosInstance = axios.create();
  const { data } = await uninterceptedAxiosInstance.post(
    'https://api.cloudinary.com/v1_1/dg2mwhsdc/video/upload',
    payload
  );
  return data;
};
