import React from 'react';
import useRole from 'hooks/useRole';
import AdvertiserAdminAdverts from './components/AdvertiserAdmin.adverts';
import MediaChannelAdminAdverts from './components/MediaChannelAdmin.adverts';

const Adverts = () => {
  const { isAdvertiserAdmin, isAdvertiserUser } = useRole();

  if (isAdvertiserAdmin || isAdvertiserUser) {
    return <AdvertiserAdminAdverts />;
  } else {
    return <MediaChannelAdminAdverts />;
  }
};

export default Adverts;
