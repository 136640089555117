import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { FieldType, Status, SortAd } from 'utils/enum';
import Modal from 'components/Modal';
import Button, { Variants } from 'components/Button';
import Tabs from 'components/Tabs';
import { useModal } from 'hooks/useModal';
import {
  resendInvite,
  statusChangeUser,
  useAllRegisterAccount
} from 'queries/invites';
import Layout from 'components/Forms/Layout';
import _, { debounce } from 'lodash';
import { readAxiosErr } from 'utils/apiService';
import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import NameCircle from 'components/NameCircle/NameCircle';
import AppShell from 'components/AppShell';
import { Link, useLocation } from 'react-router-dom';
import RevokeInvite from 'pages/TeamMember/components/revokeInvitee';
import InviteMember from './inviteMember';
import { Variant } from 'components/Forms/components/Input';
import Sort from 'pages/Adverts/components/Sort';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Sort_Ad } from 'data/options';
import { useForm } from 'react-hook-form';
import Icon from 'components/Icon';
import FilterPopover from './FilterPopover';
import { INDUSTRY } from 'data/options';
import { PRIMARY_COLOR } from 'utils/constants';
import useRole from 'hooks/useRole';
const { Option } = components;

interface IForm {
  sort: string;
}
interface IFilter {
  statuses: Status[];
  industry?: string | null;
}
const MediaAdminAccount = () => {
  const [isInviteTeamOpen, showInviteTeam, closeInviteTeam] = useModal();
  const [isRevokeOpen, showRevoke, closeRevoke] = useModal();
  const [memberId, setMemberId] = useState('');
  const [allCheckBox, setAllCheckBox] = useState(false);
  const [sortField, setSortField] = useState('name');
  const location = useLocation();
  const { isMediaChannelUser } = useRole();
  const [sortIcon, setSortIcon] = useState({
    name: false,
    advertiser: false,
    industry: false,
    adCount: false,
    status: false
  });

  let pathname = location.pathname;
  const queryClient = useQueryClient();
  const [sort, setSort] = useState<string>(SortAd.Descending);
  const [searchQuery, setSearchQuery] = useState<String>('');
  const updateQuery = (e: any) => {
    setSearchQuery(e?.target?.value);
  };

  const schema = yup.object({
    sort: yup.string()
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { control, handleSubmit, setValue } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      sort: Sort_Ad[0].value
    }
  });
  const debouncedOnChange = debounce(updateQuery, 500);
  const handleSearchQueryChange = (e: any) => {
    if (e.target.value.length === 0 || e.target.value.length > 2) {
      debouncedOnChange(e);
    }
  };
  const [filters, setFilters] = useState<IFilter>({
    statuses: [],
    industry: ''
  });

  const isFiltersEmpty = () => {
    return _.isEqual(filters, {
      industry: '',
      statuses: []
    });
  };

  const CustomSelectValue = (props: any) => (
    <div className="flex">
      <Icon name={props.data.icon} data-testid={`${props.data.label}-icon`} />
      {props.data.label}
    </div>
  );

  const CustomSelectOption = (props: any) => (
    <Option {...props}>
      <div className="flex">
        <Icon name={props.data.icon} data-testid={`${props.data.label}-icon`} />
        {props.data.label}
      </div>
    </Option>
  );

  const colourStyles = {
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        fontSize: '14px',
        padding: '2px',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        backgroundColor: isFocused ? PRIMARY_COLOR : null,
        color: isFocused ? 'white' : 'black'
      };
    }
  };

  let { data: registeredOrg } = useAllRegisterAccount({
    orgName: searchQuery,
    order: sort,
    status: filters.statuses.join(),
    industry: filters?.industry,
    sortField: sortField
  });

  const handleFilterChange = (filterData: IFilter) => {
    let { industry, ...statuses } = filterData;

    let transformFilterData: IFilter;

    if (allCheckBox) {
      transformFilterData = {
        statuses: Object.values({
          Approved: Status.Approved,
          Draft: Status.Draft,
          Pending: Status.Pending,
          Published: Status.Published,
          Rejected: Status.Rejected,
          Unpublished: Status.Unpublished
        })
      };
    } else {
      transformFilterData = {
        statuses: Object.values(statuses)
          .filter((v) => v !== undefined && v !== null)
          .flat()
      };
    }
    if (industry) {
      transformFilterData.industry = industry;
    }
    setFilters(transformFilterData);
  };

  function handleFilterRemove(type: string, value: any) {
    switch (type) {
      case 'industry':
        handleFilterChange({
          ...filters,
          industry: ''
        });
        break;
      case 'status':
        setAllCheckBox(false);
        handleFilterChange({
          ...filters,
          statuses: filters.statuses.filter((status) => status !== value)
        });
        break;
      default:
        break;
    }
  }

  const showFilters = () => {
    return (
      <div className="flex flex-wrap" data-testid="show-all-filters">
        {filters?.industry && (
          <span className="flex bg-blue-50 py-0.5 px-2.5 w-fit mr-4 mb-4 text-sm">
            <span
              className="text-blue-800 font-semibold"
              data-testid="show-filters-media-channel"
            >
              Industry
            </span>
            : {INDUSTRY.filter((l) => l.value === filters.industry)[0].label}
            <div
              onClick={(e) => handleFilterRemove('industry', filters.industry)}
              className="cursor-pointer"
              data-testid="channel-filters-x-icon"
            >
              <Icon name="x" />{' '}
            </div>{' '}
          </span>
        )}
        {filters?.statuses.map((status: string, id: number) => {
          return (
            <span
              className="flex bg-blue-50 py-0.5 px-2.5 w-fit mr-4 mb-4 text-sm"
              key={id}
            >
              <span
                className="text-blue-800 font-semibold"
                data-testid={`show-filters-statuses-${id}`}
              >
                Status
              </span>
              : {_.capitalize(status)}
              <div
                onClick={(e) => handleFilterRemove('status', status)}
                className="cursor-pointer"
                data-testid={`statuses-filters-x-icon-${id}`}
              >
                <Icon name="x" />{' '}
              </div>
            </span>
          );
        })}
      </div>
    );
  };

  const field = [
    {
      name: 'search',
      type: FieldType.Input,
      variant: Variant.Text,
      placeholder: 'Search company',
      onChange: handleSearchQueryChange,
      className: 'text-sm',
      dataTestId: 'search-company-name'
    }
  ];
  let { data: allInvitees } = useAllRegisterAccount({ status: Status.Pending });
  const handleCloseRevoke = () => {
    setMemberId('');
    closeRevoke();
  };

  const links = [
    {
      link: '/advertiser/overview',
      label: 'Overview',

      className:
        pathname === '/advertiser/overview'
          ? 'text-blue-500 border-blue-500  border-b-2'
          : 'bg-background-theme'
    },
    {
      link: '/account',
      label: 'Accounts',
      count: registeredOrg?.totalAdvAccounts,
      className:
        pathname === '/account'
          ? 'text-blue-500 border-blue-500  border-b-2'
          : 'bg-background-theme'
    }
  ];

  const showStatus = (status: string) => {
    if (status === Status.Active) {
      return (
        <div
          className="flex w-24 text-xs p-2 drop-shadow-sm rounded-lg bg-green-100 text-green-800"
          data-testid={`ad-card-status-approved`}
        >
          <span className="mt-1 w-2 h-2 mr-2 rounded-full bg-green-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }

    if (status === 'INACTIVE') {
      return (
        <div
          className="flex w-24 text-xs p-2 drop-shadow-sm rounded-lg bg-red-100 text-red-800"
          data-testid={`ad-card-status-rejected`}
        >
          <span className="mt-1 w-2 h-2 mr-2 rounded-full bg-red-600"></span>{' '}
          {_.capitalize(status)}
        </div>
      );
    }
  };

  const resendInviteMutation = useMutation((id: string) => resendInvite(id), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: () => {
      toast.success('Invite Resent');
    }
  });
  const statusModifyMutation = useMutation(
    (formData: any) => statusChangeUser(formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        queryClient.invalidateQueries(['allregister']);
        let state = '';
        if (res?.status === 'ACTIVE') {
          state = 'active';
        } else {
          state = 'inactive';
        }
        toast.success(`Advertiser is ${state}`);
      }
    }
  );

  return (
    <AppShell>
      <div className="bg-background-theme h-theme grid">
        <div className="mt-8 mb-8 ml-36 text-white text-4xl">
          <div className="flex justify-between">
            <div className="font-semibold text-4xl" data-testid="profile-label">
              Advertisers
            </div>
            {!isMediaChannelUser && (
              <Button
                label="Invite"
                className="w-44 bg-primary mr-48"
                data-testid="create-ad-dashboard"
                onClick={showInviteTeam}
              />
            )}
          </div>
          <Tabs links={links} />
        </div>
        <div className="flex flex-col w-3/5 mt-10 m-auto">
          <div
            className="flex justify-between mt-6 "
            data-testid="search-adverts"
          >
            <Layout fields={field} className="w-80 text-sm" />
            <div>
              <FilterPopover
                filters={filters}
                handleFilterChange={handleFilterChange}
                data-testid="filter-popover-layout"
              />
            </div>
          </div>
          <div className="mb-16 mx-1">{showFilters()}</div>
          <div className="mx-1 py-9 border-t-2 flex justify-between">
            <div data-testid="showing-adverts-results">
              {allInvitees?.res?.length !== 0
                ? `Showing 1 to ${
                    isMediaChannelUser
                      ? registeredOrg?.res?.length
                      : allInvitees?.res?.length ?? ''
                  } of ${
                    isMediaChannelUser
                      ? registeredOrg?.res?.length
                      : allInvitees?.res?.length ?? ''
                  } results`
                : 'Showing 0 results'}
            </div>
            <div className="relative">
              <Sort sort={sort} setSort={setSort} />
            </div>
          </div>
          {!isMediaChannelUser && (
            <div className="bg-white rounded-l border shadow-lg w-full mb-8">
              <div className="font-bold text-xl px-8 my-4">Invites sent</div>
              <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200 mb-8"></hr>
              {allInvitees?.res?.map((d: any) => (
                <div
                  className="flex m-8 border p-4"
                  key={d.id}
                  data-testid={d?.admin?.firstName}
                >
                  <div>
                    <NameCircle
                      firstName={d?.admin?.email.toUpperCase()}
                      lastName=""
                    />{' '}
                  </div>
                  <div className="ml-2">
                    <div
                      className="font-semibold text-base w-64 truncate"
                      data-testid={d?.admin?.email}
                      title={d?.admin?.email}
                    >
                      {d?.admin?.email}
                    </div>
                    <div
                      className="text-sm font-normal text-gray-500  w-64 truncate"
                      data-testid={d?.admin?.inviteeEmail}
                      title={d?.admin?.inviteeEmail}
                    >
                      Invited by {d?.admin?.inviteeEmail}
                    </div>
                  </div>
                  <div className="flex w-10 ml-60">
                    <Button
                      variant={Variants.Negative}
                      label="Revoke"
                      data-testid="revoke-button"
                      onClick={() => {
                        setMemberId(d?.admin?.id);
                        showRevoke();
                      }}
                    ></Button>
                    <Button
                      label="Resend"
                      data-testid="resend-button"
                      className="ml-4"
                      onClick={() => {
                        resendInviteMutation.mutate(d.admin.id);
                      }}
                    ></Button>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="bg-white rounded-l border shadow-lg w-full mb-8">
            <div className="font-bold text-xl px-8 mt-4">
              Registered accounts
            </div>
            <p className="font-normal text-sm px-8 mb-4 text-gray-500">
              View all registered companies and their information
            </p>
            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200 mb-8"></hr>
            <table>
              <thead>
                <tr className="bg-gray-50 text-gray-500 font-semibold text-xs">
                  <th className="w-80 text-left py-4 pl-4">
                    <div
                      className="flex justify-between"
                      onClick={(e: any) => {
                        setSortField('name');
                        setSortIcon({
                          name: !sortIcon.name,
                          advertiser: false,
                          industry: false,
                          adCount: false,
                          status: false
                        });
                        sort === SortAd.Ascending
                          ? setSort(SortAd.Descending)
                          : setSort(SortAd.Ascending);
                      }}
                    >
                      <div>Name/email</div>{' '}
                      <Icon name={sortIcon.name ? 'oldest' : 'latest'} />
                    </div>
                  </th>
                  <th className="w-80 text-left py-4 pl-4">
                    <div
                      className="flex justify-between"
                      onClick={(e: any) => {
                        setSortField('orgName');
                        setSortIcon({
                          name: false,
                          advertiser: !sortIcon.advertiser,
                          industry: false,
                          adCount: false,
                          status: false
                        });
                        sort === SortAd.Ascending
                          ? setSort(SortAd.Descending)
                          : setSort(SortAd.Ascending);
                      }}
                    >
                      <div>Advertiser</div>{' '}
                      <Icon name={sortIcon.advertiser ? 'oldest' : 'latest'} />
                    </div>
                  </th>
                  <th className="w-80 text-left py-4 pl-4">
                    <div
                      className="flex justify-between"
                      onClick={(e: any) => {
                        setSortField('industry');
                        setSortIcon({
                          name: false,
                          advertiser: false,
                          industry: !sortIcon.industry,
                          adCount: false,
                          status: false
                        });
                        sort === SortAd.Ascending
                          ? setSort(SortAd.Descending)
                          : setSort(SortAd.Ascending);
                      }}
                    >
                      <div> Industry </div>{' '}
                      <Icon name={sortIcon.industry ? 'oldest' : 'latest'} />
                    </div>
                  </th>
                  <th className="w-80 text-left py-4 pl-4">
                    <div
                      className="flex justify-between"
                      onClick={(e: any) => {
                        setSortField('adCount');
                        setSortIcon({
                          name: false,
                          advertiser: false,
                          industry: false,
                          adCount: !sortIcon.adCount,
                          status: false
                        });
                        sort === SortAd.Ascending
                          ? setSort(SortAd.Descending)
                          : setSort(SortAd.Ascending);
                      }}
                    >
                      <div> # of ads </div>{' '}
                      <Icon name={sortIcon.adCount ? 'oldest' : 'latest'} />
                    </div>
                  </th>
                  <th className="w-80 text-left py-4 pl-4">
                    <div
                      className="flex justify-between"
                      onClick={(e: any) => {
                        setSortField('status');
                        setSortIcon({
                          name: false,
                          advertiser: false,
                          industry: false,
                          adCount: false,
                          status: !sortIcon.status
                        });
                        sort === SortAd.Ascending
                          ? setSort(SortAd.Descending)
                          : setSort(SortAd.Ascending);
                      }}
                    >
                      <div> Status</div>{' '}
                      <Icon name={sortIcon.status ? 'oldest' : 'latest'} />
                    </div>
                  </th>
                  {!isMediaChannelUser && <th className="w-80 my-4"></th>}
                </tr>
              </thead>
              <tbody>
                {registeredOrg?.res?.map((d: any, i: number) => (
                  <tr
                    className={`border-t-2 text-gray-900 text-base ${
                      i % 2 === 0 ? '' : 'bg-gray-50'
                    }`}
                    key={d.id}
                  >
                    <td className="w-80 py-4 pl-4 flex">
                      <NameCircle
                        firstName={d.admin?.firstName}
                        lastName={d.admin?.lastName}
                      />{' '}
                      <div
                        className="mt-3 w-48 truncate"
                        data-testid={d?.admin?.firstName}
                        title={d?.admin?.firstName}
                      >
                        {d?.admin?.lastName
                          ? d?.admin?.firstName
                          : '' +
                            ' ' +
                            (d?.admin?.lastName ? d?.admin?.lastName : '')}
                        <div
                          className="w-64 truncate"
                          data-testid={d?.admin?.email}
                          title={d?.admin?.email}
                        >
                          {d?.admin?.email}
                        </div>
                      </div>
                    </td>
                    <td className="w-80 py-4 pl-4">
                      <Link
                        to={`/adverts/all/${d.adminOrg.id}`}
                        className="text-primary"
                        data-testid={d?.adminOrg?.orgName}
                      >
                        {' '}
                        {d?.adminOrg?.orgName}
                      </Link>
                    </td>
                    <td
                      className="w-80 py-4 pl-4"
                      data-testid={d?.adminOrg?.industry}
                    >
                      {d?.adminOrg?.industry
                        ?.split('-')
                        ?.map(
                          (word: any) =>
                            word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join('-')}
                    </td>
                    <td className="w-80 py-4 pl-4" data-testid={d?.adCount}>
                      {d?.adCount}
                    </td>
                    <td className="w-80 py-4 pl-4" data-testid={d?.status}>
                      {showStatus(d?.status)}
                    </td>
                    {!isMediaChannelUser && (
                      <td className="w-80 py-4 pl-4">
                        <Select
                          options={[
                            {
                              value: `${d?.admin?.id}`,
                              label: 'Activate a/c',
                              icon: 'switch',
                              isdisabled: d?.status === 'ACTIVE'
                            },
                            {
                              value: `${d?.admin?.id}`,
                              label: 'Deactivate a/c',
                              icon: 'minusCircle',
                              isdisabled: d?.status === 'INACTIVE'
                            }
                          ]}
                          styles={colourStyles}
                          isOptionDisabled={(option) => option.isdisabled}
                          components={{
                            Option: CustomSelectOption,
                            SingleValue: CustomSelectValue
                          }}
                          onChange={(selectedOption) => {
                            if (selectedOption?.value) {
                              if (selectedOption.label === 'Activate a/c') {
                                let formData = new FormData();
                                formData = Object.assign(
                                  { advertiserOrg: d?.adminOrg?.id },
                                  formData
                                );
                                formData = Object.assign(
                                  { status: 'ACTIVE' },
                                  formData
                                );
                                statusModifyMutation.mutate(formData);
                              } else {
                                let formData = new FormData();
                                formData = Object.assign(
                                  { advertiserOrg: d?.adminOrg?.id },
                                  formData
                                );
                                formData = Object.assign(
                                  { status: 'INACTIVE' },
                                  formData
                                );
                                statusModifyMutation.mutate(formData);
                              }
                            }
                          }}
                          isSearchable={false}
                          placeholder="Actions"
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          isOpen={isInviteTeamOpen}
          closeModal={closeInviteTeam}
          width="40%"
        >
          <InviteMember closeModal={closeInviteTeam} />
        </Modal>
        {isRevokeOpen && (
          <Modal
            isOpen={isRevokeOpen}
            closeModal={handleCloseRevoke}
            width="40%"
          >
            <RevokeInvite id={memberId} closeModal={handleCloseRevoke} />
          </Modal>
        )}
      </div>
    </AppShell>
  );
};
export default MediaAdminAccount;
