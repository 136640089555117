import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Variant } from 'components/Forms/components/Input';
import Layout from 'components/Forms/Layout';
import * as yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import Button from 'components/Button';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import List from 'components/List';
import { readAxiosErr } from 'utils/apiService';
import { updateProfile } from 'queries/auth';
import Icon from 'components/Icon';

interface IForm {
  password?: string;
  confirmPassword?: string;
}
const ChangePassword = () => {
  const schema = yup.object({
    password: yup
      .string()
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Please enter valid password'
      )
      .required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  });

  const {
    formState: { errors },
    handleSubmit,
    register
  } = useForm<IForm>({ resolver: yupResolver(schema) });

  const updateProfileMutation = useMutation(
    (formData: Record<string, any>) => updateProfile(formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        toast.success('Updated Successful');
      }
    }
  );

  const password = [
    {
      label: 'Password',
      className: 'font-normal w-full mr-4',
      type: FieldType.Input,
      variant: Variant.Password,
      placeholder: 'Password',
      error: errors.password?.message,
      dataTestId: 'email-input',
      ...register('password')
    },
    {
      label: 'Confirm new password',
      className: 'font-normal w-full',
      type: FieldType.Input,
      variant: Variant.Password,
      placeholder: 'New password',
      error: errors.confirmPassword?.message,
      disabledPaste: true,
      dataTestId: 'email-input',
      ...register('confirmPassword')
    }
  ];

  const list = [
    {
      label: 'Be at least 8 characters',
      className: 'ml-4 text-sm font-medium text-primary'
    },
    {
      label: 'Include at least two of the following:',
      className: 'ml-4 text-sm font-medium text-primary'
    },
    {
      label: 'An uppercase character',
      className: 'ml-8 text-sm font-normal text-primary'
    },
    {
      label: 'A lowercase character',
      className: 'ml-8 text-sm font-normal text-primary'
    },
    {
      label: 'A number',
      className: 'ml-8 text-sm font-normal text-primary'
    },
    {
      label: 'A special character',
      className: 'ml-8 text-sm font-normal text-primary'
    }
  ];

  const onSubmit: SubmitHandler<IForm> = async (formData) => {
    if (formData?.password !== formData?.confirmPassword) {
      return toast.error('Password do not match');
    }
    updateProfileMutation.mutate(formData);
  };
  return (
    <div>
      <div className="bg-white rounded-l border shadow-lg w-full mb-8">
        <div className="font-bold text-xl px-8 mt-4 mb-12">Change password</div>
        <form data-testid="onboarding-form" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="bg-blue-100 ml-8 rounded-lg p-4">
              <div className="flex font-semibold text-base text-primary">
                <Icon name="info" />
                <div className="ml-2 text-center">Password hint</div>
              </div>
              <List items={list} />
            </div>
          </div>
          <Layout fields={password} className="p-8 flex" />
          <div className="flex justify-end">
            <Button
              label="Update password"
              className="bg-primary w-40 rounded-lg mb-2 mr-8"
              data-testid="save-profile-button"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
