import { Menu } from '@headlessui/react';
import Icon from 'components/Icon';
import _ from 'lodash';
import { SortAd } from 'utils/enum';
type AppProps = {
  sort: string;
  setSort: (sort: string) => void;
};
const Sort: React.FC<AppProps> = ({ sort, setSort }) => {
  const mapSortLabel = (label: string) => {
    if (label === 'ASC') {
      return 'Oldest created date';
    } else {
      return 'Latest created date';
    }
  };

  const mapSortIcon = (label: string) => {
    if (label === 'ASC') {
      return 'Oldest';
    } else {
      return 'Latest';
    }
  };

  return (
    <Menu>
      <Menu.Button>
        <div
          className="flex border py-3 px-4 border-gray-300"
          data-testid="sort-button"
        >
          <Icon
            name={_.lowerFirst(mapSortIcon(sort))}
            data-testid={`icon-sort-${sort}`}
          />
          <div
            className="mx-2 -mt-1 truncate w-28"
            data-testid={`sort-button-label-${sort}`}
          >
            Sort : {mapSortLabel(sort)}
          </div>
          <Icon name="arrow-down" data-testid="arrow-down" />
        </div>
      </Menu.Button>
      <Menu.Items>
        <Menu.Item>
          {({ active }) => (
            <div
              className={`absolute z-1 bg-white w-[198px] flex cursor-pointer ${
                active && 'bg-gray-200'
              }`}
              onClick={() => setSort(SortAd.Descending)}
            >
              <div className="my-3 ml-4">
                <Icon name="latest" data-testid="latest-icon" />
              </div>
              <div className="my-2 ml-3" data-testid="latest-label">
                Latest
              </div>
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <div
              className={`absolute bg-white w-[198px]  top-20 flex cursor-pointer ${
                active && 'bg-gray-200'
              }`}
              style={{ zIndex: '1' }}
              onClick={() => setSort(SortAd.Ascending)}
            >
              <div className="my-3 ml-4">
                <Icon name="oldest" data-testid="oldest-icon" />
              </div>
              <div className="my-2 ml-3" data-testid="oldest-label">
                Oldest
              </div>
            </div>
          )}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

export default Sort;
