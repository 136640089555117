import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};
const SvgV: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 18,
  color = '#666',
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="m11.347 12.581.854.854V7.2a.4.4 0 1 1 .8 0V13.435l.853-.854 1.16-1.16a.4.4 0 0 1 .566.566l-2.696 2.696a.4.4 0 0 1-.566 0l-2.696-2.696a.4.4 0 0 1 .565-.566l1.16 1.16Zm-8.93-9.264a.4.4 0 0 1 .284-.117h9.9a.4.4 0 1 1 0 .8H2.7a.4.4 0 0 1-.283-.683Zm0 3.6a.4.4 0 0 1 .284-.117H9a.4.4 0 1 1 0 .8H2.7a.4.4 0 0 1-.283-.683Zm0 3.6a.4.4 0 0 1 .284-.117h3.6a.4.4 0 1 1 0 .8H2.7a.4.4 0 0 1-.283-.683Z"
      fill="#111928"
      stroke="#6B7280"
    />
  </svg>
);
export default SvgV;
