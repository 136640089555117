import apiService from '../utils/apiService';

export const getCurrentUser = async () => {
  const { data } = await apiService.get('v1/user/me');
  return data;
};

export const loginAccount = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('v1/auth/login', payload);
  return data;
};

export const saveProfile = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('v1/user/onboarding', payload);
  return data;
};

export const updateProfile = async (payload: Record<string, any>) => {
  const { data } = await apiService.put('v1/user', payload);
  return data;
};

export const updateOrganization = async (payload: Record<string, any>) => {
  const { data } = await apiService.put('v1/organization', payload);
  return data;
};

export const changeUserPassword = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('v1/user/activate-user', payload);
  return data;
};

export const verifyInvite = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('v1/user/verify-invite', payload);
  return data;
};
