import { getUnixTime, fromUnixTime } from 'date-fns';
import { differenceInDays, format } from 'date-fns';

export const getUnix = (date: any) => {
  return getUnixTime(date) * 1000;
};

export const getCurrentUnixTime = () => {
  return getUnixTime(new Date()) * 1000;
};
export const getTimefromUnix = (data: any) => {
  return fromUnixTime(data / 1000);
};

export const daysSinceCreation = (createdAt: number) => {
  const currentDate = getCurrentUnixTime();
  const difference = differenceInDays(currentDate, createdAt);
  return difference;
};

export const TimestampToDateAndTime = (timestamp: number) => {
  const date = getTimefromUnix(timestamp);
  const formattedDate = format(date, 'MM/dd/yyyy h:mm a');
  return formattedDate;
};

export const timestampToDate = (timestamp: number) => {
  const date = getTimefromUnix(timestamp / 1000);
  const formattedDate = format(date, 'MM/dd/yyyy');
  return formattedDate;
};

export const getDateBeforeDays = (numberOfDays: number) => {
  return new Date(new Date().getTime() - numberOfDays * 24 * 60 * 60 * 1000);
};
