import { useQuery } from '@tanstack/react-query';
import apiService from '../utils/apiService';

export const inviteUser = async (payload: any) => {
  const { data } = await apiService.post('/v1/user/invite', payload);
  return data;
};

export const inviteAdmin = async (payload: any) => {
  const { data } = await apiService.post('/v1/user/invite-admin', payload);
  return data;
};

export const inviteMediaAdmin = async (payload: any) => {
  const { data } = await apiService.post('/v1/user/media-admin', payload);
  return data;
};

export const allActiveSuperAdmins = async (q: Record<string, any>) => {
  const { data } = await apiService.get('/v1/user/superAdmins', q);
  return data;
};

export const allInvitees = async (q: Record<string, any>) => {
  const { data } = await apiService.get('/v1/user/active-pending', q);
  return data;
};

export const revokeInvitee = async (id: string, q: Record<string, any>) => {
  if (q.associationId == ' ' || q.associationId == '') {
    let { data } = await apiService.delete(`/v1/user/${id}/revoke-invitation`);
    return data;
  }
  const { data } = await apiService.delete(
    `/v1/user/${id}/revoke-invitation`,
    q
  );
  return data;
};

export const removeUser = async (id: string) => {
  const { data } = await apiService.patch(`/v1/user/${id}/remove-advertiser`);
  return data;
};

export const statusChangeUser = async (payload: any) => {
  const { data } = await apiService.put(`/v1/association`, payload);
  return data;
};

export const statusChangeOrganization = async (payload: any) => {
  const { data } = await apiService.put(`/v1/organization/deactivate`, payload);
  return data;
};

export const resendInvite = async (id: string, q?: Record<string, any>) => {
  const { data } = await apiService.put(`/v1/user/${id}/reinvite`, {}, q);
  return data;
};

export const useAllActiveSuperAdmins = (
  q: Record<string, any>,
  enabled = true
) => {
  return useQuery(['allInvitees', q], () => allActiveSuperAdmins(q), {
    staleTime: 60 * 60 * 1000,
    enabled: enabled
  });
};

export const useAllInvitees = (q: Record<string, any>, enabled = true) => {
  return useQuery(['allInvitees', q], () => allInvitees(q), {
    staleTime: 60 * 60 * 1000,
    enabled: enabled
  });
};

export const registerAccount = async (q: Record<string, any>) => {
  const { data } = await apiService.get('/v1/user/registered-org?', q);
  return data;
};
export const useAllRegisterAccount = (
  q: Record<string, any>,
  enabled = true
) => {
  return useQuery(['allregister', q], () => registerAccount(q), {
    staleTime: 60 * 60 * 1000,
    enabled: enabled
  });
};

export const registerMediaAccount = async (q: Record<string, any>) => {
  const { data } = await apiService.get('/v1/user/mediaAdmins', q);
  return data;
};
export const useAllRegisterMediaAccount = (
  q: Record<string, any>,
  enabled = true
) => {
  return useQuery(['allmediaregister', q], () => registerMediaAccount(q), {
    staleTime: 60 * 60 * 1000,
    enabled: enabled
  });
};

const getAdvertiserDetail = async (q: Record<string, any>) => {
  const { data } = await apiService.get(`/v1/user/advertiser-orgs`, q);
  return data;
};

export const useAllAdvertiserAccount = (
  q: Record<string, any>,
  enabled = true
) => {
  return useQuery(['alladvertiser', q], () => getAdvertiserDetail(q), {
    staleTime: 60 * 60 * 1000,
    enabled: enabled
  });
};

export const inviteAdvertiserAdmin = async (payload: any) => {
  const { data } = await apiService.post(`v1/user/create-advAdmin`, payload);
  return data;
};

export const editMediaAdmin = async (payload: any) => {
  const { data } = await apiService.post(`/v1/user/invite-admin`, payload);
  return data;
};
