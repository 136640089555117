import React from "react";

const Sort =() =>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      fill="none"
      viewBox="0 0 16 14"
    >
      <path
        fill="#6B7280"
        d="M1.7.7a.9.9 0 000 1.8h9.9a.9.9 0 000-1.8H1.7zm0 3.6a.9.9 0 000 1.8H8a.9.9 0 000-1.8H1.7zm0 3.6a.9.9 0 000 1.8h3.6a.9.9 0 000-1.8H1.7zm10.8-2.7a.9.9 0 10-1.8 0v5.028L9.538 9.064a.9.9 0 00-1.273 1.272l2.7 2.7a.9.9 0 001.273 0l2.7-2.7a.9.9 0 00-1.272-1.272L12.5 10.228V5.2z"
      ></path>
    </svg>
  );
}

export default Sort;