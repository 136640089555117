import Icon from 'components/Icon';
import React, { useEffect, useState } from 'react';

interface LoaderProps {
  show: boolean;
  duration?: number;
}

const Loader: React.FC<LoaderProps> = ({ show, duration = 1500 }) => {
  const [isShowing, setIsShowing] = useState(show);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (show) {
      setIsShowing(true);
      timeoutId = setTimeout(() => setIsShowing(false), duration);
    } else {
      setIsShowing(false);
    }
    return () => clearTimeout(timeoutId);
  }, [show, duration]);

  return isShowing ? (
    <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-70 z-50">
      <Icon name="circularProgress" />
    </div>
  ) : null;
};

export default Loader;
