import { useMutation } from '@tanstack/react-query';
import Layout from 'components/Forms/Layout';
import { useAdverts } from 'queries/ad';
import { getCurrentUser } from 'queries/auth';
import { useOrgDetail } from 'queries/org';
import { uploadImage } from 'queries/uploadFile';
import React, { useEffect, useState } from 'react';
import { FieldType } from 'utils/enum';
import { Role } from 'utils/map';

type AppProps = {
  id: string;
};

interface User {
  email: string;
  firstName: string;
  lastName: string;
  profileLogoURL: string;
}

const OrganizationCard: React.FC<AppProps> = ({ id }) => {
  let { data } = useOrgDetail(id);
  const [profilePhoto, setProfilePhoto] = useState('');
  const [loginUserName, setLoginUserName] = useState('');
  const [loginUserEmail, setLoginUserEmail] = useState('');

  const handleImage = (e: any) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('upload_preset', 'unboxedsass');
    uploadProfileLogo.mutate(formData);
  };

  const uploadProfileLogo = useMutation(
    (formData: any) => uploadImage(formData),
    {
      onSuccess: (res: any) => {
        setProfilePhoto(res.secure_url);
      }
    }
  );
  const profileImage = [
    {
      name: 'profileLogo',
      label: 'Profile Image (optional)',
      className: '-mt-5',
      showLabel: false,
      type: FieldType.UploadImage,
      imageRendered: profilePhoto,
      onChange: handleImage,
      dataTestId: 'profile-image-input'
    }
  ];
  const allMediaChannel = useMutation(() => getCurrentUser(), {
    onError: (err: any) => {},
    onSuccess: (res: User) => {
      const user = res;
      setLoginUserName(user?.firstName + ' ' + user?.lastName);
      setLoginUserEmail(user?.email);
      setProfilePhoto(user?.profileLogoURL);
    }
  });
  useEffect(() => {
    allMediaChannel.mutate();
  }, []);
  return (
    <div className="flex bg-white rounded-l border shadow-lg mb-8 mx-36">
      <div className="mt-8 ml-10">
        {' '}
        <img
          className="w-24 h-24 rounded-full"
          src={
            profilePhoto
              ? profilePhoto
              : 'https://www.pngkey.com/png/detail/387-3875770_placeholder-image-no-photo-available-circle.png'
          }
          alt="adPreviewDesktop"
          data-testid="image-preview-profile"
        />
      </div>
      <div className="flex flex-col mt-8 ml-4 mb-10">
        <div className="text-2xl font-bold">{loginUserName}</div>
        <div className="text-2xl font-normal text-gray-500 mt-1">
          {loginUserEmail}
        </div>
      </div>
    </div>
  );
};

export default OrganizationCard;
