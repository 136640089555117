import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Profile: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 14,
  color = '#666',
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 5.6A2.7 2.7 0 1 0 7 .2a2.7 2.7 0 0 0 0 5.4ZM.7 13.7a6.3 6.3 0 1 1 12.6 0H.7Z"
      fill="#9CA3AF"
    />
  </svg>
);
export default Profile;
