import React from 'react';
import { SubmitHandler, useController } from 'react-hook-form';
import clx from 'classnames';
import Select, { components } from 'react-select';
import { BORDER_COLOR, PRIMARY_COLOR, TEXT_COLOR } from 'utils/constants';
import { find } from 'lodash';
import Icon from 'components/Icon';

interface IOptions {
  value: string | any[];
  label: string;
}

type AppProps = {
  label: string;
  name: string;
  options: IOptions[];
  placeholder?: string;
  isClearable?: boolean;
  className?: string;
  labelStyle?: string;
  control?: any;
  required?: boolean;
  onChange?: SubmitHandler<any>;
  error?: string;
  isCreatable?: boolean;
  disabled?: boolean;
  isSearchable?: boolean;
  loading?: boolean;
  placement?: string;
};

const ESSelect: React.FC<AppProps> = ({
  name,
  label,
  control,
  labelStyle,
  placeholder = 'Select option',
  isClearable = false,
  className = '',
  disabled = false,
  options = [],
  error = null,
  required = false,
  loading = false,
  isCreatable = false,
  isSearchable = false,
  placement = 'auto'
}) => {
  const { field } = useController({ name, control });

  const selectStyle = clx(
    `w-full h-10.5 text-sm rounded outline-none focus:outline-none focus:ring-0 rounded-lg`,
    {
      'focus:border-error-dark border-error': !!error,
      'focus:border-primary border-border ': !error
    }
  );

  const { Option } = components;

  const IconOption = (props: any) => (
    <Option {...props}>
      <div className="flex">
        <Icon name={`${props.value}`} />
        <div className="mx-2">{props.label}</div>
      </div>
    </Option>
  );

  return (
    <div className={`mb-6 ${className}`} key={name}>
      <div className={`${labelStyle}`}>
        {label}
        {required && <span className="text-error">*</span>}
      </div>
      <Select
        {...field}
        onChange={(v: any) => field.onChange(v.value)}
        value={find(options, { value: field.value }) || null}
        options={options}
        placeholder={<span className="text-text-light">{placeholder}</span>}
        isClearable={isClearable}
        className={selectStyle}
        styles={customStyles}
        components={{ Option: IconOption }}
        menuPosition="fixed"
        menuPortalTarget={document.body}
        isDisabled={loading || disabled}
        isSearchable={isSearchable}
        // @ts-ignore
        menuPlacement={placement}
      />
      {error && <div className="text-error text-xs">*{error}</div>}
    </div>
  );
};

ESSelect.displayName = 'Select';

export default ESSelect;

const customStyles = {
  input: (provided: any) => ({
    ...provided,
    color: TEXT_COLOR,
    borderRadius: '8px',
    height: '42px',
    'input:focus': {
      boxShadow: 'none',
      borderColor: 'green'
    }
  }),
  control: (base: any, state: any) => ({
    ...base,
    height: '42px',
    minHeight: '42px',
    borderRadius: '8px',
    boxShadow: 'none',
    color: TEXT_COLOR,
    borderColor: state.isFocused ? PRIMARY_COLOR : BORDER_COLOR
  }),
  singleValue: (base: any) => ({
    ...base,
    color: TEXT_COLOR
  }),
  option: (base: any, { isFocused }: any) => {
    let backgroundColor = '#fff';
    let color = TEXT_COLOR;
    if (isFocused) {
      backgroundColor = PRIMARY_COLOR;
      color = '#fff';
    }
    return {
      ...base,
      backgroundColor,
      color
    };
  },
  menuPortal: (base: any) => {
    return {
      ...base,
      zIndex: 9999
    };
  },
  noOptionsMessage: (base: any) => ({
    ...base,
    fontSize: 16
  })
};
