import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const SvgV: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 14,
  color = '#666',
  ...rest
}) => (
  <svg
    width={size - 1}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10.29 5.375 6.5 9.167 2.707 5.375"
      stroke="#6B7280"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgV;
