import React, { useEffect, useState } from 'react';
import { FieldType } from 'utils/enum';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Layout from 'components/Forms/Layout';
import WhiteLoader from 'components/WhiteLoader';
import { Variant } from 'components/Forms/components/Input';
import { useQueryClient } from '@tanstack/react-query';
import { useAdDetail } from 'queries/ad';
import Button, { Variants } from 'components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { CHANNEL, DELIVERY, FORMAT, FREQUENCY, POSITION } from 'data/options';
import { find } from 'lodash';
import { getTimefromUnix } from 'utils/time';
import { extractFileName } from 'utils/helper';

interface IForm {
  orgName: string;
  adName: string;
  mediaChannel: string | null;
  adDelivery: string;
  adFormat: string;
  adSize: string | null;
  adPosition: string | null;
  pageNumber?: number;
  destinationURL: string;
  startDate?: number;
  endDate?: number;
  adFrequency: number | null;
  adLayout: string;
}

type AppProps = {
  adImage: string;
  setAdImage: (...args: any) => void;
  disabled: boolean;
};

const MediaAdminAdConfiguration: React.FC<AppProps> = ({
  adImage,
  setAdImage,
  disabled
}) => {
  const { id = '' } = useParams();
  const { data, isLoading } = useAdDetail(id);
  const editable = id && !disabled;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [fileName, setFileName] = useState('');
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    control,
    reset,
    watch,
    formState: { errors }
  } = useForm<IForm>();

  useEffect(() => {
    setFileName(extractFileName(data ? data?.mediaLink : ''));
    reset({
      orgName: data?.organization?.orgName || '',
      adName: data?.adName || '',
      mediaChannel: data?.mediaChannel?.orgName || '',
      adDelivery: data?.adDelivery || null,
      adFormat: data?.adFormat || 'image',
      adSize: data?.adSize || '',
      adPosition: data?.adPosition || '',
      adFrequency: data?.adFrequency || 1,
      destinationURL: data?.destinationURL || '',
      pageNumber: data?.pageNumber || NaN,
      adLayout: data?.adLayout?.adLayoutName || ''
    });
    setAdImage(data?.mediaLink || '');
    if (data?.startDate) {
      setStartDate(getTimefromUnix(data?.startDate));
    }
    if (data?.endDate) {
      setEndDate(getTimefromUnix(data?.endDate));
    }
  }, [data]);

  const _adFormat = watch('adFormat');

  const fields = [
    {
      label: 'Advertiser name',
      className: 'mb-4 font-normal',
      type: FieldType.Input,
      variant: Variant.Text,
      disabled,
      placeholder: 'Enter ad name',
      error: errors.orgName?.message,
      dataTestId: 'full-name-input',
      ...register('orgName')
    },
    {
      label: 'Article name',
      className: 'mb-4 font-normal',
      type: FieldType.Input,
      variant: Variant.Text,
      disabled,
      placeholder: 'Enter ad name',
      error: errors.adName?.message,
      dataTestId: 'full-name-input',
      ...register('adName')
    },
    {
      label: 'Page number (optional)',
      className: 'font-normal inline-block',
      type: FieldType.Input,
      disabled,
      variant: Variant.Number,
      placeholder: 'Enter page number',
      error: errors.pageNumber?.message,
      dataTestId: 'page-number-input',
      ...register('pageNumber')
    },
    {
      label: 'Ad position',
      type: FieldType.Select,
      control,
      disabled,
      options: POSITION,
      labelStyle: 'text-sm mb-2 font-semibold',
      className: 'mb-8 inline-block ml-3 w-72',
      placeholder: 'Select',
      error: errors.adPosition?.message,
      dataTestId: 'Ad-position-input',
      ...register('adPosition')
    },
    {
      label: 'File name',
      className: disabled ? 'mb-6 font-normal' : 'hidden',
      type: FieldType.Input,
      variant: Variant.Text,
      disabled,
      defaultValue: fileName,
      dataTestId: 'full-name-input'
    },
    {
      label: 'Ad size',
      type: FieldType.Select,
      control,
      disabled,
      options: find(FORMAT, { value: `${_adFormat}` })?.size || [],
      labelStyle: 'text-sm mb-2 font-semibold',
      className: 'mb-8',
      placeholder: 'Select',
      error: errors.adSize?.message,
      dataTestId: 'Ad-size-input',
      ...register('adSize')
    },
    {
      label: 'Ad layout',
      type: FieldType.Select,
      control,
      disabled,
      labelStyle: 'text-sm mb-2 font-semibold',
      className: 'mb-8',
      placeholder: 'Select',
      error: errors.adLayout?.message,
      dataTestId: 'Ad-size-input',
      ...register('adLayout')
    },
    {
      label: 'Ad delivery',
      buttons: DELIVERY,
      control,
      className: 'mb-8',
      disabled,
      type: FieldType.Radio,
      error: errors.adDelivery?.message,
      dataTestId: 'Ad-channel-input',
      ...register('adDelivery')
    },
    {
      label: 'Ad format',
      buttons: FORMAT,
      className: 'mb-8',
      control,
      disabled,
      type: FieldType.Radio,
      error: errors.adFormat?.message,
      dataTestId: 'Ad-channel-input',
      ...register('adFormat')
    },
    {
      label: 'Destination URL',
      className: 'font-normal',
      type: FieldType.Input,
      disabled,
      variant: Variant.Text,
      placeholder: 'Enter destination url',
      error: errors.destinationURL?.message,
      dataTestId: 'destination-url-input',
      ...register('destinationURL')
    },
    {
      name: 'startDate',
      label: 'Target delivery start date and time',
      className: 'font-normal space-y-1 mb-6',
      type: FieldType.DateTime,
      disabled,
      onChange: setStartDate,
      value: startDate,
      dataTestId: 'start-date-input'
    },
    {
      name: 'endDate',
      label: 'Target delivery end date and time',
      className: 'font-normal space-y-1 mb-6',
      disabled,
      type: FieldType.DateTime,
      onChange: setEndDate,
      value: endDate,
      dataTestId: 'end-date-input'
    },
    {
      label: 'Ad frequency',
      type: FieldType.Select,
      control,
      disabled,
      options: FREQUENCY,
      className: 'mb-8 hidden',
      labelStyle: 'font-semibold text-sm mb-2',
      placeholder: 'Select',
      error: errors.adFrequency?.message,
      dataTestId: 'Ad-size-input',
      ...register('adFrequency')
    }
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        data-testid="create-ad-form"
      >
        <Layout fields={fields} className="p-8" />
        <div className="bg-white border fixed left-0 right-0 bottom-0 shadow-lg w-full px-20 py-4 flex justify-between items-center">
          <Button
            label="Cancel"
            variant={Variants.Negative}
            className="w-28 border-0"
            onClick={() => {
              navigate('/adverts');
            }}
            dataTestId="back-dashboard"
          />
        </div>
      </form>
      <WhiteLoader show={loader || isLoading || id !== ''} />
    </div>
  );
};

export default MediaAdminAdConfiguration;
