import React, { useState } from 'react';
import { useParams } from 'react-router';
import _ from 'lodash';
import WhiteLoader from 'components/WhiteLoader';
import LayoutConfirguration from './components/LayoutConfiguration';
import AppShell from 'components/AppShell';

type AppProps = {
  page: string;
  disabled: boolean;
};

const CreateAdlayout: React.FC<AppProps> = ({ disabled, page }) => {
  const { id = '' } = useParams();
  const editable = id && !disabled;
  const [loader, setLoader] = useState(false);

  return (
    <AppShell>
      <div>
        <div className="bg-background-theme h-theme grid">
          <div className="flex justify-between mt-8 mb-8 ml-16 text-white text-4xl">
            <div
              className="font-semibold text-4xl"
              data-testid="create-ad-label"
            >
              <div className="flex">
                <div>Create ad layout</div>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-2/3 m-auto mt-8 mb-28">
              <div className="bg-white rounded-l border shadow-lg">
                <div
                  className="font-bold text-xl ml-8 mt-4 mb-3"
                  data-testid="ad-configuration-label"
                >
                  Ad layout configuration
                </div>
                <hr className="h-px bg-horizontalRule border-0 dark:bg-horizontalRule"></hr>
                <LayoutConfirguration id={id} editable={editable} />
              </div>
            </div>
          </div>
        </div>
        <WhiteLoader show={loader} />
      </div>
    </AppShell>
  );
};

export default CreateAdlayout;
