export enum FieldType {
  Input = 'input',
  Select = 'select',
  MultiSelect = 'multiSelect',
  Checkbox = 'checkbox',
  Radio = 'radio',
  ChipSingleSelect = 'chipSingleSelect',
  ChipMultiSelect = 'chipMultiSelect',
  UploadImage = 'uploadImage',
  UploadFile = 'uploadFile',
  DateTime = 'DateTime'
}

export enum Status {
  Active = 'ACTIVE',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Expired = 'EXPIRED',
  Draft = 'DRAFT',
  Unpublished = 'UNPUBLISHED',
  Published = 'PUBLISHED'
}

export enum SortAd {
  Ascending = 'ASC',
  Descending = 'DSC'
}

export enum Role {
  SuperAdmin = 'SUPER_ADMIN',
  AtcSales = 'ATC_SALES',
  mediaChannelAdmin = 'MEDIA_CHANNEL_ADMIN',
  mediaChannelUser = 'MEDIA_CHANNEL_USER',
  AdvertiserAdmin = 'ADVERTISER_ADMIN',
  AdvertiserUser = 'ADVERTISER_USER'
}
