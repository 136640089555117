import React from 'react';
import clx from 'classnames';

export enum Variants {
  Primary = 'primary',
  Negative = 'negative',
  PrimaryOutlined = 'primary-outlined',
  PrimaryNoOutlined = 'primary-no-outlined',
  Outlined = 'outlined',
  Alert = 'alert',
  SemiPrimary = 'semi-primary',
  Disabled = 'disabled'
}

export enum Size {
  Default = 'default',
  Small = 'small'
}

type AppProps = {
  label: string | React.ReactNode;
  variant?: Variants;
  size?: Size;
  className?: string;
  dataTestId?: string;
  onClick?: (...args: any) => any;
  disabled?: boolean;
  loading?: boolean;
};

const Button: React.FC<AppProps> = ({
  label,
  variant = Variants.Primary,
  size = Size.Default,
  className = '',
  dataTestId = '',
  onClick = () => null,
  loading = false,
  disabled = false
}) => {
  const _disabled = disabled || loading;

  const btnStyle = clx(
    `h rounded inline-flex items-center justify-center rounded cursor-pointer ${className}`,
    {
      'bg-background-white bg-white border border-blue-600 rounded-lg':
        variant === Variants.PrimaryOutlined,
      'bg-white border border-primary-light rounded-md':
        variant === Variants.Outlined,
      'bg-background-white': Variants.PrimaryOutlined,
      'bg-red-500 border border-primary-light rounded-md':
        variant === Variants.Alert,
      'bg-primary': variant === Variants.Primary,
      'bg-blue-50 text-blue-900 text-sm font-semibold leading-tight':
        variant === Variants.SemiPrimary,
      'text-gray-500': variant === Variants.Disabled,
      'bg-white border border-primary': variant === Variants.Negative,
      'h-10': size === Size.Small,
      'bg-opacity-40 pointer-events-none': _disabled
    }
  );

  const textStyle = clx('font-medium text-sm py-2.5 px-5', {
    'text-primary':
      variant === Variants.PrimaryOutlined ||
      variant === Variants.Outlined ||
      variant === Variants.Negative ||
      variant === Variants.PrimaryNoOutlined,
    'text-white': variant === Variants.Primary || variant === Variants.Alert,
    'text-sm': size === Size.Small
  });

  const btnContent = () => <div className={textStyle}>{label}</div>;

  return (
    <button
      data-testid={dataTestId}
      className={btnStyle}
      onClick={_disabled ? () => null : onClick}
    >
      {btnContent()}
    </button>
  );
};

export default Button;
