import React from 'react';
import clx from 'classnames';
import Icon from 'components/Icon';

type App = {
  name: string;
  icon?: string;
  isSelected?: boolean;
  onSelect?: () => void;
};

const Chip: React.FC<App> = ({
  name,
  icon = '',
  isSelected = false,
  onSelect = () => null
}) => {
  const chipStyle = clx(
    'relative border h-10 w-24 rounded-md center cursor-pointer px-4 pt-1',
    {
      'border-border': !isSelected,
      'bg-blue-50': isSelected
    }
  );

  const labelStyle = clx('text-sm', {
    'text-text': !isSelected,
    'bg-blue-50 font-bold': isSelected
  });

  return (
    <div className={chipStyle} onClick={onSelect}>
      <Icon
        name={icon}
        className="mr-2"
        color={isSelected ? 'green' : undefined}
      />{' '}
      <span className={labelStyle}>{name}</span>
    </div>
  );
};

export default Chip;
