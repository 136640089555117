import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};
const SvgVector: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 16,
  color = '#666',
  ...rest
}) => (
  <svg
    width={size - 2}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M7 10.25v1.5m-4.5 3h9a1.5 1.5 0 0 0 1.5-1.5v-4.5a1.5 1.5 0 0 0-1.5-1.5h-9A1.5 1.5 0 0 0 1 8.75v4.5a1.5 1.5 0 0 0 1.5 1.5Zm7.5-7.5v-3a3 3 0 1 0-6 0v3h6Z"
      stroke="#6B7280"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgVector;
