import { useState } from 'react';

export const useModal = (): any => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return [open, showModal, closeModal];
};
