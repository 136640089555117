import React, { useState, useEffect } from 'react';
import AppShell from 'components/AppShell';
import { useAdverts } from 'queries/ad';
import AdCard from 'components/AdCard';
import { FieldType } from 'utils/enum';
import { Variant } from 'components/Forms/components/Input';
import { debounce } from 'lodash';
import Layout from 'components/Forms/Layout';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import { Sort_Ad } from 'data/options';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FilterPopover from './FilterPopover';
import Sort from './Sort';
import { Status, SortAd } from 'utils/enum';
import { AD_CHANNEL } from 'utils/map';
import EmptyState from 'components/EmptyState';
import { timestampToDate } from 'utils/time';
import _ from 'lodash';
import Icon from 'components/Icon';
import useRole from 'hooks/useRole';

interface IForm {
  sort: string;
}

interface IFilter {
  statuses: Status[];
  date?: number | undefined;
  adChannel?: string | null;
}

const AdvertiserAdminAdverts = () => {
  const navigate = useNavigate();

  const schema = yup.object({
    sort: yup.string()
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { control, handleSubmit, setValue } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      sort: Sort_Ad[0].value
    }
  });

  const [searchQuery, setSearchQuery] = useState<String>('');
  const [sort, setSort] = useState<string>(SortAd.Descending);
  const [allCheckBox, setAllCheckBox] = useState(false);
  const [filters, setFilters] = useState<IFilter>({
    statuses: [],
    date: undefined,
    adChannel: ''
  });

  let { data } = useAdverts({
    q: searchQuery,
    order: sort,
    date: filters?.date,
    statuses: filters?.statuses.join(','),
    adChannel: filters?.adChannel
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateQuery = (e: any) => {
    setSearchQuery(e?.target?.value);
  };

  const handleFilterChange = (filterData: IFilter) => {
    let { date, adChannel, ...statuses } = filterData;
    let transformFilterData: IFilter;
    if (allCheckBox) {
      transformFilterData = {
        statuses: Object.values({
          Approved: Status.Approved,
          Draft: Status.Draft,
          Pending: Status.Pending,
          Published: Status.Published,
          Rejected: Status.Rejected,
          Unpublished: Status.Unpublished
        })
      };
    } else {
      transformFilterData = {
        statuses: Object.values(statuses)
          .filter((v) => v !== undefined && v !== null)
          .flat()
      };
    }

    if (date && !isNaN(date)) {
      transformFilterData.date = date;
    }

    if (adChannel) {
      transformFilterData.adChannel = adChannel;
    }
    setFilters(transformFilterData);
  };

  function handleFilterRemove(type: string, value: any) {
    switch (type) {
      case 'date':
        handleFilterChange({
          ...filters,
          date: undefined
        });
        break;
      case 'adChannel':
        handleFilterChange({
          ...filters,
          adChannel: undefined
        });
        break;
      case 'status':
        setAllCheckBox(false);
        handleFilterChange({
          ...filters,
          statuses: filters.statuses.filter((status) => status !== value)
        });
        break;
      default:
        break;
    }
  }

  const showFilters = () => {
    return (
      <div className="flex flex-wrap" data-testid="show-all-filters">
        {filters?.date && (
          <span className="flex bg-blue-50 py-0.5 px-2.5 w-fit mr-4 mb-4 text-sm">
            <span
              className="text-blue-800 font-semibold"
              data-testid="show-filters-date"
            >
              Date
            </span>
            : {timestampToDate(filters?.date)}
            <div
              onClick={(e) => handleFilterRemove('date', filters.date)}
              className="cursor-pointer"
              data-testid="date-filter-x-icon"
            >
              <Icon name="x" />{' '}
            </div>{' '}
          </span>
        )}
        {filters?.adChannel && (
          <span className="flex bg-blue-50 py-0.5 px-2.5 w-fit mr-4 mb-4 text-sm">
            <span
              className="text-blue-800 font-semibold"
              data-testid="show-filters-media-channel"
            >
              Media Channel
            </span>
            : {AD_CHANNEL[filters?.adChannel]}
            <div
              onClick={(e) =>
                handleFilterRemove('adChannel', filters.adChannel)
              }
              className="cursor-pointer"
              data-testid="channel-filters-x-icon"
            >
              <Icon name="x" />{' '}
            </div>{' '}
          </span>
        )}
        {filters?.statuses.map((status: string, id: number) => {
          return (
            <span
              className="flex bg-blue-50 py-0.5 px-2.5 w-fit mr-4 mb-4 text-sm"
              key={id}
            >
              <span
                className="text-blue-800 font-semibold"
                data-testid={`show-filters-statuses-${id}`}
              >
                Status
              </span>
              : {_.capitalize(status)}
              <div
                onClick={(e) => handleFilterRemove('status', status)}
                className="cursor-pointer"
                data-testid={`statuses-filters-x-icon-${id}`}
              >
                <Icon name="x" />{' '}
              </div>
            </span>
          );
        })}
      </div>
    );
  };

  const debouncedOnChange = debounce(updateQuery, 500);
  const handleSearchQueryChange = (e: any) => {
    if (e.target.value.length === 0 || e.target.value.length > 2) {
      debouncedOnChange(e);
    }
  };

  const field = [
    {
      name: 'search',
      type: FieldType.Input,
      variant: Variant.Text,
      placeholder: 'Search by ad name',
      onChange: handleSearchQueryChange,
      className: 'text-sm',
      dataTestId: 'search-adverts'
    }
  ];

  const isFiltersEmpty = () => {
    return _.isEqual(filters, {
      adChannel: '',
      date: undefined,
      statuses: []
    });
  };

  return (
    <AppShell>
      <div className="pb-24">
        <div className="bg-background-theme h-theme grid">
          <div className="my-8 mx-48 text-white text-4xl flex justify-between">
            <div className="font-semibold text-4xl" data-testid="adverts-label">
              Adverts
            </div>
            <Button
              label="Create ad"
              className="w-28 bg-primary"
              data-testid="create-ad-dashboard"
              onClick={() => navigate('/create-ad')}
            />
          </div>
        </div>
        <div
          className="flex justify-between mt-6 mx-36"
          data-testid="search-adverts"
        >
          <Layout fields={field} className="w-80 text-sm" />
          <div>
            <FilterPopover
              filters={filters}
              handleFilterChange={handleFilterChange}
              allCheckBox={allCheckBox}
              setAllCheckBox={setAllCheckBox}
              data-testid="filter-popover-layout"
            />
          </div>
        </div>
        <div className="mb-16 mx-36">{showFilters()}</div>
        <div className="mx-36 py-9 border-t-2 flex justify-between">
          <div data-testid="showing-adverts-results">
            {data?.filteredAds?.length !== 0
              ? `Showing 1 to ${data?.length ?? ''} of ${
                  data?.length ?? ''
                } results`
              : 'Showing 0 results'}
          </div>
          <div className="relative">
            <Sort sort={sort} setSort={setSort} />
          </div>
        </div>

        {data?.filteredAds?.length !== 0 ? (
          <div className="flex justify-center mx-8">
            <div
              className="grid grid-cols-1 md:grid-cols-2 md:gap-6 lg:grid-cols-3 lg:gap-8"
              data-testid="all-adverts-card"
            >
              {data?.filteredAds?.map((dep: any) => (
                <div key={dep._id}>
                  <AdCard key={dep._id} {...dep} id={dep._id} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            {searchQuery || !isFiltersEmpty() ? (
              <EmptyState
                label1="Sorry! No match found."
                label2="we couldn't find what you were looking for"
              />
            ) : (
              <EmptyState />
            )}
          </div>
        )}
        {/* <div className="text-center text-text-primary mt-20">View more</div> */}
      </div>
    </AppShell>
  );
};

export default AdvertiserAdminAdverts;
