import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};
const Duplicate: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 16,
  color = '#666'
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 18 19"
  >
    <path
      fill="#9CA3AF"
      d="M6.3 8.6a1.8 1.8 0 011.8-1.8h5.4a1.8 1.8 0 011.8 1.8V14a1.8 1.8 0 01-1.8 1.8H8.1A1.8 1.8 0 016.3 14V8.6z"
    ></path>
    <path
      fill="#9CA3AF"
      d="M4.5 3.2A1.8 1.8 0 002.7 5v5.4a1.8 1.8 0 001.8 1.8V5h7.2a1.8 1.8 0 00-1.8-1.8H4.5z"
    ></path>
  </svg>
);
export default Duplicate;
