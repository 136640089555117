import { Menu as TMenu, Transition } from '@headlessui/react';
import Icon from 'components/Icon';
import { Fragment } from 'react';

interface IOption {
  label: string;
  loading?: boolean;
  dataTestId: string;
  icon: string;
  disabled: boolean;
  onClick: () => any;
}

type AppProps = {
  options?: IOption[];
};

const Menu: React.FC<AppProps> = ({ options = [] }) => {
  if (!options.length) {
    return null;
  }

  return (
    <div>
      <TMenu as="div" className="relative inline-block text-left">
        <div>
          <TMenu.Button className="p-1">
            <div data-testid="ads-crud">
              <Icon name="more" />
            </div>
          </TMenu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <TMenu.Items className="absolute z-[99999] right-0 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {options.map((option) => (
              <div key={option.label}>
                <TMenu.Item>
                  {({ active }) => (
                    <div
                      onClick={option.disabled ? () => {} : option.onClick}
                      data-testid={option.dataTestId}
                      className={`${
                        active ? 'bg-gray-100 text-black' : 'text-text'
                      } group flex w-full items-center rounded-md px-4 py-2 cursor-pointer text-sm ${
                        option.disabled ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    >
                      {option.label === 'Delete' ? (
                        <div className="border-y-2"></div>
                      ) : (
                        ''
                      )}
                      <Icon name={option.icon} className="ml-2" />
                      <div className="ml-2">{option.label}</div>
                    </div>
                  )}
                </TMenu.Item>
              </div>
            ))}
          </TMenu.Items>
        </Transition>
      </TMenu>
    </div>
  );
};

export default Menu;
