import React, { useEffect, useState } from 'react';
import { FieldType } from 'utils/enum';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import WhiteLoader from 'components/WhiteLoader';
import * as yup from 'yup';
import Layout from 'components/Forms/Layout';
import { Variant } from 'components/Forms/components/Input';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { readAxiosErr } from 'utils/apiService';
import { toast } from 'react-hot-toast';
import Button, { Variants } from 'components/Button';
import { ADSIZE_DESKTOP, ADSIZE_MOBILE, DELIVERY } from 'data/options';
import {
  createAdLayout,
  updateAdLayout,
  useAdlayoutDetail
} from 'queries/adLayout';
import { useNavigate } from 'react-router-dom';

interface IForm {
  adLayoutName: string;
  pageNumber?: number;
  adDelivery: string;
  adSizeDesktop: string | null;
  adSizeMobile: string | null;
}

const schema = yup.object({
  adLayoutName: yup.string().min(2).max(110).required('Ad name is required'),
  pageNumber: yup
    .number()
    .notRequired()
    .transform(function (value, originalValue) {
      if (!originalValue) {
        return null;
      }
      return parseInt(originalValue);
    })
    .positive('Page number must be greater than 0')
    .min(1, 'Page number must be greater than or equal to 1')
    .nullable()
    .typeError('Page number must be a number'),
  adDelivery: yup.string().required('Ad delivery is required'),
  adSizeDesktop: yup.string().when('adDelivery', {
    is: (val: string) => val === 'desktop' || val === 'desktopAndMobile',
    then: yup.string().required('Desktop ad size is required'),
    otherwise: yup.string()
  }),
  adSizeMobile: yup.string().when('adDelivery', {
    is: (val: string) => val === 'mobile' || val === 'desktopAndMobile',
    then: yup.string().required('Mobile ad size is required'),
    otherwise: yup.string()
  })
});

type AppProps = {
  id: string;
  editable: boolean | string;
};

const LayoutConfirguration: React.FC<AppProps> = ({ id, editable }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data, isLoading } = useAdlayoutDetail(id);
  useEffect(() => {
    queryClient.invalidateQueries(['adlayoutDetails']);
  }, []);

  const handleSubmitCreate = useMutation(
    (formData: Record<string, any>) => createAdLayout(formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        queryClient.removeQueries(['layout']);
        setLoader(false);
        navigate(`/adlayout`);
        toast.success('Ad Layout Created Successfully');
      }
    }
  );
  const handleUpdateForm = useMutation(
    (formData: Record<string, any>) => updateAdLayout(formData, id),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        queryClient.removeQueries(['layout']);
        setLoader(false);
        navigate(`/adlayout`);
        toast.success('Ad layout succesfully updated');
      }
    }
  );
  const [loader, setLoader] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm<IForm>({
    resolver: yupResolver(schema)
  });

  const handleCreate = () => {
    setLoader(true);
    handleSubmit(async (formData) => {
      formData.adDelivery = formData.adDelivery.toUpperCase();
      handleSubmitCreate.mutate(formData);
    })();
  };

  const handleUpdate = () => {
    setLoader(true);

    handleSubmit(async (formData) => {
      if (formData.adDelivery) {
        formData.adDelivery = formData.adDelivery.toUpperCase();
      }
      handleUpdateForm.mutate(formData);
    })();
  };

  const _adDelivery = watch('adDelivery');

  useEffect(() => {
    reset({
      adLayoutName: data?.adLayoutName || '',
      pageNumber: data?.pageNumber || '',
      adDelivery: data?.adDelivery || null,
      adSizeDesktop: data?.adSizeDesktop ? data?.adSizeDesktop : '' || '',
      adSizeMobile: data?.adSizeMobile ? data?.adSizeMobile : '' || ''
    });
  }, [data]);

  useEffect(() => {
    setLoader(true);
    reset({
      adDelivery: 'desktop'
    });
  }, []);

  const fields = [
    {
      label: 'Ad layout name',
      className: 'mb-4 mt-2 font-normal',
      type: FieldType.Input,
      variant: Variant.Text,
      disabled: id,
      placeholder: 'Enter ad layout name',
      error: errors.adLayoutName?.message,
      dataTestId: 'full-adlayout-name-input',
      ...register('adLayoutName')
    },
    {
      label: 'Page number',
      className: 'mb-4 font-normal',
      type: FieldType.Input,
      variant: Variant.Number,
      placeholder: 'Enter page number',
      error: errors.pageNumber?.message,
      dataTestId: 'pagenumber-input',
      ...register('pageNumber')
    },
    {
      label: 'Ad delivery',
      buttons: DELIVERY,
      control,
      className: 'mb-4',
      type: FieldType.Radio,
      error: errors.adDelivery?.message,
      dataTestId: 'Ad-channel-input',
      ...register('adDelivery')
    },
    {
      label: 'Ad size (Desktop)',
      type: FieldType.Select,
      control,
      options: ADSIZE_DESKTOP,
      labelStyle: 'text-sm mb-2 font-semibold',
      className: _adDelivery === 'mobile' ? `hidden` : 'mb-4',
      placeholder: 'Select',
      error: errors.adSizeDesktop?.message,
      dataTestId: 'Ad-size-input',
      ...register('adSizeDesktop')
    }
  ];

  const adSizePXDesktop = [
    {
      label: 'Ad size (px)',
      className:
        _adDelivery === 'mobile' ? `hidden` : 'font-normal w-full mr-4',
      type: FieldType.Input,
      variant: Variant.Number,
      placeholder: 'Enter width',
      disabled: true,
      dataTestId: 'ad-size-px-width-desktop-input'
    },
    {
      label: '',
      className:
        _adDelivery === 'mobile' ? `hidden` : 'font-normal w-full mt-5',
      type: FieldType.Input,
      variant: Variant.Number,
      placeholder: 'Enter height',
      disabled: true,
      dataTestId: 'ad-size-px-height-desktop-input'
    }
  ];

  const adSizeMobile = [
    {
      label: 'Ad size (Mobile)',
      type: FieldType.Select,
      control,
      options: ADSIZE_MOBILE,
      labelStyle: 'text-sm mb-2 font-semibold',
      className: _adDelivery === 'desktop' ? `hidden` : 'mb-4',
      placeholder: 'Select',
      error: errors.adSizeMobile?.message,
      dataTestId: 'Ad-size-input',
      ...register('adSizeMobile')
    }
  ];

  const adSizePXMobile = [
    {
      label: 'Ad size (px)',
      className:
        _adDelivery === 'desktop' ? `hidden` : 'font-normal w-full mr-4',
      type: FieldType.Input,
      variant: Variant.Number,
      placeholder: 'Enter width',
      disabled: true,
      dataTestId: 'ad-size-px-width-mobile-input'
    },
    {
      label: '',
      className:
        _adDelivery === 'desktop' ? `hidden` : 'font-normal w-full mt-5',
      type: FieldType.Input,
      variant: Variant.Number,
      placeholder: 'Enter height',
      disabled: true,
      dataTestId: 'ad-size-px-height-mobile-input'
    }
  ];

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        data-testid="create-layout-form"
      >
        <Layout fields={fields} className="px-8" />
        <Layout fields={adSizePXDesktop} className="px-8 flex" />
        <Layout fields={adSizeMobile} className="px-8" />
        <Layout fields={adSizePXMobile} className="px-8 flex" />
        <div className="bg-white border fixed left-0 right-0 bottom-0 shadow-lg w-full px-20 py-4 flex justify-between items-center">
          <Button
            label="Cancel"
            variant={Variants.Negative}
            className="w-28 border-0"
            onClick={() => {
              navigate('/adlayout');
            }}
            dataTestId="back-dashboard"
          />
          {id ? (
            <Button
              label="Update ad layout"
              className="w-34"
              onClick={handleUpdate}
              dataTestId="create-ad-layout-button"
            />
          ) : (
            <Button
              label="Create ad layout"
              className="w-34"
              onClick={handleCreate}
              dataTestId="create-ad-layout-button"
            />
          )}
        </div>
      </form>
      <WhiteLoader show={loader} />
    </div>
  );
};

export default LayoutConfirguration;
