import React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Tick: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 16,
  color = '#666'
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#1A56DB"
        fillRule="evenodd"
        d="M15.2 8A7.2 7.2 0 11.8 8a7.2 7.2 0 0114.4 0zM8.9 4.4a.9.9 0 11-1.8 0 .9.9 0 011.8 0zM7.1 7.1a.9.9 0 000 1.8v2.7a.9.9 0 00.9.9h.9a.9.9 0 100-1.8V8a.9.9 0 00-.9-.9h-.9z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Tick;
