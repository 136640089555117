import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { FieldType, Status, SortAd } from 'utils/enum';
import Button from 'components/Button';
import Layout from 'components/Forms/Layout';
import _, { debounce } from 'lodash';
import { readAxiosErr } from 'utils/apiService';
import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import AppShell from 'components/AppShell';
import { Variant } from 'components/Forms/components/Input';
import Sort from 'pages/Adverts/components/Sort';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { Sort_Ad } from 'data/options';
import { useForm } from 'react-hook-form';
import Icon from 'components/Icon';
import { INDUSTRY } from 'data/options';
import FilterPopover from './components/FilterPopover';
import { getDuplicateAdLayout, useAllLayout } from 'queries/adLayout';
import { useModal } from 'hooks/useModal';
import Modal from 'components/Modal';
import DeleteAdlayout from './components/DeleteAdlayout';
import { AdDelivery } from 'utils/map';
const { Option } = components;

interface IForm {
  sort: string;
}
interface IFilter {
  statuses: Status[];
  date?: number | undefined;
  industry?: string | null;
}
const Adlayout = () => {
  const [allCheckBox, setAllCheckBox] = useState(false);
  const [sortField, setSortField] = useState('name');
  const [isDeleteOpen, showDelete, closeDelete] = useModal();
  const [id, setId] = useState('');
  const [sortIcon, setSortIcon] = useState({
    name: false,
    advertiser: false,
    industry: false,
    adCount: false,
    status: false
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [sort, setSort] = useState<string>(SortAd.Descending);
  const [searchQuery, setSearchQuery] = useState<String>('');
  const updateQuery = (e: any) => {
    setSearchQuery(e?.target?.value);
  };

  const schema = yup.object({
    sort: yup.string()
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { control, handleSubmit, setValue } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      sort: Sort_Ad[0].value
    }
  });
  const debouncedOnChange = debounce(updateQuery, 500);
  const handleSearchQueryChange = (e: any) => {
    if (e.target.value.length === 0 || e.target.value.length > 2) {
      debouncedOnChange(e);
    }
  };
  const [filters, setFilters] = useState<IFilter>({
    statuses: [],
    date: undefined,
    industry: ''
  });

  const isFiltersEmpty = () => {
    return _.isEqual(filters, {
      adChannel: '',
      date: undefined,
      statuses: []
    });
  };

  const selectStyles = {
    option: (provided: any, state: any) => {
      let color = provided.color;

      if (state.data && state.data.icon === 'delete') {
        color = 'red';
      }

      return {
        ...provided,
        fontSize: '14px',
        padding: '2px',
        color: color
      };
    }
  };

  let { data: allLayout } = useAllLayout({
    q: searchQuery,
    order: sort,
    status: filters.statuses.join(','),
    industry: filters?.industry,
    sortField: sortField
  });

  const handleFilterChange = (filterData: IFilter) => {
    let { date, industry, ...statuses } = filterData;

    let transformFilterData: IFilter;

    if (allCheckBox) {
      transformFilterData = {
        statuses: Object.values({
          Approved: Status.Approved,
          Draft: Status.Draft,
          Pending: Status.Pending,
          Published: Status.Published,
          Rejected: Status.Rejected,
          Unpublished: Status.Unpublished
        })
      };
    } else {
      transformFilterData = {
        statuses: Object.values(statuses)
          .filter((v) => v !== undefined && v !== null)
          .flat()
      };
    }

    if (industry) {
      transformFilterData.industry = industry;
    }
    setFilters(transformFilterData);
  };

  function handleFilterRemove(type: string, value: any) {
    switch (type) {
      case 'date':
        handleFilterChange({
          ...filters,
          date: undefined
        });
        break;
      case 'industry':
        handleFilterChange({
          ...filters,
          industry: ''
        });
        break;
      case 'status':
        setAllCheckBox(false);
        handleFilterChange({
          ...filters,
          statuses: filters.statuses.filter((status) => status !== value)
        });
        break;
      default:
        break;
    }
  }

  const showFilters = () => {
    return (
      <div className="flex flex-wrap" data-testid="show-all-filters">
        {filters?.industry && (
          <span className="flex bg-blue-50 py-0.5 px-2.5 w-fit mr-4 mb-4 text-sm">
            <span
              className="text-blue-800 font-semibold"
              data-testid="show-filters-media-channel"
            >
              Industry
            </span>
            : {INDUSTRY.filter((l) => l.value === filters.industry)[0].label}
            <div
              onClick={(e) => handleFilterRemove('industry', filters.industry)}
              className="cursor-pointer"
              data-testid="channel-filters-x-icon"
            >
              <Icon name="x" />{' '}
            </div>{' '}
          </span>
        )}
        {filters?.statuses.map((status: string, id: number) => {
          return (
            <span
              className="flex bg-blue-50 py-0.5 px-2.5 w-fit mr-4 mb-4 text-sm"
              key={id}
            >
              <span
                className="text-blue-800 font-semibold"
                data-testid={`show-filters-statuses-${id}`}
              >
                Status
              </span>
              : {_.capitalize(status)}
              <div
                onClick={(e) => handleFilterRemove('status', status)}
                className="cursor-pointer"
                data-testid={`statuses-filters-x-icon-${id}`}
              >
                <Icon name="x" />{' '}
              </div>
            </span>
          );
        })}
      </div>
    );
  };

  const CustomSelectValue = (props: any) => (
    <div className="flex">
      <Icon name={props.data.icon} data-testid={`${props.data.label}-icon`} />
      {props.data.label}
    </div>
  );

  const CustomSelectOption = (props: any) => (
    <Option {...props}>
      <div className="flex">
        <Icon name={props.data.icon} data-testid={`${props.data.label}-icon`} />
        {props.data.label}
      </div>
    </Option>
  );

  const field = [
    {
      name: 'search',
      type: FieldType.Input,
      variant: Variant.Text,
      placeholder: 'Search adlayout name',
      onChange: handleSearchQueryChange,
      className: 'text-sm',
      dataTestId: 'search-company-name'
    }
  ];

  const duplicateMutation = useMutation(
    (id: string) => getDuplicateAdLayout(id),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        if (res) {
          navigate(`/edit-adlayout/${res._id}`);
          toast.success('Adlayout cloned successfully');
          queryClient.removeQueries(['adlayout']);
        } else {
          toast.error('Something went wrong');
        }
      }
    }
  );

  return (
    <AppShell>
      <div className="bg-background-theme h-theme grid">
        <div className="mt-8 mb-8 ml-36 text-white text-4xl">
          <div className="flex justify-between">
            <div className="font-semibold text-4xl" data-testid="profile-label">
              Ad layout
            </div>
            <Button
              label="Create ad layout"
              onClick={() => navigate('/create-adlayout')}
              className="w-44 bg-primary mr-48"
              data-testid="create-ad-dashboard"
            />
          </div>
        </div>
        <div className="flex flex-col w-3/5 mt-10 m-auto">
          <div
            className="flex justify-between mt-6 "
            data-testid="search-adverts"
          >
            <Layout fields={field} className="w-80 text-sm" />
          </div>
          <div className="mb-16 mx-36">{showFilters()}</div>
          <div className="mx-1 py-9 border-t-2 flex justify-between">
            <div data-testid="showing-adverts-results">
              {allLayout?.length !== 0
                ? `Showing 1 to ${allLayout?.length ?? ''} of ${
                    allLayout?.length ?? ''
                  } results`
                : 'Showing 0 results'}
            </div>
            <div className="relative">
              <Sort sort={sort} setSort={setSort} />
            </div>
          </div>
          <div className="bg-white rounded-l border shadow-lg w-full mb-8">
            <div className="font-bold text-xl px-8 mt-4">Ad layouts</div>
            <p className="font-normal text-sm px-8 mb-4 text-gray-500">
              View all ad layouts and their information
            </p>
            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200 mb-8"></hr>
            <table>
              <thead>
                <tr className="bg-gray-50 text-gray-500 font-semibold text-xs">
                  <th className="w-80 text-left py-4 pl-4">
                    <div className="flex justify-between">
                      <div>Ad layout name</div>{' '}
                    </div>
                  </th>
                  <th className="w-80 text-left py-4 pl-4">
                    <div className="flex justify-between">
                      <div> Page # </div>{' '}
                    </div>
                  </th>
                  <th className="w-80 text-left py-4 pl-4">
                    <div className="flex justify-between">
                      <div> Ad delivery format </div>{' '}
                    </div>
                  </th>
                  <th className="w-80 text-left py-4 pl-4">
                    <div className="flex justify-between">
                      <div> Ad size </div>{' '}
                    </div>
                  </th>
                  <th className="w-80 my-4"></th>
                </tr>
              </thead>
              <tbody>
                {allLayout?.map((d: any, i: number) => (
                  <tr
                    className={`border-t-2 text-gray-900 text-base ${
                      i % 2 === 0 ? '' : 'bg-gray-50'
                    }`}
                    key={d.id}
                  >
                    <td className="w-80 py-4 pl-4 flex">
                      <div
                        className="mt-3 w-48 truncate"
                        data-testid={d?.adLayoutName}
                        title={d?.adLayoutName}
                      >
                        {d?.adLayoutName}
                      </div>
                    </td>
                    <td className="w-80 py-4 pl-4" data-testid={d?.pageNumber}>
                      {d?.pageNumber}
                    </td>
                    <td className="w-80 py-4 pl-4" data-testid={d?.adDelivery}>
                      {AdDelivery[d?.adDelivery]}
                    </td>
                    <td className="w-80 py-4 pl-4" data-testid={d?.status}>
                      Standard
                      <p>
                        {d?.adSizeDesktop ? d?.adSizeDesktop : d?.adSizeMobile}
                      </p>
                    </td>
                    <td className="w-80 py-4 pl-4">
                      <Select
                        options={[
                          {
                            value: `${d?.id}`,
                            label: 'Edit',
                            icon: 'link',
                            isdisabled: false
                          },
                          {
                            value: `${d?.id}`,
                            label: 'Duplicate',
                            icon: 'duplicate',
                            isdisabled: d?.status === 'INACTIVE'
                          },
                          {
                            value: `${d?.id}`,
                            label: 'View ad script',
                            icon: 'code',
                            isdisabled: d?.status === 'INACTIVE'
                          },
                          {
                            value: `${d?.admin?.id}`,
                            label: 'Delete',
                            icon: 'delete',
                            isdisabled: d?.status === 'INACTIVE'
                          }
                        ]}
                        styles={selectStyles}
                        isOptionDisabled={(option) => option.isdisabled}
                        components={{
                          Option: CustomSelectOption,
                          SingleValue: CustomSelectValue
                        }}
                        onChange={(selectedOption) => {
                          if (selectedOption?.label === 'View ad script') {
                            return navigate(
                              `/ad-layout-configuration?layoutId=${d?.id}&mediaChannelOrgId=${d?.mediaChannel}`
                            );
                          } else if (selectedOption?.label === 'Edit') {
                            return navigate(`/edit-adlayout/${d?.id}`);
                          } else if (selectedOption?.label === 'Delete') {
                            setId(d?.id);
                            showDelete();
                          } else if (selectedOption?.label === 'Duplicate') {
                            duplicateMutation.mutate(d?.id);
                          }
                        }}
                        isSearchable={false}
                        placeholder="Actions"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal isOpen={isDeleteOpen} closeModal={closeDelete} width="40%">
          <DeleteAdlayout id={id} closeModal={closeDelete} />
        </Modal>
      </div>
    </AppShell>
  );
};
export default Adlayout;
