import React from 'react';

const Switch = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <path
        stroke="#6B7280"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 2.75V9.5M4.227 4.727a6.75 6.75 0 109.546 0"
      ></path>
    </svg>
  );
};

export default Switch;
