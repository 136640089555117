import React, { useEffect, useState } from 'react';
import AppShell from 'components/AppShell';
import { useLocation } from 'react-router-dom';
import Button, { Variants } from 'components/Button';
import Tabs from 'components/Tabs';
import { useAdsStats, useMediaAccountStats } from 'queries/stats';
import { useQueryClient } from '@tanstack/react-query';
import { getDateBeforeDays, getUnix } from 'utils/time';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAdverts } from 'queries/ad';
import { Status } from 'utils/enum';

const AdvertsOverview = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const [selectDateRange, setSelectDateRange] = useState(7);
  const [dateRange, setDateRange] = useState<Date[]>([
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
    new Date()
  ]);
  const [startDate, endDate] = dateRange;

  let { data } = useAdsStats({
    startDate: getUnix(startDate),
    endDate: getUnix(endDate)
  });

  let { data: requestData } = useAdverts({
    statuses: Status.Pending
  });

  let { data: allads } = useAdverts({
    statuses: []
  });

  useEffect(() => {
    queryClient.invalidateQueries(['allads']);
  }, []);

  let pathname = location.pathname;

  const links = [
    {
      link: '/adverts/overview',
      label: 'Overview',
      className:
        pathname === '/adverts/overview'
          ? 'text-blue-500 border-blue-500  border-b-2'
          : 'bg-background-theme'
    },
    {
      link: '/adverts/all',
      label: 'All ads',
      count: allads?.totalAdCount,
      className:
        pathname === '/adverts/all'
          ? 'text-blue-500 border-blue-500 border-b-2'
          : 'bg-background-theme'
    },
    {
      link: '/adverts/requests',
      label: 'Requests',
      count: requestData?.pedindAds,
      className:
        pathname === '/adverts/requests'
          ? 'text-blue-500 border-blue-500 border-b-2'
          : 'bg-background-theme'
    }
  ];

  const handleDate = (selectedDuration: number) => {
    setSelectDateRange(selectedDuration);
    setDateRange([getDateBeforeDays(selectedDuration), new Date()]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AppShell>
      <div className="bg-background-theme h-theme grid">
        <div className="mt-8 mb-4 ml-36 text-white text-4xl">
          <div className="flex justify-between">
            <div className="font-semibold text-4xl" data-testid="profile-label">
              Adverts
            </div>
          </div>
          <Tabs links={links} />
        </div>
        <div className="bg-[#F5F5F5]">
          <div className="flex mb-20">
            <DatePicker
              className="ml-36 mb-4"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              placeholderText="Custom range"
              onChange={(update: any) => {
                setDateRange(update);
              }}
              maxDate={new Date()}
              isClearable={true}
            />
            <Button
              label="7 days"
              variant={
                selectDateRange === 7 ? Variants.SemiPrimary : Variants.Disabled
              }
              className="mr-4"
              onClick={() => handleDate(7)}
            />
            <Button
              label="30 days"
              variant={
                selectDateRange === 30
                  ? Variants.SemiPrimary
                  : Variants.Disabled
              }
              className="mr-4"
              onClick={() => handleDate(30)}
            />
            <Button
              label="3 months"
              variant={
                selectDateRange === 90
                  ? Variants.SemiPrimary
                  : Variants.Disabled
              }
              onClick={() => handleDate(90)}
            />
          </div>
          <div className="flex ml-36">
            <div className="drop-shadow-sm rounded-lg p-6 bg-white flex flex-col mr-8 w-96">
              <div className="font-bold">Published Ads</div>
              <div className="font-bold">{data?.pending}</div>
              <div className="font-bold">-%</div>
              <div>vs</div>
            </div>
            <div className="drop-shadow-sm rounded-lg p-6 bg-white flex flex-col mr-8 w-96">
              <div className="font-bold">Pending Ads</div>
              <div className="font-bold">{data?.published}</div>
              <div className="font-bold">-%</div>
              <div>vs</div>
            </div>
            <div className="drop-shadow-sm rounded-lg p-6 bg-white flex flex-col w-96">
              <div className="font-bold">Unpublished Ads</div>
              <div className="font-bold">{data?.unPublished}</div>
              <div className="font-bold">-%</div>
              <div>vs</div>
            </div>
          </div>
        </div>
      </div>
    </AppShell>
  );
};

export default AdvertsOverview;
