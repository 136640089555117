import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Dashboard: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 16,
  color = '#666',
  ...rest
}) => (
  <svg
    width={size}
    height={size + 1}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1.8 9.5A7.2 7.2 0 0 1 9 2.3v7.2h7.2a7.2 7.2 0 0 1-14.4 0Z"
      fill="#6B7280"
    />
    <path
      d="M10.8 2.527A7.213 7.213 0 0 1 15.975 7.7h-5.173V2.527Z"
      fill="#6B7280"
    />
  </svg>
);
export default Dashboard;
