import apiService from '../utils/apiService';
import { useQuery } from '@tanstack/react-query';

const getOrgDetail = async (id: string) => {
  const { data } = await apiService.get(`/v1/organization/${id}`);
  return data;
};

export const useOrgDetail = (id: string, enabled = true) => {
  return useQuery(['org', id], () => getOrgDetail(id), {
    staleTime: 60 * 60 * 1000,
    enabled: enabled
  });
};
