import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import AppShell from 'components/AppShell';
import DatePicker from 'react-date-picker';
import { FieldType } from 'utils/enum';
import { DAYS } from 'data/options';
import Layout from 'components/Forms/Layout';
import { SubmitHandler, useForm } from 'react-hook-form';

function MediaAdminDashboard() {
  const navigate = useNavigate();
  const [value, onChange] = useState(new Date());
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { control } = useForm();

  const fields = [
    {
      name: 'days',
      options: DAYS,
      control,
      type: FieldType.ChipSingleSelect,
      dataTestId: 'dashboard-days-chip-single-select'
    }
  ];

  return (
    <AppShell>
      <div className="bg-background-theme h-theme grid">
        <div className="my-8 mx-48 text-white text-4xl flex justify-between">
          <div className="font-semibold text-4xl">Dashboard</div>
          <Button
            label="Create ad"
            className="w-28 bg-primary"
            data-testid="create-ad-dashboard"
            onClick={() => navigate('/create-ad')}
          />
        </div>
      </div>
      {/* <div className="mt-6 flex mx-48">
        <div>
          <DatePicker onChange={onChange} value={value} />
        </div>
        <div className="ml-4">
          <Layout fields={fields} />
        </div>
      </div> */}
    </AppShell>
  );
}

export default MediaAdminDashboard;
