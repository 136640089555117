import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import DateTimePicker from 'react-datetime-picker';
import { useController } from 'react-hook-form';
import DatePicker from 'react-date-picker';

type AppProps = {
  label?: string;
  control?: any;
  name?: string;
  className?: string;
  value: Date | undefined;
  onChange: SubmitHandler<any>;
  error?: string;
  loading?: boolean;
  disabled?: boolean;
  showDateAndTime?: boolean;
  dataTestId?: string;
};

const DateTime = React.forwardRef<HTMLInputElement, AppProps>(
  (
    {
      name = '',
      label = '',
      value = undefined,
      onChange,
      disabled = false,
      showDateAndTime = true,
      className = '',
      error = null,
      loading = false,
      dataTestId = ''
    },
    ref
  ) => {
    const DateComponent = showDateAndTime ? DateTimePicker : DatePicker;

    return (
      <div className={className} data-testid={dataTestId}>
        {label && (
          <div className="mb-1">
            <label className="text-sm font-semibold" htmlFor="dateandtime">
              {label}
            </label>
          </div>
        )}
        <DateComponent
          name={name}
          disabled={disabled}
          onChange={onChange}
          value={value}
          className="w-full outline-none focus:outline-none focus:ring-0 rounded-lg"
        />
        {error && <div className="text-error text-xs">*{error}</div>}
      </div>
    );
  }
);

DateTime.displayName = 'DateTime';

export default DateTime;
