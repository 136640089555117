import { createContext, useReducer, useEffect, useState, useMemo } from 'react';
import { getItem, setItem } from 'utils/localstorage';
import { getCurrentUser, loginAccount } from 'queries/auth';
import { isEmpty } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import apiService from 'utils/apiService';
import { Role } from 'utils/enum';

interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  organization: {
    id: string;
    name: string;
  };
}

interface IForm {
  username: string;
  password: string;
}

export const AuthContext = createContext<any>({
  user: null as null | IUser,
  setUser: (...args: any) => {},
  login: (formData: IForm) => {}
});

type AppProps = {
  children: React.ReactNode;
};

const AuthProvider: React.FC<AppProps> = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const location = useLocation();

  const login = async (formData: IForm) => {
    const response = await loginAccount(formData);

    if (response.token.access_token) {
      setItem('token', response.token.access_token);
      setUser(response.userWithoutPassword);
      return response.userWithoutPassword;
    }
    return null;
  };

  useEffect(() => {
    const token = getItem('token');
    if (token) {
      apiService.setTokenGenerator(token);
    } else {
      apiService.setTokenGenerator(() => null);
    }
  }, [user]);

  useEffect(() => {
    const token = getItem('token');
    if (token) {
      fetchCurrentUser();
    }
    setLoading(false);
    // else {
    //   setLoading(false);
    //   return navigate('/login');
    // }
  }, []);

  const fetchCurrentUser = async () => {
    const currentURL = window.location.href;
    if (currentURL.includes('accept-invite')) {
      localStorage.removeItem('token');
    }
    const d = await getCurrentUser();
    setLoading(false);
    if (isEmpty(d)) {
      return navigate('/login');
    }
    setUser(d);
    if (d?.organization.isOnboardingCompleted) {
      setLoading(false);
      if (location.pathname == '/onboarding') {
        navigate('/dashboard');
      }
    } else {
      if (d.role !== Role.SuperAdmin) {
        return navigate('/onboarding');
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <AuthContext.Provider value={{ user, setUser, login }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
