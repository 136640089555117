import React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Alert: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size,
  color
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="30"
      fill="none"
      viewBox="0 0 32 30"
    >
      <path
        fill="#F05252"
        fillRule="evenodd"
        d="M12.338 2.508c1.606-2.856 5.716-2.856 7.32 0L31.377 23.34c1.576 2.802-.447 6.258-3.658 6.258H4.28c-3.213 0-5.235-3.456-3.66-6.258L12.338 2.508zm5.76 20.792a2.1 2.1 0 11-4.199 0 2.1 2.1 0 014.2 0zm-2.1-16.8a2.1 2.1 0 00-2.1 2.1v6.3a2.1 2.1 0 004.2 0V8.6a2.1 2.1 0 00-2.1-2.1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Alert;
