import { useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

export const mediaAccountStats = async (q: Record<string, any>) => {
  const { data } = await apiService.get('/v1/user/mediaAdmin/statistics', q);
  return data;
};
export const useMediaAccountStats = (
  q: Record<string, any>,
  enabled = true
) => {
  return useQuery(['allmediaaccount', q], () => mediaAccountStats(q), {
    staleTime: 60 * 60 * 1000,
    enabled: enabled
  });
};

export const adsStats = async (q: Record<string, any>) => {
  const { data } = await apiService.get('/v1/ad-request/statistics', q);
  return data;
};

export const useAdsStats = (q: Record<string, any>, enabled = true) => {
  return useQuery(['allads', q], () => adsStats(q), {
    staleTime: 60 * 60 * 1000,
    enabled: enabled
  });
};

export const advertiserAccountStats = async (q: Record<string, any>) => {
  const { data } = await apiService.get('/v1/organization/statistics', q);
  return data;
};
export const useAdvertiserAccountStats = (
  q: Record<string, any>,
  enabled = true
) => {
  return useQuery(['alladvertiser', q], () => advertiserAccountStats(q), {
    staleTime: 60 * 60 * 1000,
    enabled: enabled
  });
};

export const advertiserAccountStatsforMediaAdmin = async (
  q: Record<string, any>
) => {
  const { data } = await apiService.get('/v1/association/advAdmn-stats', q);
  return data;
};

export const useAdvertiserAccountStatsforMediaAdmin = (
  q: Record<string, any>,
  enabled = true
) => {
  return useQuery(
    ['alladvertiser', q],
    () => advertiserAccountStatsforMediaAdmin(q),
    {
      staleTime: 60 * 60 * 1000,
      enabled: enabled
    }
  );
};
