import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const SvgV: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 18,
  color = '#666',
  ...rest
}) => (
  <svg
    width={size}
    height={size + 1}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M2.7 3.2a.9.9 0 0 0 0 1.8h9.9a.9.9 0 0 0 0-1.8H2.7Zm0 3.6a.9.9 0 0 0 0 1.8h4.5a.9.9 0 0 0 0-1.8H2.7Zm0 3.6a.9.9 0 1 0 0 1.8h3.6a.9.9 0 1 0 0-1.8H2.7Zm9 4.5a.9.9 0 1 0 1.8 0V9.873l1.165 1.164a.9.9 0 0 0 1.272-1.273l-2.7-2.7a.9.9 0 0 0-1.273 0l-2.7 2.7a.9.9 0 1 0 1.273 1.273l1.164-1.164V14.9Z"
      fill="#9CA3AF"
    />
  </svg>
);
export default SvgV;
