import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import Icon from 'components/Icon';
import { FieldType } from 'utils/enum';
import Layout from 'components/Forms/Layout';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button, { Variants } from 'components/Button';
import { Status } from 'utils/enum';
import DateTime from 'components/Forms/components/DateTime';
import { getUnix } from 'utils/time';
import { Menu } from '@headlessui/react';
import { AD_CHANNEL } from 'utils/map';
import useRole from 'hooks/useRole';

interface IForm {
  statuses: Status[];
  date: number | undefined;
  adChannel?: string;
}

type AppProps = {
  filters: object;
  handleFilterChange: (filterData: IForm) => void;
  allCheckBox?: boolean;
  setAllCheckBox?: any;
 
};

const FilterPopover: React.FC<AppProps> = ({
  filters,
  handleFilterChange,
  allCheckBox = false,
  setAllCheckBox = () => {}
}) => {
  const schema = yup.object({
    filter: yup.string()
  });

  const { control, handleSubmit, reset, setValue } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      statuses: []
    }
  });

  const { isAdvertiserAdmin } = useRole();

  const [date, setDate] = useState(undefined);
  const [adChannel, setAdChannel] = useState<string>('');

  const statusFields = [
    {
      name: 'All',
      label: 'All',
      value: 'ALL',
      control,
      defaultValue: allCheckBox,
      setAllCheckBox,
      type: FieldType.Checkbox,
      onChange: handleFilterChange,
      dataTestId: 'filter-all'
    },
    {
      name: 'Pending',
      label: 'Pending',
      value: 'PENDING',
      control,
      defaultValue: allCheckBox,
      setAllCheckBox,
      type: FieldType.Checkbox,
      onChange: handleFilterChange,
      dataTestId: 'filter-pending'
    },
    {
      name: 'Published',
      label: 'Published',
      value: 'PUBLISHED',
      control,
      defaultValue: allCheckBox,
      setAllCheckBox,
      type: FieldType.Checkbox,
      onChange: handleFilterChange,
      dataTestId: 'filter-published'
    },
    {
      name: 'Approved',
      label: 'Approved',
      value: 'APPROVED',
      control,
      defaultValue: allCheckBox,
      setAllCheckBox,
      type: FieldType.Checkbox,
      onChange: handleFilterChange,
      dataTestId: 'filter-approved'
    },
    {
      name: 'Unpublished',
      label: 'Unpublished',
      value: 'UNPUBLISHED',
      control,
      defaultValue: allCheckBox,
      setAllCheckBox,
      type: FieldType.Checkbox,
      onChange: handleFilterChange,
      dataTestId: 'filter-all'
    },
    {
      name: 'Rejected',
      label: 'Rejected',
      value: 'REJECTED',
      control,
      defaultValue: allCheckBox,
      setAllCheckBox,
      type: FieldType.Checkbox,
      onChange: handleFilterChange,
      dataTestId: 'filter-rejected'
    }
  ];

  const onSubmit: SubmitHandler<IForm> = async (formData) => {
    formData = Object.assign({ date: getUnix(date) }, formData);
    formData = Object.assign({ adChannel: adChannel }, formData);
    handleFilterChange(formData);
  };

  const handleReset = () => {
    reset({
      statuses: []
    });
    setDate(undefined);
    setAdChannel('');
    setAllCheckBox(false);
  };

  return  (
    <Popover className="relative">
      <Popover.Button
        onClick={() => {
          // setIsOpen(true);
        }}
      >
        <div className="flex py-2 px-4 w-28 h-10 border-solid border border-gray-700">
          <div className="pt-1 mr-2">
            <Icon name="filter" data-testid="filter-icon" />
          </div>
          <div data-testid="filter-label">Filter</div>
          <div className="mt-1 ml-2">
            <Icon name="arrow-down" data-testid="arrow-down-filter-icon" />
          </div>
        </div>
      </Popover.Button>

      <Popover.Panel className="absolute right-0 z-10 w-[472px] drop-shadow-2xl px-8 border bg-white">
        {({ close }) => (
          <form
            className="mt-6"
            onSubmit={handleSubmit(onSubmit)}
            data-testid="popover-panel-filters"
          >
            <div className="flex justify-between pb-3.5">
              <div className="text-xl" data-testid="filter-popover-label">
                Filters
              </div>
              <div>
                {' '}
                <Icon
                  name="x"
                  onClick={() => close()}
                  className="cursor-pointer"
                  data-testid="filter-popover-x-icon"
                />
              </div>
            </div>
            <div className="border-t-2">
              <div
                className="text-lg font-semibold mt-8 mb-4"
                data-testid="filter-status-label"
              >
                Filter by: Status
              </div>
              <div>
                <Layout
                  fields={statusFields}
                  className="grid grid-cols-3 gap-2"
                />
              </div>
            </div>
            {/* {pagePath =="/adverts/overview"} */}
            {isAdvertiserAdmin && (
              <div>
                <div
                  className="text-lg font-semibold mt-8 mb-4"
                  data-testid="filter-created-date"
                >
                  Filter by: Created date
                </div>
                <div>Date</div>
                <DateTime
                  showDateAndTime={false}
                  onChange={(newDate) => setDate(newDate)}
                  value={date}
                  dataTestId="filter-created-date"
                />
              </div>
            )}


            {isAdvertiserAdmin && (
              <div className="mb-8">
                <div
                  className="text-lg font-semibold mt-8 mb-4"
                  data-testid="filter-ad-channel"
                >
                  Filter by: Ad channel
                </div>
                <Menu>
                  <Menu.Button>
                    <div
                      className="w-[406px] py-3 px-4 border-2 flex justify-between relative rounded-lg"
                      data-testid="filter-ad-channel-button"
                    >
                      {adChannel ? `${AD_CHANNEL[adChannel]}` : 'Select'}
                      <Icon
                        name="arrow-down"
                        className="mt-1"
                        data-testid="filter-ad-channel-icon-arrow-down"
                      />
                    </div>
                  </Menu.Button>

                  <Menu.Items className="absolute w-[406px] rounded-lg">
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={`bg-white ${
                            active && ' bg-gray-200'
                          } py-3 pl-4 cursor-pointer rounded-lg border-x-2`}
                          onClick={() => setAdChannel('auzmor_office')}
                          data-testid="auzmor-channel"
                        >
                          Auzmor Office
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={`bg-white ${
                            active && ' bg-gray-200'
                          } py-3 pl-4 cursor-pointer rounded-lg border-x-2`}
                          onClick={() => setAdChannel('unboxed')}
                          data-testid="unboxed-channel"
                        >
                          Unboxed
                        </div>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              </div>
            )}
            <div className="flex justify-between my-8">
              <div>
                <Button
                  label="Cancel"
                  variant={Variants.PrimaryNoOutlined}
                  className="w-14 mr-8"
                  onClick={(e: any) => {
                    e?.preventDefault();
                    close();
                  }}
                  dataTestId="button-filter-cancel"
                />
              </div>
              <div className="">
                <Button
                  label="Reset all filters"
                  variant={Variants.PrimaryOutlined}
                  className="w-36 mr-4"
                  onClick={(e: any) => {
                    e?.preventDefault();
                    handleReset();
                  }}
                  dataTestId="button-filter-reset"
                />
                <Button
                  label="Apply"
                  className="w-20"
                  dataTestId="button-filter-apply"
                />
              </div>
            </div>
          </form>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default FilterPopover;
