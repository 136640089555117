import React from 'react';

const Link = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <path
        fill="#9CA3AF"
        d="M15.672 2.827a1.8 1.8 0 00-2.545 0L6.3 9.655V12.2h2.545l6.827-6.827a1.8 1.8 0 000-2.546z"
      ></path>
      <path
        fill="#9CA3AF"
        fillRule="evenodd"
        d="M1.8 5.9a1.8 1.8 0 011.8-1.8h3.6a.9.9 0 110 1.8H3.6v9h9v-3.6a.9.9 0 111.8 0v3.6a1.8 1.8 0 01-1.8 1.8h-9a1.8 1.8 0 01-1.8-1.8v-9z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Link;
